import {
    SET_SCHEDULER_CREW_DATA,
    UPDATE_SCHEDULER_CREW,
    UPDATE_SCHEDULER_CREW_DATE,
    SET_SCHEDULER_JOB_DATA,
    ADD_NEW_ORDER,
    DELETE_PLACEHOLDER_ORDER,
    SET_SCHEDULER_JOB_CREW_DATA,
    SET_IS_INFORM_CHANGE_DATES
} from '../actions/scheduler';
import moment from 'moment/src/moment';
import api from '@/headers.js';

export default {
    namespaced: true,
    state: {
        schedulerCrewList:[],
        schedulerJobList: [],
        isInformChangeDates: false
    },
    getters: {
        getSchedulerCrewList: state => state.schedulerCrewList,
        getSchedulerJobList: state => state.schedulerJobList,
        getInformChangeDates: state => state.isInformChangeDates
    },
    mutations: {
        [SET_IS_INFORM_CHANGE_DATES] (state, payload) {
            state.isInformChangeDates = payload;
        },
        [SET_SCHEDULER_CREW_DATA](state, payload) {
            state.schedulerCrewList = payload.filter(el => {
                el.orders && el.orders.length && el.orders.forEach((item) => {
                    item.trade.is_active = true;
                    if (item.labor_delivery_date_from && item.labor_delivery_date_to) {

                        let datetimeFrom = moment.utc(item.labor_delivery_date_from).local();
                        let datetimeTo = moment.utc(item.labor_delivery_date_to).local();

                        item.labor_delivery_date_from = datetimeFrom.format('DD MMM YYYY');
                        item.labor_delivery_date_to = datetimeTo.format('DD MMM YYYY');
                        item.labor_delivery_time_from = item.labor_all_day_working
                            ? null
                            : datetimeFrom.format('HH:mm');
                        item.labor_delivery_time_to = item.labor_all_day_working
                            ? null
                            : datetimeTo.format('HH:mm');
                    }
                });

                return el.is_active ? el : null;
            });
        },
        [SET_SCHEDULER_JOB_CREW_DATA] (state, payload) {
            state.schedulerCrewList = payload.filter(el => el.is_active = true);
        },
        [DELETE_PLACEHOLDER_ORDER] (state) {
            state.schedulerJobList.forEach(el => {
                el.orders && el.orders.forEach((item, i) => {
                    const searchId = 0;
                    if (item.id == searchId) {
                        el.orders.splice(i, 1);
                    }
                });
            });
        },
        [ADD_NEW_ORDER] (state, payload) {
            state.schedulerJobList.forEach(el => {
                if (el.id == payload.jobId) {
                    el.orders.push(payload);
                }
            });
        },
        [SET_SCHEDULER_JOB_DATA](state, payload) {
            state.schedulerJobList = payload.filter(el => {
                el.orders && el.orders.length && el.orders.forEach(item => {
                    item.is_active = true;
                });
                return el;
            });
        },
        [UPDATE_SCHEDULER_CREW](state, payload) {
            state.schedulerCrewList[payload.index].is_active = payload.value;
            state.schedulerJobList.forEach(el => {
                el.orders && el.orders.length && el.orders.forEach(item => {
                    if (payload.id) {
                        item.crew_id == payload.id ? item.is_active = payload.value : null
                    }
                });
            });
        },
        [UPDATE_SCHEDULER_CREW_DATE] (state, payload) {
            state.schedulerCrewList.forEach(el => {
                el.orders.forEach((item, index) => {
                    if (item.id == payload.id) {
                        if (payload.labor_delivery_date_from) {
                            item.labor_all_day_working = payload.labor_all_day_working;
                            item.labor_delivery_date_from = moment(payload.labor_delivery_date_from).format('YYYY-MM-DD');
                            item.labor_delivery_date_to = moment(payload.labor_delivery_date_to).format('YYYY-MM-DD');
                        }

                        if (!payload.labor_all_day_working) {
                            item.labor_delivery_time_to = payload.labor_delivery_time_to;
                            item.labor_delivery_time_from = payload.labor_delivery_time_from;
                        }

                        if (payload.delivery_date) {
                            item.delivery_date = payload.delivery_date;
                            item.delivery_time = payload.delivery_time;
                        }
                    }
                });
            });
        }
    },
    actions: {
        [UPDATE_SCHEDULER_CREW]({ commit }, data) {
            return new Promise((resolve, reject) => {
                api.put(`/scheduler/change-date/${data.id}`, data)
                    .then(response => {
                        resolve(response);
                        commit(UPDATE_SCHEDULER_CREW_DATE, data);
                        return response.data.data;
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    }
}
