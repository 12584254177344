export const ROLES = [
    {
        "id": 1,
        "name": "admin"
    },
    {
        "id": 2,
        "name": "management"
    },
    {
        "id": 3,
        "name": "office staff"
    },
    {
        "id": 4,
        "name": "accountant"
    },
    {
        "id": 5,
        "name": "salesman"
    }
];

export const CUSTOM_ITEM_UNITS_TYPES = [
    'BD',
    'BF',
    'BRD',
    'BX',
    'CF',
    'CS',
    'CY',
    'DA',
    'EA',
    'GL',
    'HR',
    'JB',
    'LB',
    'LF',
    'MO',
    'PC',
    'PNL',
    'RL',
    'SF',
    'SQ',
    'STK',
    'UI',
    'WK',
    'YD',
];

// job documents
export const MEGABYTE_SIZE = 1048576;
export const ZIP_TYPE = 'application/zip';
export const IMAGE_TYPE = /image.*/;
export const VIDEO_TYPE = /video.*/;
export const FOLDER_TYPE = 'folder';

// audit filters
export const FILTER = {
    categories: [
        {
            name: 'Job',
            id: 1
        },
        {
            name: 'Payment',
            id: 2
        },
        {
            name: 'Salesmen Payrol',
            id: 3
        },
        {
            name: 'Crew Payrol',
            id: 4
        }
    ],
    event_types: [
        {
            id: 1,
            name: 'Updated'
        },
        {
            id: 2,
            name: 'Created'
        }
    ]
};

// on the page of estimates (edit) and templates (edit)
// item can have 2 types:
export const MATERIAL = 1;
export const LABOR = 2;


// custom fields
export const MODEL_CLASSES = {
    RESIDENTIAL_LEAD: 'App\\Models\\Lead',
    CUSTOM_REPORT: 'CustomReport',
    COMMERCIAL_JOB: {
        CUSTOMER: 'Modules\\Commercial\\Entities\\CommercialCustomer',
        PROPERTY: 'Modules\\Commercial\\Entities\\CommercialProperty',
        UNIT: 'Modules\\Commercial\\Entities\\CommercialUnit',
        JOB: 'Modules\\Commercial\\Entities\\CommercialJob'
    },
    COMMERCIAL_LEAD: 'Modules\\Commercial\\Entities\\CommercialLead',
    COMPANY_DOCUMENTS: 'App\\Models\\CompanyInfo',
    CREW: 'Modules\\Crew\\Entities\\Crew',
    CONTACT: 'Modules\\Contact\\Entities\\Contact',
    OPPORTUNITY: 'Modules\\Opportunity\\Entities\\Opportunity',
    WARRANTY_JOB: 'Modules\\Warranty\\Entities\\WarrantyJob',
    WARRANTY_CASE: 'Modules\\Warranty\\Entities\\WarrantyCase',
    WARRANTY: 'Modules\\Warranty\\Entities\\Warranty',
};

export const TYPE_NOTIFY = {
    ASSIGNED: 'assignee',
    COMPLETED: 'completed',
    DUE_DATE: 'due_date',
    REMIND: 'remind_date',
    STANDARD: 'standard'
};

export const TASK_FREQUENCY = {
    IMMEDIATELY: 'immediately',
    ONCE_AFTER: 'once_after',
    ONCE_BEFORE: 'once_before',
    LOOP_UNTIL: 'loop_until'
};

export const TASK_TRIGGER_FIELD = {
    DATE_OF_COMPLETION: 'date_of_completion',
    IS_COMPLETED: 'is_completed'
};

export const TYPE_COMPLETE = {
    no_warranty: 'no_warranty',
    new_lead: 'new_lead',
    new_job: 'new_job'
}

export const MODEL_NAMES = {
    REPORT: 'Report',
    COMMERCIAL_JOB: 'CommercialJob',
    WARRANTY: 'Warranty'
};

export const CUSTOM_FIELDS_PAGES = {
    RESIDENTIAL: 'CustomFieldsResidential',
    COMMERCIAL_JOB: {
        CUSTOMER: 'CustomFieldsCommercialJobCustomer',
        PROPERTY: 'CustomFieldsCommercialJobProperty',
        UNIT: 'CustomFieldsCommercialJobUnit',
        JOB: 'CustomFieldsCommercialJob'
    },
    COMMERCIAL_LEAD: 'CustomFieldsCommercialLead',
    FORM_BUILDER: 'CustomFieldsFormBuilder',
    WARRANTY: 'CustomFieldsWarranty'
};

export const CUSTOM_FIELDS_DRAG_SOURCES = {
    EXISTING_SECTION: 'existing_section',
    NEW_SECTION: 'new_section',
    EXISTING_FIELD: 'existing_field',
    NEW_FIELD: 'new_field',
    PICK_LIST: 'pick_list'
};

export const CUSTOM_FIELDS_TYPES = {
    SINGLE_LINE: 'single_line',
    EMAIL: 'email',
    PHONE: 'phone',
    PICK_LIST: 'pick_list',
    MULTISELECT: 'multiselect',
    DATE: 'date',
    DATE_TIME: 'date_time',
    CURRENCY: 'currency',
    DECIMAL: 'decimal',
    PERCENT: 'percent',
    LONG_INTEGER: 'long_integer',
    CHECKBOX: 'checkbox',
    URL: 'url',
    LOOKUP: 'lookup',
    MULTI_USER: "multi_user",
    FILE_UPLOAD: 'file_upload',
    ADDRESS: 'address',
    MULTILINE: 'multiline',
    PICTURE: 'picture',
    DB_TABLE: 'db_table'
};

export const CUSTOM_FIELDS_SECTION_TYPES = {
    COMMON: 'common',
    ADDRESS: 'address',
    MULTILINE: 'multiline',
    PICTURE: 'picture'
};

// packets statuses
export const PACKETS_STATUSES = {
    SIGNED: 'signed',
    WAITING: 'waiting',
    CANCELLED: 'cancelled',
    DRAFT: 'draft'
};

export const PACKETS_PARTIES_STATUSES = {
    REJECTED: 'rejected',
    PENDING: 'pending',
    SIGNED: 'signed',
    IN_PROGRESS: 'in progress'
};

// screen size (CSS)
export const SCREEN_SIZES = {
    NOT_SUPPORT: 'not support', // <= 319
    MOBILE: 'mobile', // <= 767
    TABLET: 'tablet', // > 767 && <= 900
    NETBOOK: 'netbook', // > 900 && <= 1165
    DESKTOP: 'desktop' // > 1165
};

export const MILESTONE_STATUSES = {
    DONE: 'done',
    INPROGRESS: 'inprogress',
    AWAIT: 'await',
    DEAD: 'dead',
    COMPLETED: 'completed'
};

// EagleView
export const EAGLE_VIEW_TYPES = [
    { name: 'Sun site residential', id: 11 },
    { name: 'EC premium residential', id: 31 },
    { name: 'EC premium commercial', id: 32 },
    { name: 'Premium residential wall only', id: 34 },
    // { name: 'Quick squares', id: 44 },
    // { name: 'Walls lite', id: 51 }
];

export const DELIVERY_METHODS = [
    { name: 'Express delivery', id: 4 },
    { name: 'Three hour delivery', id: 7 },
    { name: 'Regular delivery', id: 8 },
    { name: 'Quick delivery', id: 45 }
];

export const INSTRUCTION_TYPES = [
    { name: 'Primary plus detached garage', id: 1 },
    { name: 'Primary structure only', id: 2 },
    { name: 'All structures on parcel', id: 3 },
    { name: 'Commercial complex', id: 4 }
];

export const EAGLE_VIEW_STATUSES = {
    CREATED: 'Created',
    IN_PROCESS: 'In Process',
    PENDING: 'Pending',
    CLOSED: 'Closed',
    COMPLETED: 'Completed'
};

// form builder reports
export const FORM_BUILDER_REPORTS = {
    LEAK: 'Leak Response Report',
    SERVICE: 'Service Response Report',
    FURTHER: 'Further Action Report',
    INVOICE: 'Invoice'
};

export const INVOICE_REPORT_DESCRIPTION_OPTIONS = [
    '2 Man Hours (2 Hr Minimum)',
    '2 Man Hours (after 2nd Hr) billed in 30 minute increments',
    '1 Excessively Steep/ Excessively High/ Difficult Access Fee (If Applicable)',
    '1 Travel hours atr (1 Hr Minimum)',
    'Materials'
];

// progress status component (CSS)
export const PROGRESS_STATUS = {
    FAULT: 'fault',
    WAIT: 'wait',
    DONE: 'done',
    CLOSE: 'close'
};

export const LEAD_TEL_TYPES = {
    HOME: 'Home',
    MOBILE: 'Mobile',
    WORK: 'Work'
};

export const EMAIL_TYPES = {
    MAIN: 'main',
    BILLING: 'billing'
};

export const REPORT_TYPES = {
    TABULAR_REPORT: 1,
    SUMMARY_REPORT: 2,
    MATRIX_REPORT: 3
};

//for status call

export const STATUS_CALL_TYPES = {
    OUTBOUND: 'outbound',
    INBOUND: 'inbound'
}

export const REPORT_COLUMNS = {
    CITY: { id: 1, excerpt: 'city' },
    COUNTRY: { id: 2, excerpt: 'country' },
    ZIP: { id: 3, excerpt: 'zip', inModule: 'residential' },
    CREATED_BY: { id: 4, excerpt: 'created_by' },
    CREATED_TIME: { id: 5, excerpt: 'created_time' },
    MILESTONE: { id: 6, excerpt: 'milestone' },
    AMOUNT_OF_ESTIMATES: { id: 7, excerpt: 'estimates_created' },
    AMOUNT_OF_MEASUREMENTS: { id: 8, excerpt: 'measurements_created' },
    AMOUNT_OF_MEASUREMENTS_EAGLEVIEW : { id: 9, excerpt: 'measurements_eagleview' },
    ESTIMATES_SIGNED: { id: 10, excerpt: 'estimates_signed' },
    ESTIMATES_SIGNED_PRICE_TOTAL: { id: 11, excerpt: 'estimates_signed_price_total' },
    FIRST_NAME: { id: 12, excerpt: 'first_name', inModule: 'residential' },
    LAST_NAME: { id: 13, excerpt: 'last_name', inModule: 'residential' },
    PRIORITY: { id: 14, excerpt: 'priority' },
    SALESMAN: { id: 15, excerpt: 'salesman' },
    LEAD_TYPE: { id: 16, excerpt: 'lead_type' },
    HOME_TYPE: { id: 17, excerpt: 'home_type', inModule: 'residential' },
    SOURCE: { id: 18, excerpt: 'source', inModule: 'residential' },
    TRADES: { id: 19, excerpt: 'trades', inModule: 'residential' },
    MILESTONE_SET: { id: 20, excerpt: 'milestone_set' },
    JOB_ID: { id: 21, excerpt: 'job_identifier' },
    PHONE: { id: 22, excerpt: 'phone' },
    AGE: { id: 23, excerpt: 'age' },
    JOB_NAME: { id: 24, excerpt: 'job_name' },
    BASE_BID: { id: 25, excerpt: 'base_bid' },
    STATE: { id: 26, excerpt: 'state' },
    PROPOSAL_DELIVERED: { id: 27, excerpt: 'proposal_delivered' },
    RESPONSIBLE_BILLING_PARTY: { id: 28, excerpt: 'responsible_billing_party', inModule: 'commercial' },
    PRIMARY_CONTACT: { id: 29, excerpt: 'primary_contact' },
    REV_PROPOSAL: { id: 30, excerpt: 'rev_proposal', inModule: 'commercial' },
    OFFICE: { id: 31, excerpt: 'office' },
    ADDRESS: { id: 32, excerpt: 'address' },
    BID_DUE_DATE: { id: 33, excerpt: 'bid_due_time' },
    BID_DATE: { id: 34, excerpt: 'bid_date' },
    EMAIL: { id: 35, excerpt: 'email' },
    ACTIVE_LABELS: { id: 36, excerpt: 'tags' },
    REASONS_FOR_CANCELLATION: { id: 37, excerpt: 'reasons_milestone' },
    QUALITY_CONTROL: { id: 38, excerpt: 'quality_control' },
    CLASS_NAMESPACE: { id: 39, excerpt: 'class_namespace' },
    EXPECTED_REVENUE: { id: 40, excerpt: 'expected_revenue' },
    TASK_ACTIVE_AND_OVERDUE: { id: 41, excerpt: 'active_and_overdue' },
    TASK_ACTIVE_AND_NOT_OVERDUE: { id: 42, excerpt: 'active_and_not_overdue' },
    TASK_COMPLETED_OVERDUE: { id: 43, excerpt: 'completed_overdue' },
    TASK_COMPLETED_NOT_OVERDUE: { id: 44, excerpt: 'completed_not_overdue' },
    JOB_COMPLETE_DATE: { id: 45, excerpt: 'job_complete_date' },
    JOB_START_DATE: { id: 46, excerpt: 'job_start_date' },
    NET_PROFIT: { id: 47, excerpt: 'net_profit' },
    PROFIT_MARGIN: { id: 48, excerpt: 'profit_margin' },
    PROPERTY: { id: 50, excerpt: 'property', inModule: 'commercial' },
    EVENT_DATE_FOR_TECHNICIAN: { id: 51, excerpt: 'event_date_for_technician' },
    PRIMARY_CUSTOMER: { id: 52, excerpt: 'primary_customer', inModule: 'commercial' },
    SALES_RABBIT: { id: 53, excerpt: 'sales_rabbit' },
    COORDINATES: { id: 54, excerpt: 'coordinates' },
    ASSIGNMENT: { id: 55, excerpt: 'assignment' },
    CASE_DATE: { id: 56, excerpt: 'warranty_case__start_date' },
    CASE_END_DATE: { id: 57, excerpt: 'warranty_case__end_date' },
    CASE_NUMBER: { id: 58, excerpt: 'warranty_case__number' },
    CLAIM: { id: 59, excerpt: 'claim' },
    CREW: { id: 60, excerpt: 'crew' },
    DESCRIPTION: { id: 61, excerpt: 'description' },
    LABEL: { id: 62, excerpt: 'label' },
    REGISTRATION: { id: 63, excerpt: 'registration' },
    STATUS: { id: 64, excerpt: 'status' },
    WARRANTY_TYPE: { id: 65, excerpt: 'warranty_type' }
};

// Report numeric columns not allowed for grouping
export const DISABLED_COLUMNS_FOR_GROUPING = [
    REPORT_COLUMNS.ZIP.excerpt,
    REPORT_COLUMNS.AMOUNT_OF_ESTIMATES.excerpt,
    REPORT_COLUMNS.AMOUNT_OF_MEASUREMENTS.excerpt,
    REPORT_COLUMNS.AMOUNT_OF_MEASUREMENTS_EAGLEVIEW.excerpt,
    REPORT_COLUMNS.ESTIMATES_SIGNED.excerpt,
    REPORT_COLUMNS.ESTIMATES_SIGNED_PRICE_TOTAL.excerpt,
    REPORT_COLUMNS.AGE.excerpt,
    REPORT_COLUMNS.BASE_BID.excerpt,
    REPORT_COLUMNS.EXPECTED_REVENUE.excerpt,
    REPORT_COLUMNS.TASK_ACTIVE_AND_OVERDUE.excerpt,
    REPORT_COLUMNS.TASK_ACTIVE_AND_NOT_OVERDUE.excerpt,
    REPORT_COLUMNS.TASK_COMPLETED_OVERDUE.excerpt,
    REPORT_COLUMNS.TASK_COMPLETED_NOT_OVERDUE.excerpt,
    REPORT_COLUMNS.NET_PROFIT.excerpt,
    REPORT_COLUMNS.PROFIT_MARGIN.excerpt,
];

export const REPORT_SYSTEM_COLUMNS = {
    ID: 'id',
    CREATED_AT: 'created_at',
    ENTITY_TYPE: 'entity_type',
    PROPERTY_ID: 'property_id'
};

export const CUSTOMER_INVITE_MODES = {
    MILESTONE: 'milestone',
    MANUAL: 'manual',
    NONE: 'none'
};

export const YES_OR_NO = {
    YES: '1',
    NO: '0'
}

export const COLOR_PICKER_PALETTE = [
    '#e5f0ff',
    '#eafaf1',
    '#fffae9',
    '#fff5e9',
    '#feebec',
    '#f2effc',
    '#e5f5ff',
    '#e7e8ea',
    '#bcd6f9',
    '#d5f5e3',
    '#fff4d4',
    '#ffecd4',
    '#fdd7da',
    '#e5e1f9',
    '#ccecff',
    '#ced0d3',
    '#7fb5fe',
    '#96e5b8',
    '#ffe493',
    '#ffd093',
    '#fa9ba3',
    '#bdb3ef',
    '#7fcfff',
    '#878f98',
    '#3389fd',
    '#58d68d',
    '#ffd553',
    '#ffb453',
    '#f7606c',
    '#9685e5',
    '#33b3ff',
    '#212f40',
    '#006bfd',
    '#2ecc71',
    '#ffc928',
    '#ffa128',
    '#f53847',
    '#8876e2',
    '#00a0ff',
    '#101f32'
];

export const PRIORITIES_COLOR =  [
    '#fbd8d6',
    '#fbd6e8',
    '#e8d6fb',
    '#d6e6fb',
    '#d6f5fb',
    '#d6fbf2',
    '#d6fbdb',
    '#fbfad6',
    '#fcddd3',
    '#f6b0ac',
    '#f6acd1',
    '#d0acf6',
    '#accdf6',
    '#aceaf6',
    '#acf6e5',
    '#acf6b6',
    '#f6f4ac',
    '#f9bba6',
    '#ef756e',
    '#ef6eaf',
    '#ad6eef',
    '#6ea6ef',
    '#6edaef',
    '#6eefd1',
    '#6eef80',
    '#efeb6e',
    '#f48763',
    '#e83a30',
    '#e8308c',
    '#8930e8',
    '#3080e8',
    '#30c9e8',
    '#30e8bd',
    '#30e849',
    '#e8e230',
    '#ee5421',
    '#d1342b',
    '#d12b7e',
    '#7b2bd1',
    '#2b73d1',
    '#2bb5d1',
    '#2bd1aa',
    '#2bd141',
    '#d1cc2b',
    '#d74b1d',
    '#a32821',
    '#a32162',
    '#6021a3',
    '#2159a3',
    '#218da3',
    '#21a384',
    '#21a333',
    '#a39e21',
    '#a73b17',
    '#741d18',
    '#741846',
    '#441874',
    '#184074',
    '#186474',
    '#18745e',
    '#187424',
    '#747118',
    '#772a10'
];

export const USER_ROLES = {
    ADMIN: 'admin',
    MANAGEMENT: 'management',
    OFFICE_STAFF: 'office staff',
    ACCOUNTANT: 'accountant',
    SALESMAN: 'salesman'
};

// commercial
export const DESIGNATION_OPTIONS = [
    'Single Property',
    'Main Property'
];

export const DIVISION_OPTIONS = [
    'Commercial',
    'Residential'
];

export const IS_SELECTED_LOCATION = [
    { title: 'Yes', value: 1 },
    { title: 'No', value: 0 }
];

export const COMMERCIAL_PHONE_TYPES = ['home', 'mobile', 'work'];

export const ENTITY_TYPES = {
    RESIDENTIAL: 'Residential',
    RESIDENTIAL_LEAD: 'ResidentialLead',
    RESIDENTIAL_JOB: 'ResidentialJob',
    COMMERCIAL_JOB: 'CommercialJob',
    COMMERCIAL_LEAD: 'CommercialLead',
    COMMERCIAL_PROPERTY: 'CommercialProperty',
    COMMERCIAL_CUSTOMER: 'CommercialCustomer',
    CONTACT: 'contact',
    OPPORTUNITY: 'opportunity',
    WARRANTY_JOB: 'warranty_job',
    WARRANTY: 'warranty'
};

export const BASE_PATH = {
    RESIDENTIAL: 'residential',
    COMMERCIAL_JOB: 'commercial_job',
    COMMERCIAL_LEAD: 'commercial_lead'
};

export const ENTITY_LEAD_TYPES = {
    RESIDENTIAL: 'residential',
    COMMERCIAL: 'commercial'
};

export const ENTITY_TYPES_ROUTES = {
    RESIDENTIAL_LEAD: 1,
    COMMERCIAL_JOB: 2,
    COMMERCIAL_LEAD: 3,
    WARRANTY_JOB: 4
};

export const ADDRESS_TYPES = {
    MAIN: 'main',
    BILLING: 'billing',
    MAILING: 'mailing',
    APPOINTMENT: 'appointment'
};

export const BASE_ENTITY_TYPES = {
    RESIDENTIAL_JOB: 'residentialJob',
    RESIDENTIAL_LEAD: 'residentialLead',
    RESIDENTIAL: 'residential',
    COMMERCIAL_CUSTOMER: 'commercialCustomer',
    COMMERCIAL_PROPERTY: 'commercialProperty',
    COMMERCIAL_JOB: 'commercialJob',
    COMMERCIAL_LEAD: 'commercialLead',
    CONTACT: 'contact',
    OPPORTUNITY: 'opportunity',
    MIX: 'mix',
    WARRANTY_JOB: 'warranty_job',
    WARRANTY_CASE: 'warranty_case',
    WARRANTY: 'warranty'
};

export const DUMMY_EMPTY_FIELD = 'N/A';

export const COMMERCIAL_PROPERTY_TABS = {
    UNITS: 'units',
    JOBS: 'jobs',
    LEADS: 'leads'
};
export const ORDER_RECEIVING_STATUS = {
    DELIVERY: 'Delivery'
}
export const ORDER_MILESTONES = {
    DRAFT: { order: 1, name: 'Draft', group: 'Order' },
    ORDERED: { order: 2, name: 'Ordered', group: 'Order' },
    ORDERED_MATERIALS: { order: 3, name: 'Ordered', group: 'Material' },
    DELIVERY_MATERIALS: { order: 4, name: 'Delivery', group: 'Material' },
    ASSIGNED: { order: 5, name: 'Assigned', group: 'Labor' },
    STARTED: { order: 6, name: 'Started', group: 'Labor' },
    COMPLETED: { order: 7, name: 'Completed', group: 'Labor' },
    CLOSED: { order: 8, name: 'Closed', group: 'Order' }
};

export const ORDER_STATUSES = {
    SAVE: 'Save',
    DRAFT_ORDER: 'DraftOrder',
    DRAFT_MATERIAL_LABOR: 'DraftMaterialLabor'
};

export const COMPANY_LIBRARY_TYPES = {
    ALL_MATERIALS: 'all_materials',
    CUSTOM_MATERIALS: 'custom_materials',
    CATALOG_MATERIALS: 'catalog_materials',
    LABORS: 'labors'
};

export const FILE_EXTENSIONS = {
    DOCUMENTS: ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'txt'],
    IMAGES: ['jpeg', 'jpg', 'png', 'gif'],
    VIDEOS: ['mkv', 'mp4']
};

export const PROFILE_PAGE_PARTS = {
    PROFILE: 'profile',
    CHANGE_PASSWORD: 'password',
    NOTIFICATIONS: 'notifications',
    CHANGE_EMAIL: 'email',
    CONFIGURE_EMAIL: 'configure'
};

export const DEFAULT_COLORS = {
    ERROR: '#f53847',
    WARNING: '#ffc928',
    SUCCESS: '#2ecc71'
};

export const PROJECT_COLORS = [
    '#101f32',
    '#006bfd',
    '#00a0ff',
    '#2ecc71',
    '#ffc928',
    '#ffa128',
    '#f53847',
    '#8876e2',
    '#212f40',
    '#3389fd',
    '#33b3ff',
    '#58d68d',
    '#ffd553',
    '#ffb453',
    '#f7606c',
    '#9685e5',
    '#878f98',
    '#7fb5fe',
    '#7fcfff',
    '#96e5b8',
    '#ffe493',
    '#ffd093',
    '#fa9ba3',
    '#bdb3ef',
    '#cfd2d6',
    '#cce1ff',
    '#ccecff',
    '#d5f5e3',
    '#fff4d4',
    '#ffecd4',
    '#fdd7da',
    '#e5e1f9',
];

export const ESTIMATE_TYPES = {
    BUILD: 'build',
    MANUAL: 'manual'
};

export const CALENDAR_PROVIDERS = {
    CRM: 'CRM',
    OUTLOOK: 'OUTLOOK'
};

export const EVENT_CREATORS = {
    TASK: 'task',
    CALENDAR: 'calendar'
};

export const CALENDAR_TYPES = {
    USER: 'user',
    TECHNICIAN: 'technician',
    CREW: 'crew',
    JOB: 'job'
};

export const SELECTED_TEMPLATE = {
    NO_TEMPLATE: 0,
    USE_TEMPLATE: 1
};

export const ACTION_MODE = {
    CREATE: 'create',
    EDIT: 'edit'
}

export const KEY_TEMPLATE = {
    EMAIL_TEMPLATE: 'email_template'
};

export const ENTITY_TYPE_ACTION = {
    TASK: 'task',
    MILESTONE: 'milestone',
    CREATE_CARD: 'create card',
    MOVE_CARD: 'move card',
    SEND_USPS: 'send usps',
    SEND_EMAIL :'notify'
};

export const ORDER_ITEMS_TYPE_ID = {
    MATERIALS: 1,
    LABORS: 2
};

export const DELIVERY_TIME = {
    AM: 'AM',
    PM: 'PM',
    ANYTIME: 'Anytime'
};

export const EVENTS_TYPE_SCHEDULER = {
    LABOR: 'Labor',
    MATERIAL: 'Material'
};

export const MAX_SIZE_FILES = {
    FILE_EMAIL_MESSSAGE: 1024 * 1024 * 30
};

export const NOT_ALLOWED_CHARACTERS = /^[a-zA-Z0-9-\'"\s\\/\\\|\(\)&\,\.]+$/;

export const ALLOWED_CHARACTERS_FILE = /^[a-zA-Z0-9-\_\-\s\.]+$/;

export const ALLOWED_CHARACTERS_FOLDER = /^[a-zA-Z0-9-\_\-\s]+$/;

export const ALLOWED_CHARACTERS_PROTOCOL = /(http(s?)):\/\//;

export const ALLOWED_CHARACTERS_WEBSITE = /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\\=]*)/;


export const RE_DISPATCH_STATUSES = {
    NOT_DISPATCH: 'not_dispatch',
    DISPATCHED_FROM: 'dispatched_from',
    DISPATCHED_TO: 'dispatched_to'
};

export const CURRENT_USER = {
    id: -1,
    fullname: "CURRENT_USER",
    label: "Curent User"
};

export const MESSAGE_TYPE = {
    RESIDENTIAL_LEAD: 'RL',
    RESIDENTIAL_JOB: 'RJ',
    COMMERCIAL_JOB: 'CJ',
    COMMERCIAL_LEAD: 'CL',
};

export const STATES = [
    {
        code: 'AL',
        name: 'Alabama'
    },
    {
        code: 'AK',
        name: 'Alaska'
    },
    {
        code: 'AZ',
        name: 'Arizona'
    },
    {
        code: 'AR',
        name: 'Arkansas'
    },
    {
        code: 'CA',
        name: 'California'
    },
    {
        code: 'CO',
        name: 'Colorado'
    },
    {
        code: 'CT',
        name: 'Connecticut'
    },
    {
        code: 'DE',
        name: 'Delaware'
    },
    {
        code: 'FL',
        name: 'Florida'
    },
    {
        code: 'GA',
        name: 'Georgia'
    },
    {
        code: 'HI',
        name: 'Hawaii'
    },
    {
        code: 'ID',
        name: 'Idaho'
    },
    {
        code: 'IL',
        name: 'Illinois'
    },
    {
        code: 'IN',
        name: 'Indiana'
    },
    {
        code: 'IA',
        name: 'Iowa'
    },
    {
        code: 'KS',
        name: 'Kansas'
    },
    {
        code: 'KY',
        name: 'Kentucky'
    },
    {
        code: 'LA',
        name: 'Louisiana'
    },
    {
        code: 'ME',
        name: 'Maine'
    },
    {
        code: 'MD',
        name: 'Maryland'
    },
    {
        code: 'MA',
        name: 'Massachusetts'
    },
    {
        code: 'MI',
        name: 'Michigan'
    },
    {
        code: 'MN',
        name: 'Minnesota'
    },
    {
        code: 'MS',
        name: 'Mississippi'
    },
    {
        code: 'MO',
        name: 'Missouri'
    },
    {
        code: 'MT',
        name: 'Montana'
    },
    {
        code: 'NE',
        name: 'Nebraska'
    },
    {
        code: 'NV',
        name: 'Nevada'
    },
    {
        code: 'NH',
        name: 'New Hampshire'
    },
    {
        code: 'NJ',
        name: 'New Jersey'
    },
    {
        code: 'NM',
        name: 'New Mexico'
    },
    {
        code: 'NY',
        name: 'New York'
    },
    {
        code: 'NC',
        name: 'North Carolina'
    },
    {
        code: 'ND',
        name: 'North Dakota'
    },
    {
        code: 'OH',
        name: 'Ohio'
    },
    {
        code: 'OK',
        name: 'Oklahoma'
    },
    {
        code: 'OR',
        name: 'Oregon'
    },
    {
        code: 'PA',
        name: 'Pennsylvania'
    },
    {
        code: 'RI',
        name: 'Rhode Island'
    },
    {
        code: 'SC',
        name: 'South Carolina'
    },
    {
        code: 'SD',
        name: 'South Dakota'
    },
    {
        code: 'TN',
        name: 'Tennessee'
    },
    {
        code: 'TX',
        name: 'Texas'
    },
    {
        code: 'UT',
        name: 'Utah'
    },
    {
        code: 'VT',
        name: 'Vermont'
    },
    {
        code: 'VA',
        name: 'Virginia'
    },
    {
        code: 'WA',
        name: 'Washington'
    },
    {
        code: 'WV',
        name: 'West Virginia'
    },
    {
        code: 'WI',
        name: 'Wisconsin'
    },
    {
        code: 'WY',
        name: 'Wyoming'
    },
    {
        code: 'DC',
        name: 'District of Columbia'
    }
];

export const SYSTEMS = {
    CRM: {
        name: 'CRM',
        code: 'crm'
    },
    RG: {
        name: 'RoofGeek',
        code: 'rg'
    }
}

export const TYPE_RG_JOB = {
    CREATED_JOB: 'created_job',
    UNCREATED_JOB: 'uncreated_job'
}

export const WARRANTY_FIELD_TYPE = {
    REGISTRATION_NUMBER: 'registration_number',
    CREW: 'crew_number',
    MANUFACTURER : 'manufacturer',
    MANUFACTURER_TYPE : 'manufacturer_type',
}

export const ENTITY_TYPE_SMARTDOC = {
    COMPANY: 'Company',
    RESIDENTIAL: 'Residential',
    COMMERCIAL_LEAD: 'CommercialLead',
    COMMERCIAL_JOB: 'CommercialJob',
    NOT_LINKED: 'NotLinked',
    SIGNATURE : 'Signature'
}
