<template>
    <div v-if="isShow" class="w-100">
        <loader
            v-if="loading && showAllResultBtn"
            size="small"
            class="mh-auto"
        />
        <loader
            v-else-if="loading"
            size="normal"
        />
        <div
            v-if="searchResult.length" 
            class="search-result"
        >
            <div 
                :class="[
                    'row-between row-baseline ph-20 pt-20 pb-7',
                    contentClass
                ]"
            >
                <router-link
                    v-if="showAllResultBtn"
                    class="f-13-grey"
                    :to="{ name: 'Search', query: { q: needToFind } }"
                    @click.native="isShow = false"
                >
                    Found
                    <span class="f-13-black">
                        {{ searchResult.length }} results
                    </span>
                </router-link>
                <p 
                    v-else
                    class="f-13-grey"
                >
                    Found
                    <span class="f-13-black">
                        {{ searchResult.length }} results
                    </span>
                </p>
                <button 
                    v-if="showCloseBtn"
                    class="icon-light"
                    @click="isShow = false"
                >
                    <close-icon/>
                </button>
            </div>
            <div class="column-static">
                <template v-for="(result, index) in searchResultLimit">
                    <p 
                        :key="index"
                        class="pl-20 f-12-asphalt f-sbold uppercase"
                    >
                        <template v-if="showAllResultBtn && index === customerAndPropertyTitle">
                            Customer &amp; Property
                        </template>
                        <template v-else-if="!showAllResultBtn && index === propertyIndex">
                            Property
                        </template>
                        <template v-else-if="!showAllResultBtn && index === customerIndex">
                            Customer
                        </template>
                        <template v-else-if="index === milestoneIndex">
                            Leads &amp; Jobs
                        </template>
                    </p>
                    <router-link
                        :key="`${result.id}-${result.type}`"
                        :class="[
                            'search-result-item row-baseline flex-no-wrap p-6 ph-20',
                            contentClass
                        ]"
                        :style="{ borderBottom: unvisibleBorder(index) ? '1px solid transparent' : ''}"
                        :to="routeToResource(result)"
                        @click.native="isShow = false"
                    >
                        <status-marker
                            v-if="milestoneShow(result.type)"
                            class="mr-5 flex-shrink-0"
                            :marker="getMilestoneTitle(result)"
                            :color="result.milestone_color"
                        />
                        <div class="item-desc ellipsis">
                            <div class="row-between flex-no-wrap">
                                <div class="row-start">
                                    <div 
                                        class="search-result-label s-align-5 residential uppercase f-9-white f-bold mr-2 mb-1">
                                        {{ getType(result.type) }}
                                    </div>
                                    <div 
                                        :class="[
                                            'search-result-label s-align-5 uppercase f-9-white f-bold mr-2 mb-1',
                                            getLabelClass(result)
                                        ]"
                                    >
                                        {{ baseEntityTypeMarker(result) }}
                                    </div>
                                    <p class="f-14-black t-line-height-1">
                                        {{ getResultItemName(result) }}
                                    </p>
                                </div>
                            </div>
                            <div class="row-between flex-no-wrap mt-1">
                                <p
                                    v-if="result.job_number"
                                    :class="[
                                        'no-wrap mr-1',
                                        !showAllResultBtn && dScreen ? 'f-12-black w-15' : 'f-11-black w-60'
                                    ]"
                                >
                                    <span 
                                        :class="[
                                            'mr-1',
                                            !showAllResultBtn ? 'f-12-grey' : 'f-11-grey'
                                        ]"
                                    >
                                        Job ID:
                                    </span>
                                    {{ collectJobNumber(result.job_number, result.prefix) }}
                                </p>
                                <p
                                    v-if="result.created_at && !result.job_number"
                                    :class="[
                                        'no-wrap mr-1',
                                        !showAllResultBtn && dScreen ? 'f-12-black w-15' : 'f-11-black w-60'
                                    ]"
                                >
                                    <span 
                                        :class="[
                                            'mr-1',
                                            !showAllResultBtn ? 'f-12-grey' : 'f-11-grey'
                                        ]"
                                    >
                                        Created:
                                    </span>
                                    {{ result.created_at | date }}
                                </p>
                                <p
                                    v-if="result.address"
                                    class="f-11-blue row-baseline flex-no-wrap ellipsis flex-shrink-0"
                                >
                                    <map-icon
                                        size="13"
                                        class="i-blue mr-2 s-align-2 flex-shrink-0"
                                    />
                                    {{ result.address }}
                                </p>
                            </div>
                        </div>
                    </router-link>
                </template>
            </div>
            <div
                v-if="showAllResultBtn"
                class="row-center"
            >
                <router-link
                    class="view-all-notification-button"
                    :to="toSearchPage"
                    @click.native="isShow = false"
                >
                    Show all results
                </router-link>
            </div>
        </div>
        <div 
            v-if="!searchResult.length && !loading" 
            class="search-result card-body"
        >
            <div :class="['row-between row-baseline p-6', contentClass]">
                <p class="f-13-grey">Found 
                    <span class="f-13-black">
                        0 results
                    </span>
                </p>
                <button 
                    v-if="showCloseBtn"
                    class="icon-light"
                    @click="isShow = false"
                >
                    <close-icon/>
                </button>
            </div>
            <div 
                v-if="showAllResultBtn"
                class="nothing-to-display pv-7 column-center t-center mb-8"
            >
                <img 
                    class="mb-4"
                    src="@/assets/images/not-found-icon.svg" 
                    width="80" 
                    height="76"
                    alt="Not Found"
                />
                <p class="f-14-darkgrey f-sbold mb-1">No Job or Lead matched your search.</p>
                <p class="f-12-darkgrey">Try to change your search request.</p>
            </div>
            <div 
                v-else
                class="nothing-to-display column-center t-center"
            >
                <img 
                    class="mb-8"
                    src="@/assets/images/not-found-icon.svg" 
                    width="185" 
                    height="175" 
                    alt="Not Found"
                />
                <p class="f-20-darkgrey f-sbold mb-2">No Job or Lead matched your search.</p>
                <p class="f-14-darkgrey">Try to change your search request.</p>
            </div>
        </div>
    </div>
</template>

<script>
    import { BASE_ENTITY_TYPES } from '@/constants.js'; 
    import StatusMarker from '@/components/StatusMarker.vue';
    import { clearArray, getAbbreviation, collectJobNumber } from '@/helpers';
    import { CloseIcon, MapIcon } from '@/icons';
    import api from '@/headers.js';
    import { Loader } from '@/ui';
    import moment from 'moment/src/moment';

    const COMMERCIAL = 'Commercial';
    const RESIDENTIAL = 'Residential';
    const WARRANTY = 'Warranty';

    export default {
        name: 'SearchResult',
        components: {
            Loader,
            CloseIcon,
            MapIcon,
            StatusMarker
        },
        props: {
            showAllResultBtn: {
                type: Boolean,
                default: false
            },
            showCloseBtn: {
                type: Boolean,
                default: false
            },
            contentClass: {
                type: String,
                default: ''
            },
            isCustomerSearch: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                BASE_ENTITY_TYPES: BASE_ENTITY_TYPES,
                isShow: false,
                loading: false,
                count: '',
                needToFind: '',
                searchResult: [],
                customerAndPropertyIndex: null,
                customerAndPropertyTitle: false,
                propertyIndex: null,
                customerIndex: null,
                milestoneIndex: null
            }
        },
        computed: {
            toSearchPage() {
                const route = {
                    name: 'Search',
                    query: { q: this.needToFind }
                };

                if (this.isCustomerSearch) {
                    route.query["customer_id"] = this.$route.params.customer_id;
                }

                return route;
            },
            sortedSearchResult() {
                return this.searchResult.sort((a, b) => {
                    if (moment(b.created_at).isBefore(moment(a.created_at))) { return -1; }
                    if (moment(a.created_at).isBefore(moment(b.created_at))) { return 1; }
                });
            },
            searchResultLimit() {
                let property = [];
                let customer = [];
                let jobs = [];
                let leads = [];
                let result = [];
                let warranty_job = [];

                property = this.sortedSearchResult.filter(result => result.type === BASE_ENTITY_TYPES.COMMERCIAL_PROPERTY);
                this.propertyIndex = property.length ? 0 : null;
                customer = this.sortedSearchResult.filter(result => result.type === BASE_ENTITY_TYPES.COMMERCIAL_CUSTOMER);
                this.customerIndex = customer.length ? property.length : null;
                jobs = this.sortedSearchResult.filter(result => result.type === BASE_ENTITY_TYPES.COMMERCIAL_JOB);
                leads = this.sortedSearchResult.filter(result => {
                    return result.type === BASE_ENTITY_TYPES.RESIDENTIAL 
                        || result.type === BASE_ENTITY_TYPES.COMMERCIAL_LEAD
                });
                warranty_job = this.sortedSearchResult.filter(result => result.type === BASE_ENTITY_TYPES.WARRANTY_JOB);

                this.milestoneIndex = jobs.length || leads.length ? property.length + customer.length : null;

                if (!this.showAllResultBtn) {
                    return result = [...property, ...customer, ...jobs, ...leads, ...warranty_job].slice(0, this.count);
                }

                if (property.length < 2) {
                    result = [...property, ...customer.slice(0, 2 - property.length)];
                } else  {
                    result = [...property.slice(0, 2)];
                }

                this.customerAndPropertyTitle = result.length ? 0 : null;
                this.customerAndPropertyIndex = result.length ? result.length : null;
                this.milestoneIndex = result.length ? result.length : null;

                return result = [...result, ...jobs.slice(0, 2), ...leads.slice(0, 2), ...warranty_job.slice(0, 2)].slice(0, this.count);
            },
        },
        methods: {
            getSearchCustomerResult(query, id, count) {
                this.isShow = true;
                this.loading = true;
                this.needToFind = query;
                this.count = count;
                clearArray(this.searchResult)

                api.get(`/commercial/customers/${id}/search`, { params: { 'value' : this.needToFind } })
                    .then(response => response.data.data)
                    .then(data => {
                        this.searchResult.push(...data);
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            getSearchResult(query, count) {
                if ((this.needToFind === query && this.needToFind !== null) || query === '') {
                    return;
                }

                this.isShow = true;
                this.loading = true;
                this.needToFind = query;
                this.count = count;
                clearArray(this.searchResult)
                
                api.get('/search', { params: { 'q' : this.needToFind } })
                    .then(response => response.data.data)
                    .then(data => {
                        this.searchResult.push(...data);
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            outside(event) {
                if (!this.$el.contains(event.target) && this.isShow && this.showAllResultBtn)
                    this.isShow = false;
            },
            getType(type) {
                let returnType = '';

                return returnType = type === this.BASE_ENTITY_TYPES.WARRANTY_JOB
                    ? returnType = WARRANTY
                    : returnType = type.includes(COMMERCIAL.toLowerCase()) ? COMMERCIAL : RESIDENTIAL;
            },
            baseEntityTypeMarker(result) {
                switch (result.type) {
                    case this.BASE_ENTITY_TYPES.RESIDENTIAL: {
                        if (result.job_number) {
                            return 'Job';
                        }

                        return 'Lead';
                    }
                    case this.BASE_ENTITY_TYPES.WARRANTY_JOB:
                    case this.BASE_ENTITY_TYPES.COMMERCIAL_JOB: {
                        return 'Job';
                    }
                    case this.BASE_ENTITY_TYPES.COMMERCIAL_LEAD: {
                        return 'Lead';
                    }
                    case this.BASE_ENTITY_TYPES.COMMERCIAL_CUSTOMER: {
                        return 'Customer';
                    }
                    case this.BASE_ENTITY_TYPES.COMMERCIAL_PROPERTY: {
                        return 'Property';
                    }
                }
            },
            routeToResource(item) {
                switch (item.type) {
                    case this.BASE_ENTITY_TYPES.RESIDENTIAL: {
                        return { 
                            name: 'Overview', 
                            params: { 
                                lead_id: item.id 
                            }
                        };
                    }
                    case this.BASE_ENTITY_TYPES.COMMERCIAL_JOB: {
                        return {
                            name: 'CommercialJobOverview', 
                            params: { 
                                property_id: item.property_id, 
                                job_id: item.id 
                            }
                        };
                    }
                    case this.BASE_ENTITY_TYPES.COMMERCIAL_LEAD: {
                        return {
                            name: 'CommercialLeadOverview',
                            params: {
                                lead_id: item.id
                            }
                        };
                    }
                    case this.BASE_ENTITY_TYPES.COMMERCIAL_CUSTOMER: {
                        return {
                            name: 'CommercialDetails',
                            params: {
                                customer_id: item.id
                            }
                        };
                    }
                    case this.BASE_ENTITY_TYPES.COMMERCIAL_PROPERTY: {
                        if (item.customer_id) {
                            return { 
                                name: 'CommercialDetails', 
                                params: { 
                                    customer_id: item.customer_id,
                                    property_id: item.id
                                }
                            };
                        }

                        return { 
                            name: 'CommercialPropertyWithoutCustomer', 
                            params: { 
                                property_id: item.id
                            }
                        };
                    }
                    case this.BASE_ENTITY_TYPES.WARRANTY_JOB: {
                        return {
                            name: 'WarrantyJobDetails',
                            params: {
                                job_id: item.id
                            }
                        };
                    }
                }
            },
            getResultItemName(resultItem) {
                switch (resultItem.type) {
                    case this.BASE_ENTITY_TYPES.RESIDENTIAL: {
                        return resultItem.customer;
                    }
                    case this.BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                    case this.BASE_ENTITY_TYPES.COMMERCIAL_LEAD: {
                        return resultItem.property;
                    }
                    case this.BASE_ENTITY_TYPES.COMMERCIAL_CUSTOMER: {
                        return resultItem.customer_name;
                    }
                    case this.BASE_ENTITY_TYPES.COMMERCIAL_PROPERTY: {
                        return resultItem.property_name;
                    }
                }
            },
            getLabelClass(result) {
                switch (result.type) {
                    case this.BASE_ENTITY_TYPES.RESIDENTIAL: {
                        if (result.job_number) {
                            return 'job';
                        }

                        return 'lead';
                    }
                    case this.BASE_ENTITY_TYPES.WARRANTY_JOB:
                    case this.BASE_ENTITY_TYPES.COMMERCIAL_JOB: {
                        return 'job';
                    }
                    case this.BASE_ENTITY_TYPES.COMMERCIAL_LEAD: {
                        return 'lead';
                    }
                    case this.BASE_ENTITY_TYPES.COMMERCIAL_CUSTOMER: {
                        return 'commercial-customer';
                    }
                    case this.BASE_ENTITY_TYPES.COMMERCIAL_PROPERTY: {
                        return 'commercial-property';
                    }
                }
            },
            unvisibleBorder(index) {
                return index === this.customerIndex - 1 && !this.showAllResultBtn
                    || index === this.milestoneIndex - 1 && !this.showAllResultBtn
                    || index === this.customerAndPropertyIndex - 1 && this.showAllResultBtn;
            },
            milestoneShow(type) {
                return type !== BASE_ENTITY_TYPES.COMMERCIAL_CUSTOMER 
                    && type !== BASE_ENTITY_TYPES.COMMERCIAL_PROPERTY
                    && type !== BASE_ENTITY_TYPES.WARRANTY_JOB;
            },
            getMilestoneTitle(result) {
                return !result.milestone_title ? '' : result.milestone_title;
            },
            collectJobNumber
        },
        mounted() {
            document.body.addEventListener('click', this.outside, false);
        },
        destroyed() {
            document.body.removeEventListener('click', this.outside, false);
        }
    }
</script>
