<template>
    <div class="column-start w-100">
        <div class="commercial-list-title row-between">
            <div class="commercial-list-nav-title s-align-4 mb-md-1 mb-sm-1">
                <h1 class="f-20-darkgrey f-sbold">
                    Commercial 
                    <span class="f-20-blue">Jobs</span>
                </h1>
            </div>
            <div class="s-align-6 md-6 sm-12">
                <slot name="default"></slot>
            </div>
        </div>
        <div class="commercial-list-nav ph-20 w-100 hidden">
            <div class="commercial-list-nav-container s-align-1 w-100">
                <router-link
                    v-for="(page, index) in pages"
                    :key="`page-${index}`"
                    :to="{ name: page.link }"
                    class="commercial-list-nav-link s-align-5 no-wrap"
                >
                    {{ page.name }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CommercialJobsNav',
        data() {
            return {
                pages: [
                    { name: 'Responsible billing party', link: 'CommercialCustomerList' },
                    { name: 'Property', link: 'CommercialPropertyList' },
                    { name: 'Unit', link: 'CommercialUnitList' },
                    { name: 'Job', link: 'CommercialJobList' }
                ]
            }
        }
    }
</script>