<template>
    <div
        class="form-date-range-input row-baseline row-between"
        :style="{ width: isFullWidth ? '100%' : 'auto' }"
    >
        <calendar-icon size="16"/>
        <v-date-picker
            class="date-picker-from column-center pl-6 h-100 pointer"
            :value="dateFrom"
            @dayclick="onFromChange"
            show-caps
            :popover-direction="popoverDirection"
            :tintColor="tintColor"
            :attributes="attributes"
            :disabledAttribute="disabledAttribute"
            :themeStyles="themeStyles"
        >
            <span
                v-if="value[0]"
                class="date"
            >
                <span v-if="ignoreTZ">
                    {{ value[0] | date_ignore_tz }}
                </span>
                <span v-else>
                    {{ value[0] | date }}
                </span>
            </span>
            <span class="placeholder ellipsis">From</span>
        </v-date-picker>
        <v-date-picker
            class="date-picker-to column-center pl-6 h-100 pointer"
            :value="dateTo"
            @dayclick="onToChange"
            show-caps
            :popover-direction="popoverDirection"
            :tintColor="tintColor"
            :attributes="attributes"
            :disabledAttribute="disabledAttribute"
            :themeStyles="themeStyles"
        >
            <span
                v-if="value[1]"
                class="date"
            >
                <span v-if="ignoreTZ">
                    {{ value[1] | date_ignore_tz }}
                </span>
                <span v-else>
                    {{ value[1] | date }}
                </span>
            </span>
            <span class="placeholder ellipsis">To</span>
        </v-date-picker>
    </div>
</template>

<script>
    import moment from 'moment/src/moment';
    import { CalendarIcon, ArrowIcon } from '@/icons';
    import calendarConfig from '@/mixins/calendarConfig';

    export default {
        name: 'FormDateRangeInput',
        components: {
            CalendarIcon,
            ArrowIcon
        },
        props: {
            // accept array of values
            // first value - date from
            // second date - date to
            value: {
                required: true,
                type: Array
            },
            isFullWidth: {
                type: Boolean,
                default: false
            },
            popoverDirection: {
                type: String,
                default: 'bottom'
            },
            ignoreTZ: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                selectedDate: null,
                value1: null,
                value2: null
            }
        },
        mixins: [ calendarConfig ],
        computed: {
            dateFrom() {
                // convert to JS Date object
                return moment(this.value[0]).toDate();
            },
            dateTo() {
                // convert to JS Date object
                return moment(this.value[1]).toDate();
            }
        },
        methods: {
            onFromChange(event) {
                this.$emit('input', [
                    moment(event.date).format('YYYY-MM-DD'),
                    this.value[1]
                ]);

                // unfocus on date picker after day click
                this.$el.querySelector('.date-picker-from').blur();
            },
            onToChange(event) {

              this.$emit('input', [
                    this.value[0],
                    moment(event.date).format('YYYY-MM-DD')
                ]);
              // unfocus on date picker after day click
              this.$el.querySelector('.date-picker-to').blur();
            }
        }
    }
</script>

