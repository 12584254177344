var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVisible),expression:"isVisible"}],staticClass:"w-100 pb-8"},[(Array.isArray(_vm.body.data))?_c('div',{staticClass:"column-start ph-8"},[(_vm.dScreen)?_c('div',{staticClass:"report-table-row report-table-head row-start"},_vm._l((_vm.head),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.disableEntityTypeColumn(item)),expression:"disableEntityTypeColumn(item)"}],key:index,staticClass:"report-table-cell f-10-grey f-sbold uppercase",style:({
                    width: _vm.dScreen ? ("calc(100%/" + _vm.columnsCount + ")") : '',
                    padding: _vm.columnsCount > 10 && _vm.dScreen ? '20px 3px' : ''
                })},[_vm._v("\n                "+_vm._s(item)+"\n            ")])}),0):_vm._e(),_vm._l((_vm.body.data),function(row,index){return _c('div',{key:index,class:[
                'report-table-row row-start',
                _vm.openedTableIndex === index ? 'row-open' : ''
            ]},[_c('router-link',{staticClass:"row-start",attrs:{"to":{ 
                    name: _vm.routeNameFromReport(row),
                    params: _vm.routeParamsFromReport(row)
                },"target":"_blank"}},[_c('button',{staticClass:"report-table-toggle icon-light",on:{"click":function($event){_vm.openedTableIndex = _vm.openedTableIndex == index ? null : index}}},[_c('arrow-icon',{attrs:{"transform":_vm.openedTableIndex === index ? 270 : 90}})],1),_vm._l((row),function(cell,key,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkReportSystemColumns(key)),expression:"checkReportSystemColumns(key)"}],key:key,staticClass:"report-table-cell column-start",style:({
                        width: _vm.dScreen ? ("calc(100%/" + _vm.columnsCount + ")") : '',
                        padding: _vm.columnsCount > 10 && _vm.dScreen ? '20px 3px' : ''
                    })},[(key === _vm.REPORT_COLUMNS.SOURCE.excerpt)?_c('span',{staticClass:"report-lead-source f-12-white"},[_vm._v("   \n                        "+_vm._s(cell || '-')+"\n                    ")]):(key === _vm.REPORT_COLUMNS.PROFIT_MARGIN.excerpt)?_c('span',{staticClass:"cell-value f-14-black"},[_vm._v("\n                        "+_vm._s(cell)+"%\n                    ")]):(key === _vm.REPORT_COLUMNS.BASE_BID.excerpt
                            || key === _vm.REPORT_COLUMNS.ESTIMATES_SIGNED_PRICE_TOTAL.excerpt
                            || key === _vm.REPORT_COLUMNS.EXPECTED_REVENUE.excerpt
                            || key === _vm.REPORT_COLUMNS.NET_PROFIT.excerpt)?_c('span',{staticClass:"cell-value f-14-black"},[_vm._v("\n                        $"+_vm._s(_vm._f("numberWithCommas")(cell))+"\n                    ")]):(key === _vm.REPORT_COLUMNS.PROPOSAL_DELIVERED.excerpt)?_c('span',{staticClass:"cell-value f-14-black"},[_vm._v("   \n                        "+_vm._s(_vm._f("date_ignore_tz")(cell || '-'))+"\n                    ")]):(_vm.dateTimeReportFields(key))?_c('span',{staticClass:"cell-value f-14-black"},[_vm._v("   \n                        "+_vm._s(_vm._f("considerTimeZone")(cell || '-'))+"\n                    ")]):_c('span',{staticClass:"cell-value f-14-black"},[_vm._v("\n                        "+_vm._s(cell || '-')+"\n                    ")]),_c('span',{staticClass:"cell-desc f-12-grey"},[_vm._v(_vm._s(_vm.head[index]))])])})],2)],1)})],2):_vm._l((_vm.body.data),function(item,key){return _c('div',{key:key,staticClass:"column-start ph-8 pt-8"},[_c('div',{class:[
                'table-group f-sbold pointer',
                ("table-group-depth-" + _vm.depth),
                _vm.depth > 1 ? 'row-start row-baseline' : 'row-between'
            ],on:{"click":function($event){_vm.groupVisibilityStates[key] = !_vm.groupVisibilityStates[key]}}},[_c('div',{class:[
                    'lg-6',
                    _vm.depth > 1 ? 'row-start row-baseline' : 'row-between' ]},[_c('div',{staticClass:"row-start row-baseline"},[_c('button',{staticClass:"transparent-button p-1 mr-2"},[_c('arrow-icon',{attrs:{"transform":_vm.groupVisibilityStates[key] ? 270 : 90}})],1),_c('span',[_vm._v(_vm._s(_vm._f("renameEntityTypeGrouping")(key)))]),(_vm.depth > 1)?_c('span',{staticClass:"table-group-count f-sbold s-align-5 ml-3"},[_vm._v("\n                        "+_vm._s(item.count)+"\n                    ")]):_vm._e()])]),(_vm.depth == 1)?_c('span',{class:[
                    'table-group-count f-sbold s-align-5 ml-3 lg-6',
                    _vm.groupVisibilityStates[key] ? 'active' : ''
                ]},[_vm._v("\n                "+_vm._s(item.count)+"\n            ")]):_vm._e(),(item.hasOwnProperty('totals'))?_c('div',{staticClass:"lg-6 pt-sm-0"},_vm._l((item.totals),function(total,index){return _c('div',{key:index,class:[ 
                        index > 1 ? 'mb-2' : '',
                        _vm.dScreen ? 'row-end' : 'row-start' 
                    ]},[_c('span',{staticClass:"f-13-grey mr-2"},[_vm._v(_vm._s(total.column_title)+":")]),_c('span',{staticClass:"f-13-black f-sbold"},[_vm._v(_vm._s(_vm._f("currency")(total.value)))])])}),0):_vm._e()]),_c('report-table',{attrs:{"head":_vm.head,"body":item,"depth":_vm.depth + 1,"isVisible":_vm.groupVisibilityStates[key]}})],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }