<template>
    <full-modal
        @close="$emit('close')"
        class="workflow-milestone-modal"
    >
    <div slot="title">Change Milestone</div>
    <div slot="body">
        <div 
            class="content-box row-between p-4 mb-2"
            v-for="(row, index) in data"
            :key="`row-${index}`"
        >
            <div :class="['lg-5 sm-12 pr-1 pr-sm-0', { 'pb-2': mScreen }]">
                <multiselect
                    :options="filteredLeadTypes"                                        
                    :name="`lead-type-${index}`"
                    :showLabels="false"
                    placeholder="Lead type"
                    v-validate="'required'"
                    v-model="row.lead_type"
                    label="name"
                    track-by="id"
                    :class="['multiselect-small', { 'multiselect-danger' : errors.has(`lead-type-${index}`) }]"
                />
            </div>
            <div class="lg-5 sm-12 pl-1 pl-sm-0">
                <multiselect
                    :options="row.lead_type ? filteredMilestones(index) : []"                                        
                    :name="`milestone-${index}`"
                    :showLabels="false"
                    placeholder="Milestone"
                    v-validate="row.lead_type ? 'required' : ''"
                    v-model="row.milestone"
                    label="slug"
                    track-by="id"
                    :class="['multiselect-small', { 'multiselect-danger' : errors.has(`milestone-${index}`) }]"
                    :disabled="!row.lead_type"
                >
                    <template 
                        slot="singleLabel" 
                        slot-scope="props"
                    >      
                        <template v-if="props.option.title !== undefined">
                            <div class="s-align-4">
                                <span
                                    :style="{'background-color' : props.option.color}"
                                    class="milestone-status-marker-small capitalize mr-2"
                                >
                                    {{ getAbbreviation(props.option.title) }}
                                </span>
                                <span>{{ props.option.title }}</span>
                            </div>
                        </template>
                    </template>
                    <template 
                        slot="option" 
                        slot-scope="props"
                    >   
                        <div class="s-align-4">
                            <span
                                :style="{'background-color' : props.option.color}"
                                class="milestone-status-marker-small capitalize mr-2"
                            >
                                {{ getAbbreviation(props.option.title) }}
                            </span>
                            <span>{{ props.option.title }}</span>
                        </div>
                    </template> 
                </multiselect>
            </div>
            <div class="lg-2 sm-12 s-align-6">
                <button
                    class="transparent-button mt-sm-1"
                    @click="removeRow(index)"
                    v-if="index > 0"
                >
                    <trash-icon class="i-grey"/>
                </button>
            </div>
        </div>
        <div class="w-100 pt-1">
            <button
                class="colored-large-button"
                @click="addRow"
            >
                <span class="add-icon s-align-5 mr-2 f-15-blue">+</span>Add
            </button>
        </div>
    </div>
    <div slot="footer">
        <button 
            class="add-button mb-2"
            @click="$emit('close')"
        >
            Cancel
        </button>
        <button 
            class="primary-button mb-2"
            @click="saveChanges"
        >
            Save
        </button>
    </div>
    </full-modal>
</template>

<script>
    import { FullModal } from '@/ui';
    import { TrashIcon } from '@/icons';
    import Multiselect from 'vue-multiselect';
    import { ENTITY_TYPES, ENTITY_LEAD_TYPES } from '@/constants';
    import { getAbbreviation } from '@/helpers';

    export default {
        name: 'WorkflowMilestonesModal',
        props: {
            leadTypes: {
                type: Array,
                required: true
            },
            milestones: {
                type: Array,
                required: true
            },
            existingData: {
                type: Array,
                required: true
            },
            entityName: {
                type: String,
                required: true
            }
        },
        components: {
            FullModal,
            Multiselect,
            TrashIcon
        },
        data() {
            return {
                data: [],
                getAbbreviation
            }
        },
        computed: {
            filteredLeadTypes() {
                if (this.entityName == ENTITY_TYPES.RESIDENTIAL_LEAD 
                    || this.entityName == ENTITY_TYPES.RESIDENTIAL_JOB) {
                    return this.leadTypes.filter(t => t.entity_type == ENTITY_LEAD_TYPES.RESIDENTIAL);
                } 
                
                if (this.entityName === ENTITY_TYPES.COMMERCIAL_LEAD) {
                    return this.leadTypes.filter(type => type.has_lead_type === 1);
                }

                if (this.entityName === ENTITY_TYPES.COMMERCIAL_JOB) {
                    return this.leadTypes.filter(type => type.entity_type === ENTITY_LEAD_TYPES.COMMERCIAL);
                }

                return [];
            }
        },
        methods: {
            addRow() {
                this.data.push({
                    lead_type: null,
                    milestone: null
                })
            },
            filteredMilestones(index) {
                const leadTypeGroupId = this.data[index].lead_type.group_id;
                const milestones = this.milestones.filter(m => m.group_id === leadTypeGroupId && !m.is_cancelled);
                const isBoundaryIndex = milestones.findIndex(b => b.is_boundary);

                if (this.entityName == ENTITY_TYPES.RESIDENTIAL_LEAD 
                    || this.entityName == ENTITY_TYPES.COMMERCIAL_LEAD) {
                    return milestones.slice(0, isBoundaryIndex);
                } else if (this.entityName == ENTITY_TYPES.RESIDENTIAL_JOB 
                    || this.entityName == ENTITY_TYPES.COMMERCIAL_JOB) {
                    return milestones.slice(isBoundaryIndex);
                }
                
                return [];
            },
            removeRow(index) {
                this.data.splice(index, 1);
            },
            saveChanges() {
                this.$validator.validateAll().then(result => {
                    if (!result) {
                        this.notifyError('Fill in required fields!');
                        return;
                    };

                    const data = [];
                    this.data.forEach((item) => {
                        data.push({
                            milestone_id: item.milestone.id,
                            lead_type_id: item.lead_type.id
                        });
                    });

                    this.$emit('setMilestones', data);
                });
            }
        },
        created() {
            if (this.existingData.length) {
                this.existingData.forEach((item) => {
                    this.data.push({
                        lead_type: this.leadTypes.find(l => l.id == item.lead_type_id),
                        milestone: this.milestones.find(m => m.id == item.milestone_id)
                    });
                });
            } else {
                this.addRow();
            }
        }
    }
</script>