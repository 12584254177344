<template>
    <full-modal @close="$emit('close')">
        <div slot="title">Property Settings</div>
        <div slot="body">
            <div class="w-100 pb-30">
                <form-input
                    label="Property name"
                    :class="[{ 'form-input-danger' : errors.has('property_name') }]"
                >
                    <input
                        type="text"
                        name="property_name"
                        id="property_name"
                        class="form-input-field"
                        placeholder="Property name"
                        v-input-style="property.property_name"
                        v-model="property.property_name || property.name"
                        v-validate="'required|max:250'"
                    />
                </form-input>
            </div>
            <p class="f-14-asphalt f-sbold pb-4">Address</p>
            <div class="w-100 pb-30">
                <address-box
                    type="address"
                    unicId="address"
                    :key="'main-address'"
                    :existing="propertyAddress"
                    :apartment="true"
                    @address="getPropertyAddress"
                >
                    <div
                        class="w-100 mb-4"
                        slot="apartment"
                    >
                        <form-input
                            label="Unit"
                            :class="[{ 'form-input-danger' : errors.has('property_unit') }]"
                        >
                            <input
                                type="text"
                                name="property_unit"
                                id="property_unit"
                                class="form-input-field"
                                placeholder="Unit"
                                v-input-style="propertyAddress.apartment"
                                v-model="propertyAddress.apartment"
                                v-validate="'max:50'"
                            />
                        </form-input>
                    </div>
                </address-box>
            </div>
            <div>
                <div class="row-between pb-4">
                    <p class="f-14-asphalt f-sbold">Billing address</p>
                    <checkbox-button
                        color="dark-blue"
                        label="Use the same address"
                        class="f-11-darkgrey f-sbold no-wrap"
                    >
                        <input 
                            type="checkbox" 
                            name="add_billing_address" 
                            v-model="useMainPropertyAddress"
                            @change="setPropertyBillingAddress($event)"
                        />
                    </checkbox-button>
                </div>
                <div class="w-100 pb-30">
                    <address-box
                        type="address"
                        unicId="billing"
                        :key="'billing-address'"
                        :existing="propertyBillingAddress"
                        :apartment="false"
                        :validate="'required'"
                        @address="getBillingAddress"
                    />
                </div>
            </div>
            <p class="f-14-asphalt f-sbold pb-4">Suite</p>
            <div class="w-100 pb-30">
                <form-input
                    label="Suite"
                    :class="[{ 'form-input-danger' : errors.has('suite') }]"
                >
                    <input
                        type="text"
                        name="suite"
                        id="suite"
                        class="form-input-field"
                        placeholder="Suite"
                        v-input-style="propertyBillingAddress.apartment"
                        v-model="propertyBillingAddress.apartment"
                        v-validate="{ required: false, max: 30, regex: titleRegex }"
                    />
                </form-input>
            </div>
        </div>
        <div slot="footer">
            <button 
                class="add-button"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button 
                class="primary-button"
                @click="updateProperty"
                ref="save"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import { FullModal, FormInput, CheckboxButton } from '@/ui';
    import {
        ADDRESS_TYPES,
        BASE_ENTITY_TYPES,
        EMAIL_TYPES, ENTITY_TYPES,
        NOT_ALLOWED_CHARACTERS
    } from '@/constants';
    import AddressBox from '@/components/AddressBox.vue';
    import { validationErrorNotification } from '@/helpers';
    import api from '@/headers.js';
    import cloneDeep from 'lodash/cloneDeep';

    export default {
        name: 'EditPropertyInCommercial',
        components: {
            FullModal, 
            FormInput,
            CheckboxButton,
            AddressBox
        },
        props: {
            property: {
                type: Object,
                required: true
            },
            entityId: {
                type: [String, Number],
                required: true
            },
            entityType: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                EMAIL_TYPES,
                ENTITY_TYPES,
                propertyAddress: {
                    address: '', 
                    street: '', 
                    city: '', 
                    state: '', 
                    zip: '', 
                    lat: null, 
                    lng: null,
                    apartment: ''
                },
                useMainPropertyAddress: false,
                propertyBillingAddress: {
                    address: '', 
                    street: '', 
                    city: '', 
                    state: '', 
                    zip: '',
                    apartment: '',
                    lat: null, 
                    lng: null
                },
                titleRegex: NOT_ALLOWED_CHARACTERS
            }
        },
        methods: {
            getPropertyAddress(data) {
                this.propertyAddress = data;
            },
            getBillingAddress(data) {
                this.propertyBillingAddress = data;
            },
            setPropertyBillingAddress(event) {
                if (event.target.checked) {
                    this.propertyBillingAddress = cloneDeep(this.propertyAddress);
                } else {
                    this.propertyBillingAddress = {
                        address: '',
                        street: '',
                        city: '',
                        state: '',
                        zip: '',
                        lat: null,
                        lng: null,
                    };
                }
            },
            updateProperty() {
                this.$children.forEach(vm => {
                    vm.$validator.validateAll();
                });

                this.$validator.validateAll().then(result => {
                    if (this.$validator.errors.items.length) {
                        const context = this;
                        validationErrorNotification(this.$validator, context);
                        return;
                    }

                    this.$refs.save.disabled = true;
                    this.property.address = this.propertyAddress;

                    if (this.property.addresses.billing) {
                        this.property.addresses.billing.splice(0, 1, this.propertyBillingAddress);
                    }

                    const property = {
                        id: this.property.id,
                        property_name: this.property.property_name || this.property.name,
                        addresses: [
                            {
                                ...this.propertyAddress,
                                type: ADDRESS_TYPES.MAIN
                            },
                            {
                                ...this.propertyBillingAddress,
                                type: ADDRESS_TYPES.BILLING
                            }
                        ]
                    }

                    api.put(`/commercial/properties/${this.entityId}`, property)
                        .then((response) => {
                            this.notifySuccess('Property is updated!');

                            const property = this.property;

                            switch (this.entityType) {
                                case BASE_ENTITY_TYPES.COMMERCIAL_LEAD:
                                    this.$store.commit('commercial/SET_PROPERTY_IN_COMMERCIAL_LEAD', property);
                                    break;
                                case BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                                    this.$store.commit('commercial/SET_PROPERTY_IN_COMMERCIAL_JOB', property);
                                    break;
                            }

                            this.$emit('close');
                            this.$emit('refreshData');
                        })
                        .catch(error => {
                            this.notifyRequestErrors(error);

                            this.$refs.save.disabled = false;
                        });
                });
            }
        },
        created() {
            switch (this.entityType) {
                case ENTITY_TYPES.WARRANTY_JOB:
                    if (this.property.addresses.length) {
                        this.property.addresses.forEach(el => {
                            if (el.type === EMAIL_TYPES.MAIN) {
                                this.propertyAddress = el;
                                delete this.propertyAddress.id;
                            } else {
                                this.propertyBillingAddress = el;
                                delete this.propertyBillingAddress.id;
                            }
                        })
                    }
                    break;
                default:
                    if (this.property.addresses.main.length) {
                        this.propertyAddress = cloneDeep(this.property.addresses.main[0]);
                        delete this.propertyAddress.id;
                    }

                    if (this.property.addresses.billing.length) {
                        this.propertyBillingAddress = cloneDeep(this.property.addresses.billing[0]);
                        delete this.propertyBillingAddress.id;
                    }
            }
        }
    }
</script>