<template>
    <div
        class="l-filter column-between-start"
        v-scroll="scrollListener"
    >
        <div class="l-filter-search row-start s-align-4">
            <search-icon/>
            <input
                type="text"
                name="leads_search"
                class="l-filter-input"
                placeholder="Filter Results"
                v-model="filtersInput"
            />
        </div>
        <perfect-scrollbar
            id="container-filters"
            class="ps-grey flex-grow-1"
        >
            <!-- <lead-filter-section :class="{ hide: checkFilterVisibility('Milestone Pipeline') }">
                <span slot="filter-head">Milestone Pipeline</span>
                <div
                    slot="filter-list"
                    class="row-between"
                    v-for="(item, index) in milestones"
                    :key="index"
                >
                    <div class="s-align-4">
                        <checkbox-button
                            color="blue"
                            :label="item.name"
                            class="f-13-black f-sbold"
                        >
                            <input
                                type="checkbox"
                                :name="'milestone-' + index"
                            />
                        </checkbox-button>
                    </div>
                    <div class="s-align-6">
                        <span class="f-13-black f-sbold">34</span>
                    </div>
                </div>
            </lead-filter-section> -->
            <lead-filter-section
                :count-elements="milestones && milestones.length"
                :class="{ hide: checkFilterVisibility('Milestone') }"
                keyFilter="milestone"
            >
                <span slot="filter-head">Milestone</span>
                <div
                    slot="filter-list"
                    class="row-between"
                    v-for="(item, index) in milestones"
                    :key="index"
                >
                    <div class="s-align-4 w-85">
                        <checkbox-button
                            color="blue"
                            :label="item.title"
                            class="f-13-black f-sbold w-100"
                        >
                            <input
                                type="checkbox"
                                :name="'milestone-' + index"
                                :id="'milestone-' + index"
                                v-model="filters.milestones"
                                :value="item.title"
                            />
                        </checkbox-button>
                    </div>
                    <div class="s-align-6">
                        <span class="f-12-grey">
                            {{ item.count }}
                        </span>
                    </div>
                </div>
            </lead-filter-section>
            <lead-filter-section
                :count-elements="assigned && assigned.length"
                :class="{ hide: checkFilterVisibility('Assigned To') }"
                keyFilter="assigned-to"
            >
                <span slot="filter-head">Assigned To</span>
                <div
                    slot="filter-list"
                    class="row-between"
                    v-for="(item, index) in assigned"
                    :key="index"
                >
                    <div class="s-align-4 w-85">
                        <checkbox-button
                            color="violet"
                            :label="item.fullname"
                            class="f-13-black f-sbold w-100"
                        >
                            <input
                                type="checkbox"
                                name="assigned"
                                :id="'assigned' + index"
                                v-model="filters.assigned"
                                :value="item.seller_id"
                            />
                        </checkbox-button>
                    </div>
                    <div class="s-align-6">
                        <span class="f-12-grey">
                            {{ item.count }}
                        </span>
                    </div>
                </div>
            </lead-filter-section>
            <lead-filter-section
                :count-elements="priorities && priorities.length"
                :class="[
                    'l-filter-priority',
                    { hide: checkFilterVisibility('Job Priority') }
                ]"
                keyFilter="priorities"
            >
                <span slot="filter-head">Job Priority</span>
                <div
                    slot="filter-list"
                    class="row-between"
                    v-for="(item, index) in priorities"
                    :key="index"
                >
                    <priority-checkbox
                        :priorityColor="item.color_code"
                        :priority="item.name"
                        :id="item.id"
                        :filters="filters.priorities"
                        class="pointer"
                    >
                        <input
                            type="checkbox"
                            name="job-priority"
                            :id="'job-priority' + index"
                            v-model="filters.priorities"
                            :value="item.id"
                        />
                    </priority-checkbox>
                    <div class="s-align-6">
                        <span class="f-12-grey">
                            {{ item.count }}
                        </span>
                    </div>
                </div>
            </lead-filter-section>
            <!-- <lead-filter-section :class="{ hide: checkFilterVisibility('Job Category') }">
                <span slot="filter-head">Job Category</span>
                <div
                    slot="filter-list"
                    class="row-between"
                    v-for="(item, index) in categories"
                    :key="index"
                >
                    <div class="s-align-4">
                        <checkbox-button
                            color="green"
                            :label="item.name"
                            class="f-13-black f-sbold"
                        >
                            <input
                                type="checkbox"
                                name="category"
                            />
                        </checkbox-button>
                    </div>
                    <div class="s-align-6">
                        <span class="f-13-black f-sbold">45</span>
                    </div>
                </div>
            </lead-filter-section> -->
            <lead-filter-section
                :count-elements="leadTypes && leadTypes.length"
                :class="{ hide: checkFilterVisibility('Lead Types') }"
                keyFilter="lead-types"
            >
                <span slot="filter-head">Lead Types</span>
                <div
                    slot="filter-list"
                    class="row-between"
                    v-for="(item, index) in leadTypes"
                    :key="index"
                >
                    <div class="s-align-4 w-85">
                        <checkbox-button
                            color="green"
                            :label="item.name"
                            class="f-13-black f-sbold w-100"
                        >
                            <input
                                type="checkbox"
                                name="lead-type"
                                :id="'lead-type' + index"
                                v-model="filters.lead_types"
                                :value="item.name"
                            />
                        </checkbox-button>
                    </div>
                    <div class="s-align-6">
                        <span class="f-12-grey">
                            {{ item.count }}
                        </span>
                    </div>
                </div>
            </lead-filter-section>
            <lead-filter-section
                :count-elements="trades && trades.length"
                :class="{ hide: checkFilterVisibility('Trade') }"
                keyFilter="trade"
            >
                <span slot="filter-head">Trade</span>
                <div
                    slot="filter-list"
                    class="row-between"
                    v-for="(item, index) in trades"
                    :key="index"
                >
                    <div class="s-align-4 w-85">
                        <checkbox-button
                            color="red"
                            :label="item.name"
                            class="f-13-black f-sbold w-100"
                        >
                            <input
                                type="checkbox"
                                name="trades"
                                :id="'trades' + index"
                                v-model="filters.trades"
                                :value="item.id"
                            />
                        </checkbox-button>
                    </div>
                    <div class="s-align-6">
                        <span class="f-12-grey">
                            {{ item.count }}
                        </span>
                    </div>
                </div>
            </lead-filter-section>
            <lead-filter-section
                :count-elements="workTypes && workTypes.length"
                :class="{ hide: checkFilterVisibility('Home Type') }"
                keyFilter="home-type"
            >
                <span slot="filter-head">Home Type</span>
                <div
                    slot="filter-list"
                    class="row-between"
                    v-for="(item, index) in workTypes"
                    :key="index"
                >
                    <div class="s-align-4 w-85">
                        <checkbox-button
                            color="dark"
                            :label="item.name"
                            class="f-13-black f-sbold w-100"
                        >
                            <input
                                type="checkbox"
                                name="work-type"
                                :id="'work-type' + index"
                                v-model="filters.home_types"
                                :value="item.name"
                            />
                        </checkbox-button>
                    </div>
                    <div class="s-align-6">
                        <span class="f-12-grey">
                            {{ item.count }}
                        </span>
                    </div>
                </div>
            </lead-filter-section>
            <lead-filter-section
                :count-elements="leadSources && leadSources.length"
                :class="{ hide: checkFilterVisibility('Lead Source') }"
                keyFilter="lead-source"
            >
                <span slot="filter-head">Lead Source</span>
                <div
                    slot="filter-list"
                    class="row-between"
                    v-for="(item, index) in leadSources"
                    :key="index"
                >
                    <div class="s-align-4 w-85">
                        <checkbox-button
                            color="blue"
                            :label="item.name"
                            class="f-13-black f-sbold w-100"
                        >
                            <input
                                type="checkbox"
                                name="lead-source"
                                :id="'lead-source-' + index"
                                v-model="filters.lead_sources"
                                :value="item.name"
                            />
                        </checkbox-button>
                    </div>
                    <div class="s-align-6">
                        <span class="f-12-grey">
                            {{ item.count }}
                        </span>
                    </div>
                </div>
            </lead-filter-section>
            <lead-filter-section
                :count-elements="locationsJobs && locationsJobs.length"
                :class="{ hide: checkFilterVisibility('City, State') }"
                keyFilter="city"
            >
                <span slot="filter-head">City, State</span>
                <div
                    slot="filter-list"
                    class="row-between"
                    v-for="(item, index) in locationsJobs"
                    :key="index"
                >
                    <div class="s-align-4 w-85">
                        <checkbox-button
                            color="orange"
                            :label="item.location"
                            class="f-13-black f-sbold w-100"
                        >
                            <input
                                type="checkbox"
                                name="locations-jobs"
                                :id="'locations-jobs' + index"
                                v-model="filters.locations"
                                :value="item.location"
                            />
                        </checkbox-button>
                    </div>
                    <div class="s-align-6">
                        <span class="f-12-grey">
                            {{ item.count }}
                        </span>
                    </div>
                </div>
            </lead-filter-section>
        </perfect-scrollbar>
        <div class="w-100 l-filter-expand-all">
            <checkbox-button
                class="ph-20 pv-15 s-align-1 w-100 f-12-black"
                color="grey"
                label="Expand all items"
            >
                <input
                    type="checkbox"
                    name="expand_all"
                    id="expand_all"
                    v-model="isExpandAll"
                    @change="checkExpandAll"
                />
            </checkbox-button>
        </div>
    </div>
</template>

<script>
    import { SearchIcon, ArrowIcon } from '@/icons';
    import { CheckboxButton, PriorityCheckbox } from '@/ui';
    import LeadFilterSection from '../LeadFilterSection.vue';
    import api from '@/headers.js';
    import debounce from 'lodash/debounce';
    import isEmpty from 'lodash/isEmpty';
    import { BASE_ENTITY_TYPES } from '@/constants';

    export default {
        name: 'ResidentialLeadsFilter',
        props: [],
        components: {
            SearchIcon,
            ArrowIcon,
            CheckboxButton,
            LeadFilterSection,
            PriorityCheckbox
        },
        data() {
            return {
                priorities: [],
                // categories: [],
                workTypes: [],
                trades: [],
                locationsJobs: [],
                assigned: [],
                leadSources: [],
                milestones: [],
                leadTypes: [],
                filters: {
                    priorities: [],
                    home_types: [],
                    trades: [],
                    locations: [],
                    assigned: [],
                    lead_sources: [],
                    milestones: [],
                    lead_types: []
                },
                filtersInput: '',
                isExpandAll: true
            }
        },
        watch: {
            filters: {
                handler(newValue, oldValue) {
                    this.checkFilter(newValue);
                },
                deep: true
            }
        },
        methods: {
            getData() {
                const params = {
                    needed: [
                        'priority_jobs',
                        'home_type_jobs',
                        'trade_jobs',
                        'locations_jobs',
                        'seller_jobs',
                        'lead_source_jobs',
                        'milestones',
                        'lead_type_jobs'
                    ],
                    entityType: BASE_ENTITY_TYPES.RESIDENTIAL
                };

                api.get('/handbooks', { params })
                    .then(response => response.data.data)
                    .then(data => {
                        this.priorities = data.priority_jobs;
                        this.workTypes = data.home_type_jobs;
                        this.trades = data.trade_jobs;
                        this.locationsJobs = data.locations_jobs;
                        this.assigned = data.seller_jobs;
                        this.leadSources = data.lead_source_jobs;
                        this.milestones = data.milestones;
                        this.leadTypes = data.lead_type_jobs;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    });
            },
            checkFilter: debounce(function (value) {
                let updatedFilter = {};
                for (const item in value) {
                    value[item].length ? updatedFilter[item] = value[item] : '';
                };

                this.$emit('filters', updatedFilter);
            }, 500),
            checkFilterVisibility(filterName) {
                return !filterName.toLowerCase().includes(this.filtersInput.toLowerCase());
            },
            checkExpandAll() {
                this.$store.commit('filters/SET_EXPAND_ALL', this.isExpandAll);
                if (!this.isExpandAll) {
                    document.getElementById('container-filters').scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                }
            },
            scrollListener: function (event, el) {
                if (window.innerWidth > 1165) {
                    window.scrollY > 50
                        ? el.style.paddingTop = 70 + 'px'
                        : el.style.paddingTop = 150 + 'px';
                } else {
                    return false;
                }
            },
            prepareFilters() {
                if (!isEmpty(this.$route.query)) {

                    Object.keys(this.$route.query).map(q => {
                        if (q == 'locations') {
                            this.filters[q] = this.$route.query[q].match(/[a-z.\s]+,[a-z.\s]+/ig);
                        } else {
                            this.filters[q] = this.$route.query[q].split(",");
                        }
                    });
                }
                return Promise.resolve();
            }
        },
        created() {
            this.prepareFilters().then(() => {
                this.getData();
            });
        }
    }
</script>
