<template>
    <div class="section section-default relative mb-7 p-2">
        <div class="section-title row-between row-baseline mb-4">
            <p class="f-18-black f-sbold">{{ defaultSection.name }}</p>
        </div> 
        <div class="section-fields row-between">
            <div 
                v-for="(field,index) in defaultSection.fields"
                :key="index"
                class="field"
                v-show="showFieldInTab(field)"
            >
                <div class="field-container row-start row-baseline required">
                    <div class="field-type row-start row-baseline">
                        <div class="field-icon s-align-4 mr-2">
                            <component :is="currentIcon(field.name)"/>
                        </div>
                        <span class="f-14-grey">
                            {{ field.name }}
                        </span>
                    </div>
                    <span class="field-name f-14-darkgrey">
                        {{ field.label }}
                    </span>
                    <button 
                        class="context-menu-toggle icon-light" 
                        v-if="field.data" 
                        @click="openFieldSettings(field)"
                    >
                        <edit-icon size="15"/>
                    </button>
                    <router-link
                        v-else-if="field.link" 
                        class="context-menu-toggle  icon-light"
                        :to="{ name: field.link }"
                    >
                        <edit-icon size="15"/>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="section-fields row-between">
            <div 
                v-for="(field, index) in customPrimaryFields"
                :key="index"
                class="field"
            >
                <field 
                    v-if="field.id"
                    :fieldInPrimarySection="true"
                    :field="field"
                    @edit="editField(field)"
                    @updated="updateField"
                />
            </div>
        </div>
        <work-items-properties-modal
            v-if="!!showWorkItemsEdit"
            @close="showWorkItemsEdit = false"
            @onUpdate="updateItems"
            :editingField="editingField"
            :entityType="entityType"
        />
        <contact-types-properties
            v-if="!!showContactTypeEdit"
            @close="showContactTypeEdit = false"
            :entityType="entityType"
        />
        <custom-fields-single-line-modal
            v-if="showSingleLineEdit"
            :section-id="customPrimarySection[0].id"
            :editing-field="editingField"
            :fieldInPrimarySection="true"
            @close="closePopup"
            @updateField="updateField"
        />
    </div>
</template>

<script>
    import api from '@/headers.js';
    import { Loader } from '@/ui';
    import { Field } from '@/components';
    import { 
        SingleLineIcon, MultilineIcon, EnvelopeIcon as EmailIcon, PhoneIcon, PickListIcon, AddCustomerIcon,
        MultiselectIcon, CalendarIcon as DateIcon, ClockIcon as DateTimeIcon, CurrencyIcon, DecimalIcon, 
        PercentIcon, LongIntegerIcon, CheckboxIcon, UrlIcon, SearchIcon as LookupIcon,
        FileUploadIcon, GearIcon, PersonIcon as MultiUserIcon, CloseIcon, EyeIcon, LineIcon, EditIcon,
        CalendarIcon, MapIcon
    } from '@/icons';
    import { WorkItemsPropertiesModal, ContactTypesProperties, CustomFieldsSingleLineModal }  from '@/modals';
    import { BASE_ENTITY_TYPES, CUSTOM_FIELDS_PAGES, CUSTOM_FIELDS_TYPES } from '@/constants.js';

    const UNIT_TAB = 'unit';

    export default {
        name: 'PrimarySection',
        components: {
            AddCustomerIcon,
            ContactTypesProperties, EyeIcon, LineIcon,SingleLineIcon,
            MultilineIcon, EmailIcon, PhoneIcon, PickListIcon, MultiselectIcon,
            DateIcon, DateTimeIcon, CurrencyIcon, DecimalIcon, PercentIcon,
            LongIntegerIcon, CheckboxIcon, UrlIcon, LookupIcon, FileUploadIcon, 
            GearIcon, MultiUserIcon, CloseIcon, WorkItemsPropertiesModal, EditIcon,
            Loader, CalendarIcon, MapIcon, Field, CustomFieldsSingleLineModal
        },
        props: {
            customPrimarySection: {
                type: Array,
                default() {
                    return [];
                }
            }
        },
        data() {
            return {
                lead_types: [],
                material_list: [],
                home_types: [],
                lead_sources: [],
                lead_priorities: [],
                contact_types: [],
                showWorkItemsEdit: false,
                editingField: null,
                residentialType: BASE_ENTITY_TYPES.RESIDENTIAL,
                showContactTypeEdit: false,
                showSingleLineEdit: false
            }
        },
        computed: {
            customPrimaryFields() {
                if (this.customPrimarySection && this.customPrimarySection.length) {
                    return this.customPrimarySection[0].fields;
                }
            },
            defaultSection() {
                return {
                    name: 'Primary Contacts',
                    fields: [
                        { 
                            label: 'Property Name',
                            name: 'Pick List',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_LEAD
                            ]
                        },
                        { 
                            label: 'Responsible billing party',
                            name: 'Pick List',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_CUSTOMER
                            ]
                        },
                        { 
                            label: 'Care of',
                            name: 'Single Line',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_CUSTOMER
                            ]
                        },
                        {
                            label: 'Property name',
                            name: 'Single Line',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_PROPERTY
                            ]
                        },
                        {
                            label: 'Designation',
                            name: 'Checkbox',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_PROPERTY
                            ]
                        },
                        {
                            label: 'Division',
                            name: 'Checkbox',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_PROPERTY
                            ]
                        },
                        {
                            label: 'Allow to be selected as a location?',
                            name: 'Checkbox',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_PROPERTY
                            ]
                        },
                        {
                            label: 'Bid Due Date',
                            name: 'Calendar',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_LEAD
                            ]
                        },
                        {
                            label: 'First name',
                            name: 'Single Line',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_LEAD,
                                BASE_ENTITY_TYPES.RESIDENTIAL
                            ]
                        },
                        {
                            label: 'Last name',
                            name: 'Single Line',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_LEAD,
                                BASE_ENTITY_TYPES.RESIDENTIAL
                            ]
                        },
                        {
                            label: 'Address',
                            name: 'Map',
                            showInLead: [
                                BASE_ENTITY_TYPES.RESIDENTIAL,
                                BASE_ENTITY_TYPES.COMMERCIAL_PROPERTY,
                                UNIT_TAB,
                                BASE_ENTITY_TYPES.COMMERCIAL_JOB
                            ]
                        },
                        {
                            label: 'Title',
                            name: 'Single Line',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_LEAD
                            ]
                        },
                        {
                            label: 'Job Number',
                            name: 'Single Line',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_JOB
                            ]
                        },
                        {
                            label: 'Unit Name',
                            name: 'Single Line',
                            showInLead: [
                                UNIT_TAB
                            ]
                        },
                        {
                            label: 'Store',
                            name: 'Single Line',
                            showInLead: [
                                UNIT_TAB
                            ]
                        },
                        {
                            label: 'Email',
                            name: 'Email',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_LEAD,
                                BASE_ENTITY_TYPES.RESIDENTIAL,
                                BASE_ENTITY_TYPES.COMMERCIAL_CUSTOMER
                            ]
                        },
                        {
                            label: 'Billing Email',
                            name: 'Email',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_CUSTOMER
                            ]
                        },
                        {
                            label: 'Lead Address',
                            name: 'Map',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_LEAD
                            ]
                        },
                        {
                            label: 'Appointment Address',
                            name: 'Map',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_LEAD
                            ]
                        },
                        {
                            label: 'Mailing Address',
                            name: 'Map',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_CUSTOMER,
                                BASE_ENTITY_TYPES.COMMERCIAL_PROPERTY
                            ]
                        },
                        {
                            label: 'Contact types',
                            name: 'Contact',
                            data: this.contact_types, 
                            type: 'contact_types',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_LEAD,
                                BASE_ENTITY_TYPES.COMMERCIAL_CUSTOMER,
                                BASE_ENTITY_TYPES.COMMERCIAL_PROPERTY,
                                UNIT_TAB,
                                BASE_ENTITY_TYPES.COMMERCIAL_JOB
                            ]
                        },
                        {
                            label: 'Notes',
                            name: 'Multiline',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_CUSTOMER,
                                BASE_ENTITY_TYPES.COMMERCIAL_PROPERTY
                            ]
                        },
                        {
                            label: 'Lead type',
                            name: 'Pick list',
                            data: this.entityType == this.residentialType ? this.lead_types : null,
                            type: 'lead_types',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_LEAD,
                                BASE_ENTITY_TYPES.RESIDENTIAL
                            ]
                        },
                        {
                            label: 'Material',
                            name: 'Pick list',
                            data: this.material_list,
                            type: 'material_list',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_LEAD,
                                BASE_ENTITY_TYPES.RESIDENTIAL
                            ]
                        },
                        {
                            label: 'Home type',
                            name: 'Pick list',
                            data: this.home_types,
                            type: 'home_types',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_LEAD,
                                BASE_ENTITY_TYPES.RESIDENTIAL
                            ]
                        },
                        {
                            label: 'Lead source',
                            name: 'Pick list',
                            data: this.lead_sources,
                            type: 'lead_sources',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_LEAD,
                                BASE_ENTITY_TYPES.RESIDENTIAL
                            ]
                        },
                        {
                            label: 'Priorities',
                            name: 'Pick list',
                            data: this.lead_priorities,
                            type: 'lead_priorities',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_LEAD,
                                BASE_ENTITY_TYPES.RESIDENTIAL
                            ]
                        },
                        {
                            label: 'Trades',
                            name: 'Multiselect',
                            link: 'Trades',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_LEAD,
                                BASE_ENTITY_TYPES.RESIDENTIAL
                            ]
                        },
                        {
                            label: 'Bid Due Date',
                            name: 'Calendar',
                            showInLead: [
                                BASE_ENTITY_TYPES.RESIDENTIAL
                            ]
                        },
                        {
                            label: 'Salesman',
                            name: 'Multi user',
                            showInLead: [
                                BASE_ENTITY_TYPES.COMMERCIAL_LEAD,
                                BASE_ENTITY_TYPES.RESIDENTIAL
                            ]
                        }
                    ]
                };
            },
            entityType() {
                switch (this.$route.name) {
                    case CUSTOM_FIELDS_PAGES.RESIDENTIAL: {
                        return BASE_ENTITY_TYPES.RESIDENTIAL;
                    }
                    case CUSTOM_FIELDS_PAGES.COMMERCIAL_LEAD: {
                        return BASE_ENTITY_TYPES.COMMERCIAL_LEAD;
                    }
                }
            }
        },
        methods: {
            openFieldSettings(data){
                if (data.type == "contact_types") {
                    this.showContactTypeEdit = true;
                } else {
                    this.showWorkItemsEdit = true;
                    this.editingField = data;
                }
            },
            getHandbooks() {
                const params = {
                    needed: [
                        'lead_types',
                        'materials',
                        'home_types',
                        'lead_sources',
                        'priorities'
                    ],
                    entityType: this.entityType
                };

                api.get('/handbooks', { params })
                    .then(response => response.data.data)
                    .then(data => {
                        this.lead_types.push(...data.lead_types);
                        this.material_list.push(...data.materials);
                        this.home_types.push(...data.home_types);
                        this.lead_sources.push(...data.lead_sources);
                        this.addReferralFirst();
                        this.lead_priorities.push(...data.priorities);
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    });
            },
            updateItems(data, splice = true) {
                let variableName = this.editingField.type;

                if (splice) {
                    this[variableName].splice(0, this[variableName].length, ...data);
                }

                this.showWorkItemsEdit = false;
            },
            currentIcon(name) {
                if (name === 'Contact') {
                    return 'add-customer-icon';
                }

                let words = name.split(' ').map(el => el.toLowerCase());
                words.push('icon');
                return words.join('-');
            },
            showFieldInTab(field) {
                let entityTypeTab = '';

                switch (this.$route.name) {
                    case CUSTOM_FIELDS_PAGES.RESIDENTIAL: {
                        entityTypeTab = BASE_ENTITY_TYPES.RESIDENTIAL;
                    }
                    break;
                    case CUSTOM_FIELDS_PAGES.COMMERCIAL_LEAD: {
                        entityTypeTab = BASE_ENTITY_TYPES.COMMERCIAL_LEAD;
                    }
                    break;
                    case CUSTOM_FIELDS_PAGES.COMMERCIAL_JOB.CUSTOMER: {
                        entityTypeTab = BASE_ENTITY_TYPES.COMMERCIAL_CUSTOMER;
                    }
                    break;
                    case CUSTOM_FIELDS_PAGES.COMMERCIAL_JOB.PROPERTY: {
                        entityTypeTab = BASE_ENTITY_TYPES.COMMERCIAL_PROPERTY;
                    }
                    break;
                    case CUSTOM_FIELDS_PAGES.COMMERCIAL_JOB.UNIT: {
                        entityTypeTab = UNIT_TAB;
                    }
                    break;
                    case CUSTOM_FIELDS_PAGES.COMMERCIAL_JOB.JOB: {
                        entityTypeTab = BASE_ENTITY_TYPES.COMMERCIAL_JOB;
                    }
                }

                return field.showInLead.includes(entityTypeTab);
            },
            editField(field) {
                this.editingField = field;

                switch(this.editingField.type.name) {
                    case CUSTOM_FIELDS_TYPES.PHONE:
                        this.showSingleLineEdit = true;
                        break;
                    default:
                        this.showSingleLineEdit = true;
                        break;
                }
            },
            updateField(field) {
                let fieldIndex = this.customPrimarySection[0].fields.findIndex((f) => f.id === field.id);
                this.customPrimarySection[0].fields.splice(fieldIndex, 1, field);
                // close popup
                this.showSingleLineEdit = false;
            },
            closePopup() {
                this.editingField = {};
                this.showSingleLineEdit = false;
            },
            addReferralFirst() {
                const referralIndex = this.lead_sources.findIndex(el => el.has_children === true);
                const leadReferral = this.lead_sources.splice(referralIndex, 1);
                return this.lead_sources.unshift(leadReferral[0]);
            }
        },
        created() {
            this.getHandbooks();
        },
}
</script>