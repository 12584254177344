import SetQualityControlModal from './SetQualityControlModal';
import CreateNewFolderModal from './CreateNewFolderModal';
import CreateNewDefaultFolderModal from './CreateNewDefaultFolderModal';
import DocumentsMoveToModal from './DocumentsMoveToModal.vue';
import TemplateEditLibraryItemModal from './TemplateEditLibraryItemModal.vue';
import AddEstimateSectionModal from './AddEstimateSectionModal.vue';
import AddDiscountModal from './AddDiscountModal.vue';
import MapModal from './MapModal.vue';
import EditResidentialJobModal from './EditResidentialJobModal.vue';
import CustomItemCreateEdit from './CustomItemCreateEdit.vue';
import UserListModal from './UserListModal.vue';
import SaveEstimatePdfModal from './SaveEstimatePdfModal.vue';
import EstimateEditItemModal from './EstimateEditItemModal.vue';
import CreateNewTemplateModal from './CreateNewTemplateModal.vue';
import CopyTemplateModal from './CopyTemplateModal.vue';
import CustomFieldsMultiselectModal from './CustomFieldsMultiselectModal.vue';
import WorkItemsPropertiesModal from './WorkItemsPropertiesModal.vue';
import ContactTypesProperties from "./ContactTypesPropertiesModal";
import CustomFieldsSingleLineModal from './CustomFieldsSingleLineModal.vue';
import DocumentsUploadFileModal from './DocumentsUploadFileModal.vue';
import AddNoteModal from './AddNoteModal.vue';
import SignModal from './SignModal.vue';
import EditJobCustomFieldsModal from './EditJobCustomFieldsModal.vue';
import EditCompanyInfoModal from './EditCompanyInfoModal.vue';
import AddLibraryItemsModal from './AddLibraryItemsModal.vue';
import EditJobTaskModal from './EditJobTaskModal.vue';
import FormBuilderSendReportModal from './FormBuilderSendReportModal.vue';
import FormEmailTemplateModal from './FormEmailTemplateModal.vue';
import CustomFieldsCreateLayoutModal from './CustomFieldsCreateLayoutModal.vue';
import RepresentativesModal from './RepresentativesModal.vue';
import CustomFieldsEditLayoutsModal from './CustomFieldsEditLayoutsModal.vue';
import CustomFieldsEditFormBuilderModal from './CustomFieldsEditFormBuilderModal.vue';
import CreateNewTaxModal from './CreateNewTaxModal.vue';
import EstimateTaxModal from './EstimateTaxModal.vue';
import CustomerPortalSettingsModal from './CustomerPortalSettingsModal.vue';
import EditCommercialCustomerSectionModal from './EditCommercialCustomerSectionModal.vue';
import EditCommercialPropertySectionModal from './EditCommercialPropertySectionModal.vue';
import EditCommercialUnitModal from './EditCommercialUnitModal.vue';
import CopyEstimateModal from './CopyEstimateModal.vue';
import CustomFieldsAddressModal from './CustomFieldsAddressModal.vue';
import CustomFieldsMultilineModal from './CustomFieldsMultilineModal.vue';
import EditCommercialLeadModal from './EditCommercialLeadModal.vue';
import EditCustomerInCommercial from './EditCustomerInCommercial.vue';
import EditPropertyInCommercial from './EditPropertyInCommercial.vue';
import ConfigureJobNumberingModal from './ConfigureJobNumberingModal.vue';
import ConfigureIdNumberingModal from './ConfigureIdNumberingModal.vue';
import CreateNewEventModal from './CreateNewEventModal.vue';
import EditCommercialJobModal from './EditCommercialJobModal.vue';
import ProjectManagementArchiveCardModal from './ProjectManagementArchiveCardModal.vue';
import ProjectManagementDeleteCardModal from './ProjectManagementDeleteCardModal.vue';
import ProjectManagementArchiveColumnModal from './ProjectManagementArchiveColumnModal.vue';
import ProjectManagementDeleteColumnModal from './ProjectManagementDeleteColumnModal.vue';
import OrderAddFromTemplateModal from './OrderAddFromTemplateModal.vue';
import OrderAddFromCompanyLibraryModal from './OrderAddFromCompanyLibraryModal.vue';
import OrderAddCustomItemModal from './OrderAddCustomItemModal.vue';
import OrderAddFromEstimateModal from './OrderAddFromEstimateModal.vue';
import OrderEditOverallProgressModal from './OrderEditOverallProgressModal.vue';
import OrderViewLogModal from './OrderViewLogModal.vue';
import ViewLogModal from './ViewLogModal.vue';
import MilestonesSettingsModal from './MilestonesSettingsModal.vue';
import AssignSalesmanModal from './AssignSalesmanModal.vue';
import MilestoneSetModal from './MilestoneSetModal.vue';
import SendNewEmailModal from './SendNewEmailModal.vue';
import FilePreviewModal from './FilePreviewModal.vue';
import PriceListModal from './PriceListModal.vue';
import AddVendorModal from './AddVendorModal.vue';
import AddNewTagModal from './AddNewTagModal.vue';
import EditTagsModal from './EditTagsModal.vue';
import MoveCommercialEntitiesModal from './MoveCommercialEntitiesModal.vue';
import EditBidderModal from './EditBidderModal.vue';
import ReviewOrderLaborModal from './ReviewOrderLaborModal.vue';
import WorkflowTaskModal from './WorkflowTaskModal.vue';
import MilestoneConvertModal from './MilestoneConvertModal.vue';
import MilestoneCancelModal from './MilestoneCancelModal.vue';
import WorkflowMilestonesModal from './WorkflowMilestonesModal.vue';
import ViewFullNoteModal from './ViewFullNoteModal.vue';
import ViewAllFilesModal from './ViewAllFilesModal.vue';
import WorkflowKanbanModal from './WorkflowKanbanModal.vue';
import SelectEntityModal from './SelectEntityModal.vue';
import SmartDropdownItemsModal from './SmartDropdownItemsModal.vue';
import ImportConflictSettingsModal from './ImportConflictSettingsModal.vue';
import SchedulerLaborModal from './SchedulerLaborModal.vue';
import SchedulerMaterialModal from './SchedulerMaterialModal.vue';
import ConfirmActionsModal from './ConfirmActionsModal.vue';
import PreScheduledCrewEditModal from './PreScheduledCrewEditModal.vue';
import MilestoneReDispatch from './MilestoneReDispatch.vue';
import AddContactModal from './AddContactModal.vue';
import EditOpportunityModal from './EditOpportunityModal.vue';
import EditWarrantyModal from './EditWarrantyModal.vue';
import ChooseStepWarrantyModal from './ChooseStepWarrantyModal';
import EditWarrantyJobModal from './EditWarrantyJobModal';
import CustomFieldsDbTableModal from './CustomFieldsDbTableModal';
import ContactsModal from './ContactsModal';
import SendReviewEmailModal from './SendReviewEmailModal';

export {
    PreScheduledCrewEditModal,
    SetQualityControlModal,
    DocumentsMoveToModal,
    CreateNewFolderModal,
    CreateNewDefaultFolderModal,
    TemplateEditLibraryItemModal,
    AddEstimateSectionModal,
    AddDiscountModal,
    MapModal,
    EditResidentialJobModal,
    CustomItemCreateEdit,
    UserListModal,
    SaveEstimatePdfModal,
    EstimateEditItemModal,
    CreateNewTemplateModal,
    CopyTemplateModal,
    CustomFieldsMultiselectModal,
    WorkItemsPropertiesModal,
    ContactTypesProperties,
    CustomFieldsSingleLineModal,
    DocumentsUploadFileModal,
    AddNoteModal,
    SignModal,
    EditJobCustomFieldsModal,
    EditCompanyInfoModal,
    AddLibraryItemsModal,
    EditJobTaskModal,
    FormBuilderSendReportModal,
    FormEmailTemplateModal,
    CustomFieldsCreateLayoutModal,
    RepresentativesModal,
    CustomFieldsEditLayoutsModal,
    CustomFieldsEditFormBuilderModal,
    CreateNewTaxModal,
    EstimateTaxModal,
    CustomerPortalSettingsModal,
    EditCommercialCustomerSectionModal,
    EditCommercialPropertySectionModal,
    EditCommercialUnitModal,
    CopyEstimateModal,
    CustomFieldsAddressModal,
    CustomFieldsMultilineModal,
    EditCommercialLeadModal,
    EditCustomerInCommercial,
    EditPropertyInCommercial,
    ConfigureJobNumberingModal,
    ConfigureIdNumberingModal,
    CreateNewEventModal,
    EditCommercialJobModal,
    ProjectManagementArchiveCardModal,
    ProjectManagementDeleteCardModal,
    ProjectManagementArchiveColumnModal,
    ProjectManagementDeleteColumnModal,
    OrderAddFromTemplateModal,
    OrderAddFromCompanyLibraryModal,
    OrderAddCustomItemModal,
    OrderAddFromEstimateModal,
    OrderEditOverallProgressModal,
    OrderViewLogModal,
    MilestonesSettingsModal,
    ViewLogModal,
    AssignSalesmanModal,
    MilestoneSetModal,
    SendNewEmailModal,
    FilePreviewModal,
    PriceListModal,
    AddVendorModal,
    AddNewTagModal,
    EditTagsModal,
    MoveCommercialEntitiesModal,
    EditBidderModal,
    ReviewOrderLaborModal,
    WorkflowTaskModal,
    MilestoneConvertModal,
    MilestoneCancelModal,
    WorkflowMilestonesModal,
    ViewFullNoteModal,
    ViewAllFilesModal,
    WorkflowKanbanModal,
    SelectEntityModal,
    SmartDropdownItemsModal,
    ImportConflictSettingsModal,
    SchedulerLaborModal,
    SchedulerMaterialModal,
    ConfirmActionsModal,
    MilestoneReDispatch,
    AddContactModal,
    EditOpportunityModal,
    EditWarrantyModal,
    ChooseStepWarrantyModal,
    EditWarrantyJobModal,
    CustomFieldsDbTableModal,
    ContactsModal,
    SendReviewEmailModal
}
