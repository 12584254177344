import { GET_OPPORTUNITY, SET_OPPORTUNITY } from '../actions/opportunity';
import { showFullAddress, getDataFromQuery } from '@/helpers.js';
import api from '@/headers.js';

export default {
    namespaced: true,
    state: {
        opportunityForEvents: {}
    },
    getters: {
        getOpportunityForEvents: (state) => state.opportunityForEvents
    },
    mutations: {
        [SET_OPPORTUNITY](state, payload) {
            state.opportunityForEvents = {};

            const data = getDataFromQuery(payload);

            state.opportunityForEvents = {
                zip: data.address.zip || '',
                lat: data.address.lat || '',
                lng: data.address.lng || '',
                full_address: showFullAddress(data.address),
                fullname: `${payload.first_name} ${payload.last_name}`,
                poc: payload.created_by ? payload.created_by.fullname : '',
                phone: data.phone.number || '',
                email: data.email.email || '',
            }
        }
    },
    actions: {
        async [GET_OPPORTUNITY]({ commit }, id) {
            const opportunityData = await api.get(`/opportunities/${id}`);
            commit(SET_OPPORTUNITY, opportunityData.data.data);
            return opportunityData;
        }
    }
}