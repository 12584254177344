<template>
    <multiselect
        v-if="multiselectInput"
        :options="multiselectOptions"
        track-by="id" 
        :label="multiselectLabel"
        :showLabels="false"
        :max-height="160"
        :multiple="true"
        :searchable="true"
        :placeholder="column.title"
        :name="`report_filter_value_${index}`"
        :data-name="`report_filter_value_${index}`"
        :class="[{ 'multiselect-danger' : errors.has(`report_filter_value_${index}`) }]"
        v-validate="'required'"
        :value="value"
        :max="selectedCurrentUserOption ? 1 : multiselectOptions.length"
        @input="selectHandler"
    >
        <template
            v-if="isReasonForCancellation"
            slot="option"
            slot-scope="value"
        >
            <div class="s-align-4">
                <span class="f-13-black ellipsis">
                    {{ value.option.title }}
                </span>
                <span
                    :class="[
                        'badge s-align-5 uppercase f-bold job ml-4',
                        value.option.type == 'job' ? 'job' : 'lead'
                    ]"
                >
                    {{ value.option.type }}
                </span>
            </div>
        </template>
        <template
            v-if="needCurentUserOption"
            slot="option"
            slot-scope="value"
        >
            <div
                v-if="value.option.id === -1"
                class="s-align-4"
            >
                Current User
            </div>
        </template>
        <template
            v-if="selectedCurrentUserOption"
            slot="maxElements"
        >
            <div>
                Not allowed added another user, if Current User selected.
            </div>
        </template>
    </multiselect>
    <form-datepicker
        v-else-if="column.type === 'datetime'"
        :value="value"
        @input="datePickerHandler"
        :isFullWidth="true"
        :placeholder="column.title"
        :name="`report_filter_value_${index}`"
        :keyDatePicker="`report_filter_value_${index}-date-picker`"
        v-validate="!column.excerpt === REPORT_COLUMNS.BID_DUE_DATE.excerpt && 'required'"
        :class="[{ 'form-datepicker-error' : errors.has(`report_filter_value_${index}`) }]"
    />
    <div
        v-else-if="column.type === TOGGLE"
        class="row-start align-center ml-8"
    >
      <div class="mr-6">No</div>
      <toggle-switch>
        <input
            v-model="toggle"
            type="checkbox"
            name="apply_to_materials"
            @change="changeToggle"
        />
      </toggle-switch>
      <div class="ml-6">Yes</div>
    </div>
    <form-input 
        v-else
        :label="column.title"
        :class="[{ 'form-input-danger' : errors.has(`report_filter_value_${index}`) }]"
    >
        <input 
            :type="column.type === 'numeric' ? 'number' : 'text'" 
            :name="`report_filter_value_${index}`" 
            class="form-input-field"
            :placeholder="column.title"
            v-input-style="value"
            :value="value"
            @input="$emit('input', $event.target.value)"
            v-validate="'required|max:250'"
        />
    </form-input>
</template>

<script>
    import { FormDateTimeInput, FormInput, FormDatepicker, ToggleSwitch } from '@/ui';
    import Multiselect from 'vue-multiselect';
    import { REPORT_COLUMNS, CURRENT_USER } from '@/constants';
    import cloneDeep from 'lodash/cloneDeep';

    const TOGGLE = 'toggle';
    const TOGGLE_ON = 'Yes';
    const TOGGLE_OFF = 'No';

    export default {
        name: 'ReportsFilterInput',
        components: {
            ToggleSwitch,
            FormDateTimeInput,
            FormInput,
            FormDatepicker,
            Multiselect
        },
        props: {
            value: {
                required: true
            },
            column: {
                type: Object,
                required: true
            },
            index: {
                type: Number,
                required: true
            },
            leadHandbooks: {
                type: Object,
                required: true
            },
            milestones: {
                type: Array,
                required: true
            },
            users: {
                type: Array,
                required: true
            },
            milestonesCancellationReasons: {
                type: Array,
                required: true
            },
            customers: {
                type: Array,
                required: true
            },
            milestoneSetList: {
                type: Array,
                required: true
            },
            properties: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                REPORT_COLUMNS: REPORT_COLUMNS,
                usersWithoutCurrentUser: [],
                toggle: false,
                TOGGLE
            }
        },
        inject: ['$validator'],
        methods: {
            changeToggle() {
              return this.$emit('input', this.toggle ? TOGGLE_ON : TOGGLE_OFF);
            },
            selectHandler(selectedOption) {
                this.$emit('input', selectedOption);

                this.$nextTick(() => {
                    if (this.selectedCurrentUserOption) {
                        this.$emit('setCurrentUserOnly');
                    }
                });
            },
            datePickerHandler(selectedDate) {
                this.$emit('input', selectedDate)
            },
            uniqueObjectForOptions(array) {
                return array && array.filter((obg, index, self) =>
                    index === self.findIndex((t) => (
                        t.title === obg.title
                    ))
                );
            }
        },
        computed: {
            multiselectInput() {
                return this.column.excerpt === REPORT_COLUMNS.CREATED_BY.excerpt
                    || this.column.excerpt === REPORT_COLUMNS.SALESMAN.excerpt
                    || this.column.excerpt === REPORT_COLUMNS.MILESTONE.excerpt
                    || this.column.excerpt === REPORT_COLUMNS.MILESTONE_SET.excerpt
                    || this.column.excerpt === REPORT_COLUMNS.PRIORITY.excerpt
                    || this.column.excerpt === REPORT_COLUMNS.LEAD_TYPE.excerpt
                    || this.column.excerpt === REPORT_COLUMNS.HOME_TYPE.excerpt
                    || this.column.excerpt === REPORT_COLUMNS.SOURCE.excerpt
                    || this.column.excerpt === REPORT_COLUMNS.TRADES.excerpt
                    || this.column.excerpt === REPORT_COLUMNS.ACTIVE_LABELS.excerpt
                    || this.column.excerpt === REPORT_COLUMNS.REASONS_FOR_CANCELLATION.excerpt
                    || this.column.excerpt === REPORT_COLUMNS.QUALITY_CONTROL.excerpt
                    || this.column.excerpt === REPORT_COLUMNS.RESPONSIBLE_BILLING_PARTY.excerpt
                    || this.column.excerpt === REPORT_COLUMNS.PROPERTY.excerpt;
            },
            isReasonForCancellation() {
              return this.column.excerpt === REPORT_COLUMNS.REASONS_FOR_CANCELLATION.excerpt;
            },
            multiselectOptions() {
                switch(this.column.excerpt) {
                    case REPORT_COLUMNS.QUALITY_CONTROL.excerpt: return this.users
                    case REPORT_COLUMNS.CREATED_BY.excerpt: return this.usersWithoutCurrentUser
                    case REPORT_COLUMNS.SALESMAN.excerpt: return this.leadHandbooks.sellers
                    case REPORT_COLUMNS.MILESTONE.excerpt: return this.milestones
                    case REPORT_COLUMNS.MILESTONE_SET.excerpt: return this.milestoneSetList
                    case REPORT_COLUMNS.PRIORITY.excerpt: return this.leadHandbooks.priorities
                    case REPORT_COLUMNS.LEAD_TYPE.excerpt: return this.leadHandbooks.lead_types
                    case REPORT_COLUMNS.HOME_TYPE.excerpt: return this.leadHandbooks.home_types
                    case REPORT_COLUMNS.SOURCE.excerpt: return this.leadHandbooks.lead_sources
                    case REPORT_COLUMNS.TRADES.excerpt: return this.leadHandbooks.trades
                    case REPORT_COLUMNS.ACTIVE_LABELS.excerpt: return this.leadHandbooks.tags.filter(tag => tag.is_active)
                    case REPORT_COLUMNS.REASONS_FOR_CANCELLATION.excerpt: {
                        return this.uniqueObjectForOptions(this.milestonesCancellationReasons);
                    }
                    case REPORT_COLUMNS.RESPONSIBLE_BILLING_PARTY.excerpt: return this.customers
                    case REPORT_COLUMNS.PROPERTY.excerpt: return this.properties
                }
            },
            multiselectLabel() {
                switch(this.column.excerpt) {
                    case REPORT_COLUMNS.QUALITY_CONTROL.excerpt:
                    case REPORT_COLUMNS.CREATED_BY.excerpt:
                    case REPORT_COLUMNS.SALESMAN.excerpt: {
                        if (this.selectedCurrentUserOption) {
                            return 'label';
                        }

                        return 'fullname';
                    }
                    case REPORT_COLUMNS.MILESTONE.excerpt:
                    case REPORT_COLUMNS.REASONS_FOR_CANCELLATION.excerpt: { return 'title' }
                    case REPORT_COLUMNS.MILESTONE_SET.excerpt: { return 'name' }
                    case REPORT_COLUMNS.PROPERTY.excerpt: { return 'property_name' }
                    default: return 'name'
                }
            },
            needCurentUserOption() {
                switch(this.column.excerpt) {
                    case REPORT_COLUMNS.QUALITY_CONTROL.excerpt:
                    case REPORT_COLUMNS.SALESMAN.excerpt: { 
                        return CURRENT_USER;
                    }
                }
                return false;
            },
            selectedCurrentUserOption() {
                if (this.value && this.needCurentUserOption) {
                    return this.value.find(el => el.id === -1);
                }

                return false;
            }
        },
        mounted() {
            if (this.column.type === TOGGLE) {
                this.toggle = this.value === TOGGLE_ON ? true : false;
                return this.changeToggle();
            }

            if (!this.needCurentUserOption && this.users && this.users.length) {
                this.usersWithoutCurrentUser = cloneDeep(this.users);
                this.usersWithoutCurrentUser.shift();
            }
        }
    }
</script>
