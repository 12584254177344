<template>
    <full-modal
        @close="$emit('close')"
        class="commercial-edit-modal"
    >
        <div slot="title">Job settings</div>
        <div slot="body">
            <div class="w-100 pb-30">
                <p class="f-14-asphalt f-sbold pb-4">Main information</p>
                <div class="row-between">
                    <div class="lg-12">
                        <form-input
                            label="Job #"
                            :class="[{ 'form-input-danger' : errors.has('job_number') }]"
                        >
                            <input
                                type="text"
                                name="job_number"
                                class="form-input-field"
                                placeholder="Job #"
                                v-input-style="job.job_number"
                                v-model="job.job_number"
                                v-validate="{ required: true, regex: titleRegex }"
                            />
                        </form-input>
                    </div>
                </div>
            </div>
            <div class="w-100 pb-30">
                <p class="f-14-asphalt f-sbold pb-4">Address</p>
                <address-box
                    type="address"
                    :key="'address'"
                    :existing="jobAddress"
                    :apartment="true"
                    @address="getJobAddress"
                >
                    <div
                        class="w-100 mb-4"
                        slot="apartment"
                    >
                        <form-input
                            label="Unit"
                            :class="[{ 'form-input-danger' : errors.has('job_apartment') }]"
                        >
                            <input
                                type="text"
                                name="job_apartment"
                                id="job_apartment"
                                class="form-input-field"
                                placeholder="Unit"
                                v-input-style="job.apartment"
                                v-model="job.apartment"
                                v-validate="{ max: 50, regex: titleRegex }"
                            />
                        </form-input>
                    </div>
                </address-box>
            </div>
            <loader
                v-if="loading"
                size="small"
            />
            <div v-if="!loading && job.lead">
                <p
                    class="f-14-asphalt f-sbold pb-4"
                    v-if="!loading && job.lead.requested_by"
                >
                    Requested by
                </p>
                <div
                    v-if="!loading && job.lead.requested_by"
                    class="pb-30 row-between"
                >
                    <div class="lg-6 sm-12 pb-4 pr-2 pr-sm-0">
                        <form-input
                            label="First name"
                            :class="[{ 'form-input-danger' : errors.has('first_name') }]"
                        >
                            <input
                                type="text"
                                name="first_name"
                                id="first_name"
                                class="form-input-field"
                                placeholder="First name"
                                v-input-style="edited.requested_by.first_name"
                                v-model="edited.requested_by.first_name"
                                maxlength="31"
                                v-validate="{ required: true, max: 30, regex: titleRegex }"
                            />
                        </form-input>
                    </div>
                    <div class="lg-6 sm-12 pb-4 pl-2 pl-sm-0">
                        <form-input
                            label="First name"
                            :class="[{ 'form-input-danger' : errors.has('last_name') }]"
                        >
                            <input
                                type="text"
                                name="last_name"
                                id="last_name"
                                class="form-input-field"
                                placeholder="First name"
                                v-input-style="edited.requested_by.last_name"
                                v-model="edited.requested_by.last_name"
                                maxlength="31"
                                v-validate="{ required: true, max: 30, regex: titleRegex }"
                            />
                        </form-input>
                    </div>
                    <div class="w-100">
                        <form-input
                            label="Title"
                            :class="[{ 'form-input-danger' : errors.has('title') }]"
                        >
                            <input
                                type="text"
                                name="title"
                                id="title"
                                class="form-input-field"
                                placeholder="Title"
                                v-input-style="edited.requested_by.title"
                                v-model="edited.requested_by.title"
                                maxlength="31"
                                v-validate="{ required: true, max: 30, regex: titleRegex }"
                            />
                        </form-input>
                    </div>
                </div>
                <p class="f-14-asphalt f-sbold pb-4">Contacts</p>
                <div
                    class="w-100 pb-30"
                    v-if="!loading && phones || emails"
                >
                    <div class="w-100 column-start">
                        <p class="f-13-asphalt pb-4">Phones:</p>
                        <div
                            class="s-align-4 w-100 pb-4"
                            v-for="(phone, index) in phones"
                            :key="`phone-number-${index}`"
                            :id="`phone-number-${index}`"
                        >
                            <div class="static-col-8">
                                <form-input
                                    label="Phone"
                                    class="form-input-with-select"
                                    :class="[{ 'form-input-danger' : errors.has(`phone-number-${index}`) }]"
                                >
                                    <input
                                        type="tel"
                                        :name="`phone-number-${index}`"
                                        class="form-input-field"
                                        placeholder="Phone"
                                        v-input-style="phone.number"
                                        v-model="phone.number"
                                        v-mask="'(###) ###-####'"
                                        v-validate="'required|min:14'"
                                    />
                                    <multiselect
                                        :options="phoneTypes"
                                        :showLabels="false"
                                        :max-height="200"
                                        placeholder="Types"
                                        v-model="phone.type"
                                        :name="'phone_type' + index"
                                        :data-name="'phone_type' + index"
                                        v-validate="'required'"
                                        :class="['capitalize', { 'multiselect-danger' : errors.has('phone_type' + index) }]"
                                        :searchable="false"
                                    />
                                </form-input>
                            </div>
                            <button
                                class="service-add-button ml-2"
                                v-if="index > 0"
                                @click="removeContactsFromRequestedBy(index, phones)"
                                tabindex="-1"
                            >
                                <trash-icon/>
                            </button>
                            <button
                                class="service-secondary-button ml-2"
                                v-if="phonesLength == index + 1 && phonesLength < 3"
                                @click="addPhoneNumber"
                                tabindex="-1"
                            >
                                <add-icon size="10"/>
                            </button>
                        </div>
                    </div>
                    <div class="w-100 column-start pt-2">
                        <p class="f-13-asphalt pb-4 mt-sm-1">Emails:</p>
                        <div
                            class="s-align-4 w-100 pb-4"
                            v-for="(email, index) in emails"
                            :key="email.id"
                            :id="`email-${index}`"
                        >
                            <div class="static-col-8">
                                <form-input
                                    label="Email"
                                    :class="[{ 'form-input-danger' : errors.has(`email-${index}`) }]"
                                >
                                    <input
                                        type="email"
                                        :name="`email-${index}`"
                                        class="form-input-field"
                                        placeholder="Email"
                                        v-input-style="email.email"
                                        v-model="email.email"
                                        v-validate="'required|email'"
                                    />
                                </form-input>
                            </div>
                            <button
                                class="service-add-button ml-2"
                                v-if="index > 0"
                                @click="removeContactsFromRequestedBy(index, emails)"
                                tabindex="-1"
                            >
                                <trash-icon/>
                            </button>
                            <button
                                class="service-secondary-button ml-2"
                                v-if="emailsLength == index + 1 && emailsLength < 3"
                                @click="addEmail"
                                tabindex="-1"
                            >
                                <add-icon size="10"/>
                            </button>
                        </div>
                    </div>
                </div>
                <p class="f-14-asphalt f-sbold pb-4">Work Items</p>
                <div class="w-100 pb-30 row-between">
                    <div class="column-start lg-6 sm-12 pb-4 pr-2 pr-sm-0">
                        <p class="f-13-black pb-2">Lead type:</p>
                        <multiselect
                            :options="leadTypes"
                            track-by="id"
                            label="name"
                            :showLabels="false"
                            :max-height="200"
                            placeholder="Lead type"
                            name="lead_type_id"
                            data-name="lead_type_id"
                            v-model="edited.leadType"
                            v-validate="'required'"
                            :class="[{ 'multiselect-danger' : errors.has('lead_type_id') }]"
                            :disabled="true"
                        />
                    </div>
                    <div class="column-start lg-6 sm-12 pb-4 pl-2 pl-sm-0">
                        <p class="f-13-black pb-2">Material:</p>
                        <multiselect
                            :options="materials"
                            track-by="id"
                            label="name"
                            :showLabels="false"
                            :max-height="200"
                            placeholder="Material"
                            name="material_id"
                            data-name="material_id"
                            v-model="edited.materials"
                            v-validate="'required'"
                            :class="[{ 'multiselect-danger' : errors.has('material_id') }]"
                        />
                    </div>
                    <div class="column-start lg-6 sm-12 pb-4 pr-2 pr-sm-0">
                        <p class="f-13-black pb-2">Home type:</p>
                        <multiselect
                            :options="homeTypes"
                            track-by="id"
                            label="name"
                            :showLabels="false"
                            :max-height="200"
                            placeholder="Home type"
                            name="home_type_id"
                            data-name="home_type_id"
                            v-model="edited.homeTypes"
                            v-validate="'required'"
                            :class="[{ 'multiselect-danger' : errors.has('home_type_id') }]"
                        />
                    </div>
                    <div class="column-start lg-6 sm-12 pb-4 pl-2 pl-sm-0">
                        <p class="f-13-black pb-2">Priorities:</p>
                        <multiselect
                            :options="priorities"
                            track-by="id"
                            label="name"
                            :showLabels="false"
                            :max-height="200"
                            placeholder="Priorities"
                            name="priority"
                            data-name="priority"
                            v-model="edited.priority"
                            v-validate="'required'"
                            :class="[{ 'multiselect-danger' : errors.has('priority') }]"
                        />
                    </div>
                    <div class="column-start lg-6 sm-12 pb-4 pr-2 pr-sm-0">
                        <p class="f-13-black pb-2">Lead source:</p>
                        <multiselect
                            :options="leadSources"
                            track-by="id"
                            label="name"
                            :showLabels="false"
                            :max-height="200"
                            placeholder="Lead source"
                            name="lead_source_id"
                            data-name="lead_source_id"
                            v-model="edited.source"
                            v-validate="'required'"
                            :class="[{ 'multiselect-danger' : errors.has('lead_source_id') }]"
                        />
                        <form-input
                            v-if="!selectedPartner && isReferralChild && isReferralChild.has_children"
                            label="Referral Name"
                            :class="[{ 'form-input-danger' : errors.has('referral_name') }, 'mt-4']"
                        >
                            <input
                                type="text"
                                name="referral_name"
                                id="referral_name"
                                class="form-input-field"
                                placeholder="Referral Name"
                                v-model="referralName"
                                maxlength="255"
                                v-validate="{regex: titleRegex }"
                                ref="ReferralName"
                            />
                        </form-input>
                        <multiselect
                            v-if="isReferralChild && isReferralChild.has_children && !referralName"
                            :options="contactPartners"
                            placeholder="Select from Contacts"
                            :show-labels="false"
                            :max-height="200"
                            :searchable="false"
                            :custom-label="partnerCustomLabel"
                            track-by="id"
                            name="job_partner"
                            data-name="job_partner"
                            v-model="selectedPartner"
                            :class="[
                                {'selected-referral' : selectedPartner},
                                { 'multiselect-danger' : errors.has('job_partner') },
                                'mt-5 multiselect-small'
                            ]"
                            v-validate="{required: Boolean(!referralName)}"
                        />
                    </div>
                </div>
            </div>

            <div class="row-between pb-8">
                <p class="f-14-asphalt f-sbold">Trades</p>
                <select-or-deselect
                    :data="selectedTrades"
                    :valuesFromAdd="trades"
                />
            </div>
            <div v-if="tradesLoading">
                <loader size="xs"/>
            </div>
            <div v-else class="w-100 pb-30 row-start">
                <trade
                    v-for="(trade, index) in trades"
                    :key="trade.id"
                    :label="trade.name"
                    :class="[{ 'trade-danger' : errors.has(`trade-${index}`) }]"
                    :id="`trade-${index}`"
                    :icon="trade.icon_code"
                >
                    <input
                        type="checkbox"
                        :name="`trade-${index}`"
                        v-model="selectedTrades"
                        :value="trade.id"
                        v-validate="{ required: false }"
                    />
                    <div slot="isSalesRabbit">
                        <div
                            v-if="trade.is_sales_rabbit"
                            class="row-start s-align-4 ml-4"
                        >
                            <storm-icon
                                width="10"
                                height="12"
                                class="ml-2"
                            />
                            <toggle-switch
                                class="ml-1 pointer"
                                smallSize
                            >
                                <input
                                    :checked="isSalesRabbit(trade.id)"
                                    @change="toggleSalesRabbit(trade.id, index)"
                                    type="checkbox"
                                />
                            </toggle-switch>
                        </div>
                    </div>
                </trade>
            </div>

            <div
                class="w-100 pb-30"
            >
                <p class="f-14-asphalt f-sbold pb-4">Billing emails</p>
                <div class="row-between">
                    <div
                        class="s-align-4 w-100 pb-4"
                        v-for="(email, index) in job.emails"
                    >
                        <form-input
                            :label="email.type === EMAIL_TYPES.MAIN ? 'Email' : 'Billing email'"
                            class="form-input-with-select"
                            :class="[{ 'form-input-danger' : errors.has(`email_${index}`) }]"
                        >
                            <input
                                type="text"
                                :name="`email_${index}`"
                                class="form-input-field"
                                :placeholder="email.type === EMAIL_TYPES.MAIN ? 'Email' : 'Billing email'"
                                v-input-style="email.email"
                                v-model="email.email"
                                v-validate="email.type === EMAIL_TYPES.MAIN ? 'required|email' : 'email'"
                            />
                            <multiselect
                                :options="emailTypes"
                                :showLabels="false"
                                :max-height="200"
                                placeholder="Types"
                                v-model="email.type"
                                :name="`email_type_${index}`"
                                :data-name="`email_type_${index}`"
                                :searchable="false"
                                v-validate="'required'"
                                :class="[
                                    'capitalize',
                                    { 'multiselect-danger' : errors.has(`email_type_${index}`) }
                                ]"
                            />
                        </form-input>
                        <button
                            class="service-add-button ml-2"
                            v-if="index > 0"
                            @click="removeItem(index, job.emails)"
                            tabindex="-1"
                        >
                            <trash-icon/>
                        </button>
                        <button
                            class="service-secondary-button ml-2"
                            v-if="billingEmailsLength == index + 1 && billingEmailsLength < 3"
                            @click="addBillingEmail()"
                            tabindex="-1"
                        >
                            <add-icon size="10"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">
            <button
                class="add-button"
                @click="$emit('close')"
            >
                Cancel
            </button>
            <button
                class="primary-button"
                @click="save"
                :disabled="sending"
            >
                Save
            </button>
        </div>
    </full-modal>
</template>

<script>
    import {
        FullModal, FormInput, FormDatepicker, FormTimeInput,
        RadioSwitch, FormInputTextarea, CheckboxButton, Loader, Trade, ToggleSwitch
    } from '@/ui';
    import SelectOrDeselect from '@/components/SelectOrDeselect.vue';
    import { TrashIcon, AddIcon, EditIcon, ArrowIcon, StormIcon } from '@/icons';
    import { mask } from 'vue-the-mask';
    import {
        copyFieldsValueFromObjectToObject, removePhoneMask,
        validationErrorNotification, clearArray
    } from '@/helpers';
    import { Avatar } from '@/components';
    import cloneDeep from 'lodash/cloneDeep';
    import {
        COMMERCIAL_PHONE_TYPES, BASE_ENTITY_TYPES, NOT_ALLOWED_CHARACTERS, EMAIL_TYPES
    } from '@/constants';
    import AddressBox from '@/components/AddressBox.vue';
    import Multiselect from 'vue-multiselect';
    import api from '@/headers.js';

    const ENTITY_REPRESENTATIVES = 'Modules\Commercial\Entities\CommercialRepresentative';

    export default {
        name: 'EditCommercialJobModal',
        components: {
            FullModal,
            FormInput,
            TrashIcon,
            AddIcon,
            EditIcon,
            ArrowIcon,
            Avatar,
            AddressBox,
            Multiselect,
            FormDatepicker,
            FormTimeInput,
            RadioSwitch,
            FormInputTextarea,
            CheckboxButton,
            Loader,
            SelectOrDeselect,
            Trade,
            StormIcon,
            ToggleSwitch
        },
        props: {
            job: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                EMAIL_TYPES,
                jobAddress: {
                    address: '',
                    street: '',
                    city: '',
                    state: '',
                    zip: '',
                    lat: null,
                    lng: null
                },
                phoneTypes: COMMERCIAL_PHONE_TYPES,
                sending: false,
                edited: {
                    leadType: {},
                    materials: {},
                    homeTypes: {},
                    source: {},
                    priority: {},
                    requested_by: {}
                },
                selectedTrades: [],
                trades: [],
                tradesList: [],
                salesRabbit: [],
                tradesLoading: false,
                phones: [],
                emails: [],
                leadTypes: [],
                materials: [],
                homeTypes: [],
                leadSources: [],
                priorities: [],
                loading: false,
                titleRegex: NOT_ALLOWED_CHARACTERS,
                emailTypes: Object.values(EMAIL_TYPES),
                contactPartners: [],
                referralName: null,
                selectedPartner: null
            }
        },
        computed: {
            phonesLength() {
                return this.phones.length;
            },
            emailsLength() {
                return this.emails.length;
            },
            billingEmailsLength() {
                return this.job.emails.length;
            },
            isReferralChild() {
                if (! this.edited.source) {
                    return false;
                }

                return this.leadSources.find(item => {
                    if (this.edited && this.edited.source.id) {
                        return item.id === this.edited.source.id;
                    }

                    return false;
                });
            }
        },
        methods: {
            setJobInfo() {
                this.salesRabbit = this.job.trades.filter(el => el.is_sales_rabbit).map(el => el.id);
            },
            isSalesRabbit(id) {
                return this.tradesList.find(el => el.id === id && el.is_sales_rabbit);
            },
            toggleSalesRabbit(id) {
                const findElIndex = this.salesRabbit.findIndex(el => el === id);
                if (findElIndex !== -1) {
                    this.salesRabbit.splice(findElIndex, 1);
                    return;
                }

                this.salesRabbit.push(id);
            },
            getJobAddress(data) {
                this.jobAddress = data;
                copyFieldsValueFromObjectToObject(data, this.job);
            },
            async getPartners() {
                await api.get('/partners')
                    .then(response => response.data.data)
                    .then(data => {
                        this.contactPartners = data;
                    });
            },
            save() {
                this.$children.forEach(vm => {
                    vm.$validator.validateAll();
                });

                this.$validator.validateAll().then(result => {
                    if (!result) {
                        const context = this;
                        validationErrorNotification(this.$validator, context);

                        let emailErrors = this.errors.items.filter(e => {
                            return e.rule === 'email';
                        })

                        if (emailErrors.length) {
                            this.notifyError('Value entered is invalid. Check the value and try again.');
                        }

                        return;
                    }

                    let params = this.processingParamsForEdit();
                    this.sending = true;
                    api.put(`/commercial/jobs/${this.job.id}`, params)
                        .then(response => response.data.data)
                        .then(data => {
                            this.$nextTick(() => {
                                clearArray(this.job.trades);

                                this.selectedTrades.map((id) => {
                                    const trade = this.trades.find(t => t.id === id);
                                    if (trade !== undefined) {
                                        this.job.trades.push(trade);
                                    }
                                });

                                this.job.full_address = data.full_address;
                                if (this.job.lead) {
                                    this.job.lead.requested_by.phones = this.phones;
                                    this.job.lead.requested_by.emails = this.emails;
                                }

                                this.$store.dispatch('commercial/GET_COMMERCIAL_JOB_PREVIEW', this.job.id);

                                this.notifySuccess('Job is updated!');
                                this.$emit('close');
                            });
                        })
                        .catch((error) => {
                            this.notifyRequestErrors(error);
                        })
                        .finally(() => {
                            this.sending = false;
                        });
                });
            },
            async getWorkItems() {
                this.tradesLoading = true;

                const params = {
                    needed: [
                        'lead_types',
                        'materials',
                        'home_types',
                        'lead_sources',
                        'priorities',
                        'trades'
                    ],
                    entityType: BASE_ENTITY_TYPES.COMMERCIAL_LEAD
                };

                await api.get('/handbooks?filter[is_active]=1', { params })
                    .then(response => response.data)
                    .then(response => {
                        this.materials = response.data.materials;
                        this.homeTypes = response.data.home_types;
                        this.leadSources = response.data.lead_sources;
                        this.priorities = response.data.priorities;
                        this.trades = response.data.trades;

                        // show lead types with the same group id
                        this.leadTypes = response.data.lead_types.filter(i => i.group_id === this.job.group_id);

                        this.trades.map((trade) => {
                            for (let i = 0; i < this.job.trades.length; i++) {
                                if (this.job.trades[i].id === trade.id) {
                                    this.selectedTrades.push(this.job.trades[i].id);
                                    this.tradesList.push(this.job.trades[i]);
                                }
                            }
                        });

                        this.tradesLoading = false;
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    });
            },
            getLeadParams(typeId, materialId, homeTypeId, sourceId, priorityId) {
                this.edited.leadType = this.leadTypes.find(el => el.id === typeId);
                this.edited.materials = this.materials.find(el => el.id === materialId);
                this.edited.homeTypes = this.homeTypes.find(el => el.id === homeTypeId);
                this.edited.source = this.leadSources.find(el => el.id === sourceId);
                this.edited.priority = this.priorities.find(el => el.id === priorityId);
                this.edited.requested_by = cloneDeep(this.job.lead.requested_by);
                this.phones = cloneDeep(this.job.lead.requested_by.phones);
                this.emails = cloneDeep(this.job.lead.requested_by.emails);
            },
            processingParamsForEdit() {
                let params = {};
                params = cloneDeep(this.job);
                delete params.lead;
                delete params.partner;
                params.lead = {};
                params.lead.material_id = this.edited.materials.id;
                params.lead.home_type_id = this.edited.homeTypes.id;
                params.lead.source_id = this.edited.source.id;
                params.lead.priority_id = this.edited.priority.id;
                params.type_id = this.edited.leadType.id;
                params.type_name = this.edited.leadType.name;
                params.lead.requested_by = this.edited.requested_by;
                params.lead.phones = cloneDeep(this.phones);
                params.trades = this.selectedTrades;
                params.salesRabbit = this.salesRabbit;
                removePhoneMask(params.lead.phone);
                params.lead.emails = this.emails;
                params.emails = params.emails.find(el => el.email) ? params.emails : []
                delete params.lead.requested_by.phones;
                delete params.lead.requested_by.emails;
    
                // Clear Referral if source was changed from referral to not referral
                if (! this.edited.source.has_children) {
                    delete this.job.partner;

                    if (this.selectedPartner) {
                        this.selectedPartner = null;
                        params.partner_id = null;
                    }

                    if (this.referralName) {
                        this.referralName = null;
                        params.referral_name = null;
                    }
                } else if (this.referralName && this.edited.source.has_children) {
                    params.referral_name = this.referralName;
                    params.partner_id = null;
                    // Send if it was stated
                    if (this.selectedPartner) {
                        this.selectedPartner = null;
                    }
                    this.job.partner = {
                        referral_name:  this.referralName
                    };
                } else if (!this.referralName && this.edited.source.has_children && this.selectedPartner) {
                    params.partner_id = this.selectedPartner.id;
                    params.referral_name = null;
                    this.job.partner = this.selectedPartner;
                    delete this.job.partner.referral_name;
                }

                if (this.job.lead) {
                    this.job.lead.material.id = this.edited.materials.id;
                    this.job.lead.material.name = this.edited.materials.name;
                    this.job.lead.home_type.id = this.edited.homeTypes.id;
                    this.job.lead.home_type.name = this.edited.homeTypes.name;
                    this.job.lead.source.id = this.edited.source.id;
                    this.job.lead.source.name = this.edited.source.name;
                    this.job.lead.priority.id = this.edited.priority.id;
                    this.job.lead.priority.name = this.edited.priority.name;
                    this.job.type_id = this.edited.leadType.id;
                    this.job.type_name = this.edited.leadType.name;
                    this.job.lead.requested_by = this.edited.requested_by;
                }
                return params;
            },
            addPhoneNumber() {
                this.phones.push({
                    number: '',
                    type: this.phoneTypes[1],
                    id: this.job.lead.requested_by.id,
                    entity_type: ENTITY_REPRESENTATIVES
                });
            },
            addEmail() {
                this.emails.push({
                    email : '',
                    id: this.job.lead.requested_by.id ? this.job.lead.requested_by.id : null,
                    entity_type: ENTITY_REPRESENTATIVES,
                });
            },
            addBillingEmail() {
                this.job.emails.push({
                    'type': EMAIL_TYPES.BILLING,
                    'email': ''
                });
            },
            removeContactsFromRequestedBy(index, arr) {
                arr.splice(index, 1);
            },
            removeItem(index, arr) {
                arr.splice(index, 1);
            },
            partnerCustomLabel ({ first_name, last_name }) {
                return `${first_name} ${last_name}`
            },
        },
        directives: {
            mask
        },
        async created() {
            this.loading = true;
            copyFieldsValueFromObjectToObject(this.job, this.jobAddress);

            await this.getPartners();
            await this.getWorkItems();
            this.setJobInfo();

            if (this.job.emails.length < 1) {
                this.addBillingEmail();
            }

            if (this.job.lead) {
                await this.getLeadParams(
                    this.job.type_id,
                    this.job.lead.material.id,
                    this.job.lead.home_type.id,
                    this.job.lead.source.id,
                    this.job.lead.priority.id
                );
            }

            if (this.job.partner && this.job.partner.referral_name) {
                this.referralName = this.job.partner.referral_name || null;
                this.selectedPartner = null;
            }  else if (this.job.partner) {
                this.selectedPartner = this.job.partner;
                this.referralName = null;
            }

            this.loading = false;
        }
    }
</script>
