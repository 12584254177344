<template>
    <div class="modal">
        <div 
            :class="[
                'h-100',
                collapsedState || resizeMode ? 's-align-3' : 's-align-5'
            ]" 
            v-body-scroll-lock:reserveScrollBarGap="!collapsedState"
        >
            <div
                :style="heightContent"
                class="modal-content-box column-start relative"
                :class="{'warranty-modal' : isWarranty}"
            >
                <div class="modal-header row-between bg-white z-index-2">
                    <h1>
                        <slot name="modal-header"></slot>
                    </h1>
                    <div
                        v-if="showCollapseButton"
                        :class="[
                            'row-end',
                            collapsedState ? 'w-30' : 'w-50'
                        ]"
                    >
                        <button
                            class="transparent-button p-1 mr-2"
                            @click="collapse"
                        >
                            <arrow-icon
                                size="16"
                                class="i-grey"
                                :transform="collapsedState ? '270' : '90'"
                            />
                        </button>
                        <button
                            class="transparent-button p-1 mr-2"
                            @click="resize"
                        >
                            <decrease-icon
                                v-if="!resizeMode"
                                size="16"
                                class="i-grey"
                            />
                            <resize-icon
                                v-else
                                size="16"
                                class="i-grey"
                            />
                        </button>
                        <button
                            class="transparent-button p-1"
                            @click="close"
                        >
                            <close-icon class="i-grey"/>
                        </button>
                    </div>
                    <button
                        v-else-if="isCloseBtn"
                        class="transparent-button p-1"
                        @click="close"
                    >
                        <close-icon class="i-grey"/>
                    </button>
                </div>
                <div
                    :class="[
                            'modal-body column-start',
                             !isFooter && 'mb-20 w-100 ps'
                         ]">
                    <slot name="modal-body"></slot>
                </div>
                <div
                    v-if="isFooter"
                    class="modal-footer"
                >
                    <slot name="modal-footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { CloseIcon, DecreaseIcon, ResizeIcon, ArrowIcon } from '@/icons';

    export default {
        name: 'Modal',
        components: {
            CloseIcon,
            DecreaseIcon,
            ResizeIcon,
            ArrowIcon
        },
        props: {
            showCollapseButton: {
                type: Boolean,
                default: false
            },
            resizeMode: {
                type: Boolean,
                default: false
            },
            collapsedState: {
                type: Boolean,
                default: false
            },
            isFooter: {
                type: Boolean,
                default: true
            },
            heightContent: {
                type: String,
                default: null
            },
            isWarranty: {
                type: Boolean,
                default: false
            },
            isHeaderTextCenter: {
                type: Boolean,
                default: false
            },
            isCloseBtn: {
                type: Boolean,
                default: true
            }
        },
        methods: {
            close() {
                this.$emit('close');
            },
            resize() {
                this.$emit('resize');
            },
            collapse() {
                this.$emit('collapse');
            }
        }
    }
</script>

