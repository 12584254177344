<template>
    <full-modal 
        class="milestone-set-modal"
        :full="true"
        @close="$emit('close')"
    >
        <div slot="title">Milestone Set</div>
        <div slot="body">
            <div 
                :class="['blue-box pv-3 ph-1 row-between mb-2', { 'grabbable': !confirmDeleteSetIndex }]"
                v-for="(item, index) in items"
                :key="`item-${index}`"
                :draggable="!confirmDeleteSetIndex"
                @dragstart="setDragStart(index)"
                @dragenter="setDragEnter(index)" 
                @dragend="setDragEnd"
            >
                <div class="lg-1 s-align-5">
                    <drag-icon class="i-grey"/>
                </div>
                <div class="lg-10">
                    <form-input :class="[{ 'form-input-danger' : errors.has(`set-name-${index}`) }]">
                        <input 
                            type="text" 
                            :name="`set-name-${index}`" 
                            :id="`set-name-${index}`"
                            class="form-input-field"
                            placeholder="Set name"
                            v-model="item.name"
                            v-validate="'required|min:3|max:15'"
                            maxlength="16"
                            @input="validateSetName(index)"
                        />
                    </form-input>
                </div>
                <div class="lg-1 s-align-5 relative">
                    <button 
                        class="icon-light"
                        @click="showRemoveTooltip(index, item)"
                    >
                        <trash-icon/>
                    </button>
                    <div
                        class="tooltip"
                        v-if="confirmDeleteSetIndex == index"
                    >
                        <p class="f-14-black f-bold t-center mb-6">
                            Are you sure to want to delete this Milestone Set?
                        </p>
                        <div class="row-between">
                            <button
                                class="add-button"
                                @click="confirmDeleteSetIndex = null"
                            >
                                No
                            </button>
                            <button
                                class="red-button"
                                @click="removeSet(item.id)"
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <button
                class="colored-large-button"
                @click="addNewSet"
                v-if="items.length < 5"
            >
                <span class="new-set-icon s-align-5 mr-1 f-16-blue f-sbold">+</span>
                New Set
            </button>
            <loader
                v-if="loading"
                size="full"
            />
        </div>
    </full-modal>
</template>

<script>
    import { FullModal, FormInput, Loader } from '@/ui';
    import { DragIcon, TrashIcon } from '@/icons';
    import debounce from 'lodash/debounce';
    import cloneDeep from 'lodash/cloneDeep';
    import api from '@/headers.js';

    export default {
        name: 'MilestoneSetModal',
        components: {
            FullModal,
            FormInput,
            DragIcon,
            TrashIcon,
            Loader
        },
        props: {
            setList: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                items: [],
                dragIndex: null,
                initialDragIndex: null,
                dragItem: null,
                confirmDeleteSetIndex: null,
                loading: false,
                savedItem: null
            }
        },
        computed: {
            lastOrder() {
                return Math.max.apply(null, this.items.map(item => item.order));
            }
        },
        watch: {
            setList: {
                handler (val, oldVal) {
                    if (val != oldVal)
                        this.setItems();
                },
                deep: true
            }
        },
        methods: {
            setItems() {
                this.items = cloneDeep(this.setList);
            },
            setDragStart(index) {
                this.dragIndex = index;
                this.initialDragIndex = index;
                this.dragItem = this.items[index];
            },
            setDragEnter(index) {
                if (this.dragIndex == index) return false;

                this.items.splice(this.dragIndex, 1);
                this.items.splice(index, 0, this.dragItem);

                for (let i = 0; i < this.items.length; i++) {
                    this.items[i].order = i;
                }
                this.dragIndex = index;
            },
            setDragEnd() {
                const doItemExist = this.dragItem.id && this.dragItem.name;
                const didItemChange = this.initialDragIndex !== this.dragIndex;

                if (doItemExist && didItemChange) {
                    this.updateSet(this.dragIndex);
                }

                this.dragIndex = null;
                this.initialDragIndex = null;
                this.dragItem = null;
            },
            addNewSet() {
                this.items.push({ name: '', isNew: true, order: null });
            },
            validateSetName: debounce(function (index) {
                this.$validator.validate(`set-name-${index}`)
                    .then(result => {
                        if (!result) return;

                        this.items[index].isNew
                            ? this.createNewSet(index)
                            : this.updateSet(index);
                    });
            }, 1000),
            createNewSet(index) {
                this.loading = true;

                const params = {
                    name: this.items[index].name,
                    order: this.lastOrder + 1
                };

                api.post(`/milestone-sets`, params)
                    .then(data => {
                        this.notifySuccess('Set is created!');
                        this.$emit('update');
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            updateSet(index) {
                this.loading = true;
                const editable = this.items[index];

                const params = {
                    name: editable.name,
                    order: editable.order
                };

                api.put(`/milestone-sets/${editable.id}`, params)
                    .then(data => {
                        this.notifySuccess('Set is updated!');
                        this.$emit('update');
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            showRemoveTooltip(index, item) {
                if (!item.id) {
                    const index = this.items.findIndex((item, currentIndex) => currentIndex === index);
                    this.items.splice(index, 1);
                    return;
                }

                this.confirmDeleteSetIndex = this.confirmDeleteSetIndex == index ? null : index;
            },
            removeSet(id) {
                this.loading = true;
                this.confirmDeleteSetIndex = null;

                api.delete(`/milestone-sets/${id}`)
                    .then(() => {
                        this.notifySuccess('Set is deleted!');
                        this.$emit('update');

                        let index = this.items.findIndex(s => s.id === id);
                        this.items.splice(index, 1);
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        created() {
            this.setItems();
        }
    }
</script>