<template>
    <notifications
        group="main"
        :duration="duration"
        position="top right"
        width="330"
        v-scroll="scrollListener"
        :style="margin"
    >
        <template slot="body" slot-scope="props">
            <div :class="['custom-notification mb-1', 'notification-' + props.item.type]">
                <div 
                    v-if="mScreen"
                    class="row-between row-baseline-start"
                >
                    <div class="icon-wrapper s-align-5 mr-4">
                        <mark-icon 
                            v-if="props.item.type === 'success'"
                            size="8"
                        />
                        <stop-icon 
                            v-else-if="props.item.type === 'error'"
                            size="15"
                            class="i-red"
                        />
                        <warning-icon 
                            v-else-if="props.item.type === 'warn'"
                            size="10"
                        />
                    </div>
                    <span 
                        class="custom-notification-description f-13-darkgrey"
                    >
                        {{ props.item.text }}
                    </span>
                    <button 
                        class="transparent-button p-1" 
                        @click="props.close"
                    >
                        <close-icon class="i-grey"/>
                    </button>
                </div>
                <div 
                    v-else
                    class="column-start w-100"
                >
                    <div class="row-between row-baseline mb-2">
                        <span class="f-13-darkgrey break-all w-90">{{ props.item.text }}</span>
                        <button 
                            class="transparent-button p-1" 
                            @click="props.close"
                        >
                            <close-icon class="i-grey"/>
                        </button>
                    </div>
                    <div class="row-start row-baseline">
                        <div class="icon-wrapper s-align-5 mr-4">
                            <mark-icon 
                                v-if="props.item.type === 'success'"
                                size="8"
                            />
                            <stop-icon 
                                v-else-if="props.item.type === 'error'"
                                size="15"
                                class="i-red"
                            />
                            <warning-icon 
                                v-else-if="props.item.type === 'warn'"
                                size="10"
                            />
                        </div>
                        <span class="f-13-darkgrey">{{ props.item.title }}</span> 
                    </div>
                </div>
            </div>
        </template>
    </notifications>
</template>

<script>
    import { CloseIcon, MarkIcon, StopIcon, WarningIcon } from '@/icons';

    export default {
        name: 'CustomNotification',
        components: {
            CloseIcon,
            MarkIcon,
            StopIcon,
            WarningIcon
        },
        data() {
            return {
                isScrolled: false
            }
        },
        computed: {
            margin() {
                if (this.dScreen) {
                    return this.$store.getters['views/getFullModalCondition']
                        ? { marginRight: '550px', marginTop: '20px' }
                        : { marginRight: '7px', marginTop : this.isScrolled ? '70px' : '160px' };
                } else if (this.nScreen) {
                    return this.$store.getters['views/getFullModalCondition']
                        ? { marginRight: '550px', marginTop: '95px' }
                        : { marginRight: '7px', marginTop: '160px' };
                } else {
                    return { marginRight: '7px', marginTop: '75px' };
                }
            },
            duration() {
                return this.mScreen ? 1500 : 5000;
            }
        },
        methods: {
            scrollListener(event, el) {
                this.checkScroll();
                if (this.dScreen && window.scrollY <= 50) {
                    el.style.marginTop = '160px';
                } else if (this.dScreen) {
                    el.style.marginTop = '70px';
                } else if (window.scrollY > 50) {
                    el.style.marginTop = '75px'
                } 
            },
            checkScroll() {
                if (window.scrollY > 50) {
                    this.isScrolled = true;
                } else {
                    this.isScrolled = false;
                }
            }
        },
        mounted() {
            this.checkScroll();
        }
    }
</script>

