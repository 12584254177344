export const getCoords = (element) => {    
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.documentElement.scrollTop;
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft || document.documentElement.scrollLeft;
  
    let clientTop = document.documentElement.clientTop || document.documentElement.clientTop || 0;
    let clientLeft = document.documentElement.clientLeft || document.documentElement.clientLeft || 0;
    
    let box = element.getBoundingClientRect();

    return {
        top: box.top + scrollTop - clientTop,
        left: box.left + scrollLeft - clientLeft
    }  
};

export const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }

    return null;
}

export const scrollToElement = (element, menuHeight = 80) => {
    window.scrollTo({
        top: getCoords(element).top - menuHeight,
        behavior: "smooth"
    })
};

export const scrollToElementInBlock = (element, params) => {
    element.scrollIntoView(params);
};

export const horizontalScrollToLast = (element) => {
    element.scrollLeft = element.scrollWidth;
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const clearObject = (obj) => {
    for (const field of Object.keys(obj)) {
        if (typeof obj[field] == 'object' && Array.isArray(obj[field])) {
            obj[field].splice(0, obj[field].length)
        } else if (typeof obj[field] == 'object' && !Array.isArray(obj[field])) {
            for (var v in obj[field]) {
                if (obj[field].hasOwnProperty(v)) 
                    obj[field][v] = '';
            }
        } else if (typeof obj[field] == 'string' || typeof obj[field] == 'number') {
            obj[field] = '';
        }
    }
};

// export const getStyle = (element) => {
//     return getComputedStyle(element);
// };

export const getWidth = (element) => {
    return Math.round(+getComputedStyle(element).width.slice(0, -2));
};

export const getHeight = (element) => {
    return Math.round(+getComputedStyle(element).height.slice(0, -2));
};

export const clearArray = (arr) => {
    arr.splice(0, arr.length);
};

export const createPdfPlaceholder = (link, title) => {
    link.document.write(
        `<html>
            <title>${title}</title>
                <body style="margin: 0px;">
                <p class="loading" style="text-align: center">PDF is loading, please wait</p>
            </body>
        </html>`
    );    
}

export const createPdf = (link, data) => {
    link.document.write(
        `<object 
            width="100%" 
            height="100%" 
            type="application/pdf"
            data="data:application/pdf;base64,${data}"
        >
            <embed 
                src="data:application/pdf;base64,${data}" 
                type="application/pdf"
            />
        </object>
        <style>.loading { display: none; }</style>`
    );    
};

export const copyFieldsValueFromObjectToObject = (from, to) => {
    for (let field in from) {
        if (to.hasOwnProperty(field)) {
            to[field] = from[field];
        }
    };
};

// use for commercial
export const removePhoneMask = (phones) => {
    if (!phones || !phones.length) return;

    phones.map((ph) => {
        ph.number = ph.number.replace(/\D+/g, '');
    });
};

// to display the full address
// parameters: 'billing', 'mailing'
// if the address type is 'mine', parameter can be skipped
// for copying use copyFieldsValueFromObjectToObject
export const showFullAddress = (obj, type = '') => {
    if (type.length) {
        type = `_${type}`;
    }

    let res = {};
    [`address${type}`, `street${type}`, `apartment${type}`, `city${type}`, `state${type}`, `zip${type}`].map((item) => {
        if (obj.hasOwnProperty(item)) {
            res[item.replace(type, '')] = obj[item];
        }
    });

    const addComma = (word) => {
        return word ? `${word},` : '';
    };

    const checkWord = (word) => {
        return word ? word : '';
    };
    
    return `${checkWord(res.address)} ${addComma(res.street)} ${addComma(res.apartment)} ${addComma(res.city)} ${checkWord(res.state)} ${checkWord(res.zip)}`.trim();
};

// it checks the filling of phones and emails in the array.
// the second parameter to pass the name of the field to be checked:
// 'number' (phones) or 'email' (emails)
// use for commercial
export const checkContacts = (arr, type) => {
    if (!arr.length) return;

    const result = [];
    arr.map((item) => {
        if (item[type].length) 
            result.push(item);
    });

    return result;  
}

// creating a ghost for drag event
export const createGhost = (el, cl = '') => {
    if (!el) return;

    el.classList.add(cl);

    setTimeout(() => {
        el.classList.remove(cl);
    }, 10);
};

export const addParamsToLocation = (params, route) => {
    history.pushState(
        {},
        null,
        route.path 
            + '?' 
            + Object.keys(params)
            .map(key => {
                return (
                    encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
                )
            })
            .join('&')
    )
};

export const downloadFileFromInput = file => {
    // const fileReader = new FileReader();
    // fileReader.readAsDataURL(file);

    const blob = new Blob([file], { type: file.type });
    const objectURL = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    
    link.href = objectURL;
    link.download = file.name;
    // document.body.appendChild(link);
    link.click();
    link.remove();
};

export const deleteCommas = (value) => {
    return value.toString().split(',').join('');
};

export const resetRepresentativesEntities = (representatives) => {
    return representatives.map(item => {
        item.entities = [{ is_primary: 0 }]
        return item;
    });
};

export const sortRepresentatives = (representatives, entityId) => {
    representatives.forEach((item) => {
        // Removing field 'type' from representative for success send request to Back-End.
        if (item.type) {
            item.title = item.type.title;
            delete item.type;
        }

        if (item.entities) {
            let entityIndex = item.entities.findIndex(e => e.entity_id == entityId); 
            item.entities.splice(0, 0, item.entities.splice(entityIndex, 1)[0]);
        }
    });

    const isPrimaryIndex = representatives.findIndex(representative => {

        // TODO Temporary Opportunity contacts + Representatives
        if (representative.entities) {
            return representative.entities[0].is_primary
        }

        if (representative.hasOwnProperty('is_primary')) {
            return representative.is_primary;
        }

        return false;

    });
    if (isPrimaryIndex > 0) {
        representatives.splice(0, 0, representatives.splice(isPrimaryIndex, 1)[0]);
    };

    return representatives;
};

export const secondsToHours = (value) => {
    return Math.floor(Number(value) / 60 / 60);
};

export const getContentWithoutBottomAndTopBrTag = (content) => {
    return content.replace(/^(<div><br><\/div>)+|(<div><br><\/div>)+$/g, '');
};

//regular expression /(\w+)/g checks the number of words in a line
export const getAbbreviation = (title) => {

    if (! title) {
        return '';
    }

    return (title.match(/(\w+)/g) || '').length > 1
        ? (title.match(/\b(\w)/g).join('')).slice(0, 2)
        : title.slice(0, 2);
}

export const collectJobNumber = (number, prefix) => {
    if (!number) return '-';
    if (!prefix) return number;

    const ripeNum = prefix ? `-${number}` : number;
    return prefix + ripeNum;
}

export const validationErrorNotification = function(validator, context) {
    if (validator.errors.items.length) {
        let requiredValidatorError = null;
        validator.errors.items.forEach(e => {
            if (e.rule === 'regex') {
                let errorInField = e.field.replace(/(_|-|\s)|[0-9]+/g, ' ');
                let error_message = e.msg.replace(/(_|-|\s)+/g, ' ');

                if (e.msg.includes(e.field)) {
                    error_message = e.msg.replace(e.field, errorInField);
                }

                if (error_message.includes('management company')) {
                    error_message = error_message.replace('management company', 'Care of');
                }
                
                context.notifyError(`
                    ${error_message}.
                    Special characters are not accepted.
                `);
            }

            if (e.rule === 'required') {
                requiredValidatorError = true;
            }

            if (e.rule === 'integer') {
                context.notifyError(`Field must be an integer.`);
            }

            if (e.rule === 'stateName') {
                context.notifyError(e.msg);
            }
        });

        if (requiredValidatorError) {
            context.notifyError('All fields are required.');
        }
    }
};

export const getTemporarilySavedPdf = function(filename, pdfName) {
    const url = process.env.VUE_APP_URL + '/get-temporarily-saved-pdf';

    const query = new URLSearchParams();
    query.append('filename', filename);
    query.append('document_name', pdfName);

    const link = document.createElement('a');
    link.target = '_blank';
    link.href = url + '?' + query;
    link.click();
};

export const processingCustomFields = function(customFields, CUSTOM_FIELDS_TYPES) {
    let sections = customFields.sections;
    let fields;
    let convertedObjectValue;

    // convert JSON object_value to object
    sections.forEach(function(section, sectionIndex) {
        fields = section.fields;

        fields.forEach(function(field, fieldIndex) {
            // convert parameters from JSON to Object
            if (field.values && field.values.length && field.values[0].object_value !== null) {
                convertedObjectValue = JSON.parse(field.values[0].object_value);
                field.values[0].object_value = convertedObjectValue;
            }

            if (field.values && !field.values.length) {
                // add custom field empty value
                switch (field.type.name) {
                    case CUSTOM_FIELDS_TYPES.PHONE:
                        field.values = [{ 'object_value': {}, 'value': '' }];
                        break;
                    default:
                        this[fieldIndex].values = [{ 'value': '' }];
                        break;
                }
            }
        }, fields);

        this[sectionIndex].fields = fields;
    }, sections);

    return sections;
};

export const getGoogleMapLink = function(lat, lng) {
    if ((!lat || !lng)) {
        return false;
    }

    return `https://www.google.com/maps/@?api=1&map_action=map&center=${lat},${lng}&basemap=satellite&zoom=21`;
};

export const getDataFromQuery = (payload) => {
    const address = payload.addresses && payload.addresses.length ? payload.addresses[0] : '';
    const phone = payload.phones && payload.phones.length ? payload.phones[0] : '';
    const email = payload.emails && payload.emails.length ? payload.emails[0] : '';

    return {
        address,
        phone,
        email
    }
};

export const getFieldForTrackBy = (val) => {
    // Get field name for track-by props which use in multiselect
    if (val && val.values && val.values.length) {
        return val.values[0].id ? 'id' : 'name';
    }
}
