<template>
    <job-navigation
        ref="NavigationPanel"
        :entityId="entityId"
        :entityType="entityTypeForNotes || entityType"
    >
        <li
            slot="links"
            v-for="(page, index) in visiblePages"
            :key="`menu-item-${index}`"
        >
            <router-link
                :to="{ name: page.link }"
                :class="[
                    's-align-5 no-wrap h-100 nav-menu-item',
                    { 'navigation-link-active' : page.link === $route.meta.related_to }
                ]"
                v-if="page.status"
            >
                {{ page.name }}
                <pin-icon class="ml-2"/>
            </router-link>
        </li>
        <div slot="submenu">
            <li
                class="s-align-4 pb-1 w-100"
                v-for="(page, index) in visiblePages"
                :key="`submenu-${index}`"
            >
                <button
                    class="transparent-button p-2 mr-1"
                    @click="savePins(index)"
                    :disabled="page.name == OVERVIEW"
                >
                    <pin-icon :class="{ 'pin-icon-chosen' : page.status }"/>
                </button>
                <router-link
                    :to="{ name: page.link }"
                    class="f-13-grey f-sbold"
                >
                    <span>{{ page.name }}</span>
                </router-link>
            </li>
        </div>
    </job-navigation>
</template>

<script>
    import JobNavigation from '@/components/JobNavigation.vue';
    import { PinIcon } from '@/icons';
    import debounce from 'lodash/debounce';
    import api from '@/headers.js';
    import { BASE_ENTITY_TYPES } from '@/constants';

    const OVERVIEW = 'Overview';

    export default {
        name: 'NavigationPanelForEntity',
        components: {
            PinIcon,
            JobNavigation
        },
        data() {
            return {
                OVERVIEW: OVERVIEW,
                allSavedMenuPins: null,
                residentialModulePages: [
                    { name: OVERVIEW, link: 'Overview', status: true, },
                    { 
                        name: 'Documents',
                        link: 'ResidentialDocuments',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_DOCUMENTS,
                            this.$permissions.VIEW_MY_DOCUMENTS
                        ]
                    },
                    {
                        name: 'Packets',
                        link: 'ResidentialPackets',
                        status: false
                    },
                    {
                        name: 'Measurements',
                        link: 'ResidentialMeasurements',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_MEASUREMENTS,
                            this.$permissions.VIEW_MY_MEASUREMENTS
                        ]
                    },
                    {
                        name: 'Estimates',
                        link: 'ResidentialEstimates',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_ESTIMATE,
                            this.$permissions.VIEW_MY_ESTIMATE
                        ]
                    },
                    { 
                        name: 'Tasks',
                        link: 'ResidentialTasks',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_TASKS,
                            this.$permissions.VIEW_MY_TASKS
                        ]
                    },
                    {
                        name: 'Messages/Calls',
                        link: 'ResidentialMessages',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_EMAILS,
                            this.$permissions.VIEW_MY_EMAILS
                        ]
                    },
                    {
                        name: 'Form Builder',
                        link: 'ResidentialFormBuilder',
                        status: false
                    },
                    {
                        name: 'Settings',
                        link: 'ResidentialSettings',
                        status: false
                    },
                    {
                        name: 'Events',
                        link: 'Events',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_CALENDARS,
                            this.$permissions.VIEW_MY_CALENDAR,
                            this.$permissions.CREATE_EVENT_FOR_OTHERS
                        ]
                    },
                    {
                        name: 'Orders',
                        link: 'ResidentialOrders',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_ORDERS,
                            this.$permissions.VIEW_MY_ORDERS
                        ]
                    },
                    {
                        name: 'Contract Worksheet',
                        link: 'ContractWorksheet',
                        status: false
                    }
                ],
                commercialJobPages: [
                    { name: OVERVIEW, link: 'CommercialJobOverview', status: true },
                    {
                        name: 'Documents',
                        link: 'CommercialJobDocuments',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_DOCUMENTS,
                            this.$permissions.VIEW_MY_DOCUMENTS
                        ]
                    },
                    {
                        name: 'Measurements',
                        link: 'CommercialJobMeasurements',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_MEASUREMENTS,
                            this.$permissions.VIEW_MY_MEASUREMENTS
                        ]
                    },
                    {
                        name: 'Estimates',
                        link: 'CommercialJobEstimates',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_ESTIMATE,
                            this.$permissions.VIEW_MY_ESTIMATE
                        ]
                    },
                    {
                        name: 'Events',
                        link: 'CommercialJobEvents',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_CALENDARS,
                            this.$permissions.VIEW_MY_CALENDAR,
                            this.$permissions.CREATE_EVENT_FOR_OTHERS
                        ]
                    },
                    {
                        name: 'Tasks',
                        link: 'CommercialJobTasks',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_TASKS,
                            this.$permissions.VIEW_MY_TASKS
                        ]
                    },
                    {
                        name: 'Orders',
                        link: 'CommercialJobOrders',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_ORDERS,
                            this.$permissions.VIEW_MY_ORDERS
                        ]
                    },
                    { name: 'Packets', link: 'CommercialJobPackets', status: false },
                    { name: 'Form Builder', link: 'CommercialJobFormBuilder', status: false },
                    { name: 'Settings', link: 'CommercialJobSettings', status: false },
                    {
                        name: 'Messages/Calls',
                        link: 'CommercialJobMessages',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_EMAILS,
                            this.$permissions.VIEW_MY_EMAILS
                        ]
                    },
                    {
                        name: 'Warranty',
                        link: 'CommercialJobWarranty',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_EMAILS,
                            this.$permissions.VIEW_MY_EMAILS
                        ]
                    },
                    {
                        name: 'Postcards',
                        link: 'CommercialJobPostcards',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_EMAILS,
                            this.$permissions.VIEW_MY_EMAILS
                        ]
                    },
                ],
                commercialLeadPages: [
                    { name: OVERVIEW, link: 'CommercialLeadOverview', status: true },
                    {
                        name: 'Measurements',
                        link: 'CommercialLeadMeasurements',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_MEASUREMENTS,
                            this.$permissions.VIEW_MY_MEASUREMENTS
                        ]
                    },
                    { name: 'Packets', link: 'CommercialLeadPackets', status: false },
                    {
                        name: 'Tasks',
                        link: 'CommercialLeadTasks',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_TASKS,
                            this.$permissions.VIEW_MY_TASKS
                        ]
                    },
                    {
                        name: 'Orders',
                        link: 'CommercialLeadOrders',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_ORDERS,
                            this.$permissions.VIEW_MY_ORDERS
                        ]
                    },
                    {
                        name: 'Documents',
                        link: 'CommercialLeadDocuments',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_DOCUMENTS,
                            this.$permissions.VIEW_MY_DOCUMENTS
                        ]
                    },
                    {
                        name: 'Events',
                        link: 'CommercialLeadEvents',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_CALENDARS,
                            this.$permissions.VIEW_MY_CALENDAR,
                            this.$permissions.CREATE_EVENT_FOR_OTHERS
                        ]
                    },
                    { name: 'Form Builder', link: 'CommercialLeadFormBuilder', status: false },
                    { name: 'Settings', link: 'CommercialLeadSettings', status: false },
                    {
                        name: 'Estimates',
                        link: 'CommercialLeadEstimates',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_ESTIMATE,
                            this.$permissions.VIEW_MY_ESTIMATE
                        ]
                    },
                    {
                        name: 'Messages/Calls',
                        link: 'CommercialLeadMessages',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_EMAILS,
                            this.$permissions.VIEW_MY_EMAILS
                        ]
                    }
                ],
                warrantyJobPages: [
                    { name: OVERVIEW, link: 'WarrantyOverview', status: true },
                    {
                        name: 'Documents',
                        link: 'WarrantyDocuments',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_DOCUMENTS,
                            this.$permissions.VIEW_MY_DOCUMENTS
                        ]
                    },
                    {
                        name: 'Orders',
                        link: 'WarrantyJobOrders',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_ORDERS,
                            this.$permissions.VIEW_MY_ORDERS
                        ]
                    },
                    {
                        name: 'Events',
                        link: 'WarrantyJobEvents',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_CALENDARS,
                            this.$permissions.VIEW_MY_CALENDAR,
                            this.$permissions.CREATE_EVENT_FOR_OTHERS
                        ]
                    },
                    {
                        name: 'Messages',
                        link: 'WarrantyJobMessages',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_EMAILS,
                            this.$permissions.VIEW_MY_EMAILS
                        ]
                    }
                ],
                opportunityPages: [
                    {name: OVERVIEW, link: 'OpportunityOverview', status: true},
                    {
                        name: 'Documents',
                        link: 'OpportunityDocuments',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_DOCUMENTS,
                            this.$permissions.VIEW_MY_DOCUMENTS
                        ]
                    },
                    {
                        name: 'Messages/Calls',
                        link: 'OpportunityMessages',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_EMAILS,
                            this.$permissions.VIEW_MY_EMAILS
                        ]
                    },
                    {
                        name: 'Tasks',
                        link: 'OpportunityTasks',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_TASKS,
                            this.$permissions.VIEW_MY_TASKS
                        ]
                    },
                    {
                        name: 'Events',
                        link: 'OpportunityEvents',
                        status: false,
                        permissions: [
                            this.$permissions.VIEW_ALL_CALENDARS,
                            this.$permissions.VIEW_MY_CALENDAR,
                            this.$permissions.CREATE_EVENT_FOR_OTHERS
                        ]
                    },
                ]
            }
        },
        props:{
            entityTypeForNotes: {
                type: String,
                required: false
            },
            entityType: {
                type: String,
                required: true
            },
            entityId: {
                type: [String, Number],
                default: ''
            },
        },
        computed: {
            pagesForEntity() {
                switch (this.entityType){
                    case BASE_ENTITY_TYPES.RESIDENTIAL_JOB:
                    case BASE_ENTITY_TYPES.RESIDENTIAL_LEAD:
                        return this.residentialModulePages;
                    case BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                        return this.commercialJobPages;
                    case BASE_ENTITY_TYPES.COMMERCIAL_LEAD:
                        return this.commercialLeadPages;
                    case BASE_ENTITY_TYPES.WARRANTY_JOB:
                        return this.warrantyJobPages;
                    case BASE_ENTITY_TYPES.OPPORTUNITY:
                        return this.opportunityPages;
                    default:
                        return [];
                }
            },
            visiblePages() {
                return this.pagesForEntity.filter(link => {
                    if (link.permissions) {
                        return link.permissions.some(permission => {
                            return this.$can(permission);
                        });
                    }
                    return true;
                });
            },
            isJob() {
                return !!this.$store.getters['lead/getJobNumber'];
            }
        },
        methods: {
            getPins() {
                let params = {
                    entity_type: this.entityType
                };

                api.get('/pins', { params })
                    .then(res => res.data.data)
                    .then(data => {
                        this.allSavedMenuPins = data;
                        let index;

                        if (data && data.length) {
                            data.map(item => {
                                index = this.visiblePages.findIndex(page => page.link === item.link);
                                if (index !== -1) {
                                    this.visiblePages[index].status = true;
                                }
                            });
                            setTimeout(() => {
                                if (this.$refs.NavigationPanel) {
                                    this.$refs.NavigationPanel.getLinksWidth();
                                }
                            }, 500);
                        }
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    });
            },
            savePins: debounce(function (index) {
                this.visiblePages[index].status
                    ? this.visiblePages[index].status = false
                    : this.visiblePages[index].status = true;

                let chosenPins = this.visiblePages
                    .filter(p => p.status && p.name != OVERVIEW)
                    .map(p => {
                        return {
                            name: p.name,
                            link: p.link,
                            status: p.status
                        }
                    });

                this.$nextTick(() => {
                    this.$refs.NavigationPanel.getLinksWidth();
                });

                let params = {
                    entity_type: this.entityType,
                    pins: chosenPins
                };

                api.post('/pins', params)
                    .then(res => res.data)
                    .then((data) => {
                        this.notifySuccess('Changes have been saved.');
                    })
                    .catch(error => {
                        this.notifyRequestErrors(error);
                    });
            }, 500)
        },
        created() {
            if (this.isJob) {
                this.residentialModulePages.push(
                    {
                        name: 'Warranty',
                        link: 'ResidentialJobWarranty',
                        status: false
                    },
                    {
                        name: 'Postcards',
                        link: 'ResidentialJobPostcards',
                        status: false
                    },
                );
            }
            this.getPins();
        }
    }
</script>
