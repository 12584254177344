import {
    SET_COMMERCIAL_JOB,
    GET_COMMERCIAL_JOB_PREVIEW,
    SET_COMMERCIAL_JOB_PREVIEW,
    SET_COMMERCIAL_JOB_ADDRESS,
    GET_COMMERCIAL_LEAD,
    SET_COMMERCIAL_LEAD,
    SET_CUSTOMER_IN_COMMERCIAL_LEAD,
    SET_PROPERTY_IN_COMMERCIAL_LEAD,
    SET_CUSTOMER_IN_COMMERCIAL_JOB,
    SET_PROPERTY_IN_COMMERCIAL_JOB,
    SET_COMMERCIAL_LEAD_ASSIGNMENT,
    SET_COMMERCIAL_JOB_ASSIGNMENT,
    SET_COMMERCIAL_LEAD_REPRESENTATIVES,
    SET_COMMERCIAL_JOB_REPRESENTATIVES,
    UPDATE_QUALITY_CONTROL,
    SET_COMMERCIAL_JOB_PREVIEW_FOR_PROPERTY,
    SET_COMMERCIAL_LEAD_FOR_PROPERTY
} from '../actions/commercial';
import api from '@/headers.js';

export default {
    namespaced: true,
    state: {
        job: {},
        jobPreview: {},
        jobAddress: {},
        lead: {},
        leadPreview: {},
        leadAddress: {},
        leadForEvents: {},
        jobPreviewForProperty: {},
        leadPreviewForProperty: {}
    },
    getters: {
        getCommercialJob: state => state.job,
        getCommercialJobId: state => state.job.id,
        getCommercialJobPreview: state => state.jobPreview,
        getCommercialJobAddress: state => state.jobAddress,
        getCommercialJobNumber: state => state.jobPreview.job_number,
        getParentLeadID: state => state.jobPreview.parent_lead_id,
        getCommercialLeadPreview: state => state.leadPreview,
        getCommercialLead: state => state.lead,
        getCommercialLeadAddress: state => state.leadAddress,
        getCommercialLeadForEvents: state => state.leadForEvents,
        getCommercialLeadNumber: state => state.leadPreview.job_id,
        getJobPreviewForProperty: state => state.jobPreviewForProperty,
        getLeadPreviewForProperty: state => state.leadPreviewForProperty
    },
    mutations: {
        [UPDATE_QUALITY_CONTROL] (state, payload) {
            state.jobPreview.quality_controls = payload;
        },
        [SET_COMMERCIAL_JOB] (state, payload) {
            state.job = payload;

            // update job number at the top of the page
            state.jobPreview.job_number = payload.job_number;
        },
        [SET_COMMERCIAL_JOB_PREVIEW] (state, payload) {
            state.jobPreview = {
                property: payload.property,
                unit: payload.unit,
                prefix: payload.prefix,
                job_number: payload.job_number,
                parent_lead_id: payload.lead ? payload.lead.id : null,
                creator: payload.creator,
                type_group_id: payload.type_group_id,
                farr_id: payload.farr_id,
                farr_number: payload.farr_number,
                fullname: payload.property.property_name,
                salesman: payload.salesman,
                salesman_id: payload.salesman_id,
                representatives: payload.representatives,
                jobs: payload.jobs,
                quality_controls: payload.quality_controls,
                crew: payload.crew,
                rg_info: payload.rg_info
            };
        },
        [SET_COMMERCIAL_JOB_PREVIEW_FOR_PROPERTY] (state, payload) {
            let key = `${payload.id}`;
            state.jobPreviewForProperty[key] = {
                property: payload.property,
                unit: payload.unit,
                prefix: payload.prefix,
                job_number: payload.job_number,
                parent_lead_id: payload.lead ? payload.lead.id : null,
                creator: payload.creator,
                type_group_id: payload.type_group_id,
                farr_id: payload.farr_id,
                farr_number: payload.farr_number,
                fullname: payload.property.property_name,
                salesman: payload.salesman,
                salesman_id: payload.salesman_id,
                representatives: payload.representatives,
                jobs: payload.jobs,
                quality_controls: payload.quality_controls,
                crew: payload.crew
            };
        },
        [SET_COMMERCIAL_JOB_ADDRESS] (state, payload) {
            state.jobAddress = {
                address: payload.address,
                street: payload.street,
                city: payload.city,
                state: payload.state,
                zip: payload.zip,
                lat: payload.lat,
                lng: payload.lng,
            };
        },
        [SET_COMMERCIAL_LEAD] (state, payload) {
            state.lead = payload;
            state.leadPreview = {
                name: payload.property.property_name,
                requestedBy: payload.requestedBy ? payload.requestedBy : null,
                created_at: payload.created_at,
                createdBy: payload.createdBy,
                assignment: payload.assignment,
                assignment_id: payload.assignment_id,
                customer: payload.customer,
                type: payload.type,
                property: payload.property,
                representatives: payload.representatives,
                bid_due_date: payload.bid_due_date
            };
            let address = payload.address;
            state.leadAddress = {
                address: payload.address.address,
                street: payload.address.street,
                city: payload.address.city,
                state: payload.address.state,
                zip: payload.address.zip,
                lat: payload.address.lat,
                lng: payload.address.lng,
                full_address: `${address.address} ${address.street}, ${address.city}, ${address.state} ${address.zip}`
            };
            state.leadForEvents = {
                fullname: payload.property.property_name,
                poc: `${payload.requestedBy.first_name} ${payload.requestedBy.last_name}, ${payload.requestedBy.title}`,
                phone: payload.requestedBy.phones[0].number || '',
                email: payload.requestedBy.emails[0].email || '',
                full_address: `${address.address} ${address.street}, ${address.city}, ${address.state} ${address.zip}`,
                zip: payload.address.zip,
                lat: payload.address.lat,
                lng: payload.address.lng,
            }
        },
        [SET_COMMERCIAL_LEAD_FOR_PROPERTY] (state, payload) {
            let key = `${payload.id}`;
            let address = payload.address;

            state.leadPreviewForProperty[key] = {
                fullname: payload.property.property_name,
                poc: `${payload.requestedBy.first_name} ${payload.requestedBy.last_name}, ${payload.requestedBy.title}`,
                phone: payload.requestedBy.phones[0].number || '',
                email: payload.requestedBy.emails[0].email || '',
                full_address: `${address.address} ${address.street}, ${address.city}, ${address.state} ${address.zip}`,
                zip: payload.address.zip,
                lat: payload.address.lat,
                lng: payload.address.lng,
            }
        },
        [SET_CUSTOMER_IN_COMMERCIAL_LEAD] (state, payload) {
            state.lead.customer = payload;
        },
        [SET_PROPERTY_IN_COMMERCIAL_LEAD] (state, payload) {
            state.lead.property = payload;
            state.lead.property.addresses.main.splice(0, 1, payload.address);
            state.lead.property.addresses.billing.splice(0, 1, payload.addresses.billing[0]);
        },
        [SET_CUSTOMER_IN_COMMERCIAL_JOB] (state, payload) {
            state.job.property.customer = payload;
        },
        [SET_PROPERTY_IN_COMMERCIAL_JOB] (state, payload) {
            state.job.property = payload;
            state.job.property.addresses.main.splice(0, 1, payload.address);
        },
        [SET_COMMERCIAL_LEAD_ASSIGNMENT] (state, payload) {
            if (payload.id) {
                state.lead.assignment = payload;
                state.lead.assignment_id = payload.id;
                state.leadPreview.assignment = payload;
                state.leadPreview.assignment_id = payload.id;
            } else {
                // unassign
                state.lead.assignment = null;
                state.lead.assignment_id = null;
                state.leadPreview.assignment = null;
                state.leadPreview.assignment_id = null;
            }
        },
        [SET_COMMERCIAL_JOB_ASSIGNMENT] (state, payload) {
            if (payload.id) {
                state.job.salesman = payload;
                state.job.salesman_id = payload.id;
                state.jobPreview.salesman = payload;
                state.jobPreview.salesman_id = payload.id;
            } else {
                // unassign
                state.job.salesman = null;
                state.job.salesman_id = null;
                state.jobPreview.salesman = null;
                state.jobPreview.salesman_id = null;
            }
        },
        [SET_COMMERCIAL_LEAD_REPRESENTATIVES] (state, payload) {
            state.lead.representatives = payload;
        },
        [SET_COMMERCIAL_JOB_REPRESENTATIVES] (state, payload) {
            state.job.representatives = payload;
        }
    },
    actions: {
        [GET_COMMERCIAL_JOB_PREVIEW]({ commit }, id) {
            return new Promise((resolve, reject) => {
                api.get(`/commercial/jobs/${id}`)
                    .then(response => response.data.data)
                    .then(data => {
                        commit(SET_COMMERCIAL_JOB, data)
                        commit(SET_COMMERCIAL_JOB_PREVIEW, data);
                        commit(SET_COMMERCIAL_JOB_ADDRESS, data);
                        commit(SET_COMMERCIAL_JOB_PREVIEW_FOR_PROPERTY, data);
                        resolve(data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        [GET_COMMERCIAL_LEAD]({ commit }, id) {
            return new Promise((resolve, reject) => {
                api.get(`/commercial/leads/${id}`)
                    .then(response => response.data.data)
                    .then(data => {
                        commit(SET_COMMERCIAL_LEAD, data);
                        commit(SET_COMMERCIAL_LEAD_FOR_PROPERTY, data);
                        resolve(data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        }
    }
}
