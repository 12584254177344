<template>
    <div
        class="lead-item pointer relative"
        :class="{'lead-item-chosen': isActive === warranty.id}"
        :style="`border-left: 4px solid #33b3ff;`"
        @click.stop="$emit('open-details')"
    >
        <div :class="['row-between', !mScreen && 'flex-no-wrap']">
            <div class="s-align-4">
                <status-marker
                    :marker="fullName"
                    :color="'#33b3ff'"
                    :size="34"
                />
            </div>
            <div class="ml-15 flex-grow-1 column-start">
                <component
                    :is="fullName ? 'router-link' : 'span'"
                    class="f-14-black f-sbold  relative mb-1"
                    :class="{'f-14-grey f-italic f-normal' : !fullName, 'black-link-underlined': fullName}"
                    :to="redirectToPath"
                >
                    {{ fullName || no_info }}
                </component>
                <div class="column-start w-100">
                    <div class="lead-status-info row-start flex-no-wrap s-align-4">
                        <span class="f-12-grey">ID:</span>
                        <span class="f-12-black ml-1">
                            {{ jobPrefixAndNumber }}
                        </span>
                    </div>
                    <div class="column-start w-100">
                        <div>
                            <span class="f-12-grey">Trades:</span>
                            <span
                                class="f-12-black ml-1 mr-4"
                                :class="{'f-12-grey f-italic': !warranty.trades}"
                            >
                                {{ warranty.trades || no_info }}
                            </span>

                        </div>
                        <div>
                            <span class="f-12-grey">Source:</span>
                            <span
                                class="f-12-black ml-1"
                                :class="{'f-12-grey f-italic': !warranty.lead_source}"
                            >
                               {{ warranty.lead_source || no_info }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div :class="mScreen || tScreen
                        ? 'w-100 row-between ml-50 mt-2'
                        : 'column-between-end'
                    ">
                <span class="f-11-grey">
                    <clock-icon
                        class="i-grey mr-1"
                        size="10"
                    />
                    <span>
                         {{ warranty.created_at | timeAgo }}
                    </span>
                </span>
                <priority
                    v-if="warranty.priority"
                    :priority="warranty.priority"
                    :priorityColor="warranty.priority_color"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import {ClockIcon} from '@/icons';
    import {StatusMarker, Priority} from '@/components';
    import {BASE_ENTITY_TYPES} from '@/constants';

    export default {
        name: 'WarrantyListsCard',
        components: {
            ClockIcon,
            StatusMarker,
            Priority
        },
        data() {
            return {
                BASE_ENTITY_TYPES,
                no_info: 'No information'
            }
        },
        props: {
            warranty: {
                type: Object,
                required: true
            },
            isActive: {
                type: Number
            }
        },
        computed: {
            jobNumber() {
                let jobNumber = this.warranty.job_number;
                return jobNumber || '-' ;
            },
            entityType() {
                return this.warranty.entity ? this.warranty.entity.type : null;
            },
            redirectToPath() {
                return {
                    name: 'WarrantyJobDetails',
                    params: {
                        job_id: this.warranty.id
                    }
                }
            },
            fullName() {
                let fullName;
                switch (this.entityType) {
                    case BASE_ENTITY_TYPES.RESIDENTIAL_JOB:
                        const customer = this.warranty.customer;
                        fullName = `${customer.first_name || ''} ${customer.last_name}`;
                        break;
                    case BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                        fullName = this.warranty.property.name;
                        break;
                }
                return fullName;
            },
            jobPrefixAndNumber() {
                return `${this.warranty.prefix ? this.warranty.prefix + '-' : ''}${this.warranty.job_number ? this.warranty.job_number : '-'}`;
            }
        }
    }
</script>

