<template>
    <div 
        :class="`lead-emails-chips row-start s-align-4 relative cursor-text ${name}`"
        @click="inputFocus()"
    >
        <div 
            class="lead-emails-chips-item s-align-4 mr-4 mb-2 break-all"
            v-for="(email,index) in leadChips"
            :key="'chips_' + index"
        >
            <avatar
                :name="email"
                :border="false"
                size="30"
                class="mr-4"
            />
            {{email}}
            <button 
                class="transparent-button ml-4 p-1"
                @click="deleteChips(index)"
            >
                <close-icon
                    class="i-grey" 
                    size="8"
                />
            </button>
        </div>
        <input 
            type="email"
            :name="name" 
            class="form-input-transparent h-50"
            v-model="email"
            autocomplete="no"
            @keyup="addSendChips"
            @keydown="deleteLastChips"
            @blur="checkEmailAndAdd"
            :style="`width: ${inputWidth}px`"
            :ref="name"
        />
        <lead-emails-menu 
            v-if="emailMenu && emailWithLabel.length"
            :leadEmails="emailWithLabel"
            @addEmail="addEmailToSendRecipients"
        />
    </div>
</template>

<script>
import Avatar from './User/Avatar.vue';
import { CloseIcon } from '@/icons';
import LeadEmailsMenu from './LeadEmailsMenu.vue'
import { BASE_ENTITY_TYPES } from '@/constants';
import IMAPapi from '@/IMAPheaders';

export default {
    name: 'LeadEmailsChips',
    components: {
        Avatar,
        CloseIcon,
        LeadEmailsMenu
    },
    props: {
        representatives: {
            type: Array,
            default: () => {
                return [];
            }
        },
        leadChips: {
            type: Array,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        entityType: {
            type: String,
            required: true
        },
        recipients: {
            type: Array,
            default: () => {
                return [];
            }
        },
        accountId: {
            type: [Number, String],
            required: true
        },
        isGetEmailsOutlook: {
            type: Boolean,
            default: true
        },
        fromEmailAddress: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    data() {
        return {
            email: '',
            inputWidth: 30,
            emailMenu: false,
            emails: []
        }
    },
    watch: {
        email(val) {
            if (val.includes(' ')) {
                val.trim();
                this.leadChips.push(val);
                this.email = '';
            }
            this.checkNeedEmail();
        }
    },
    methods: {
        getEmailsOutlook() {
            const params = {
                value: null,
                account_id: this.accountId
            };

            IMAPapi.get('people/search', { params })
                .then(response => {
                    this.$store.commit('user/GET_EMAILS_PEOPLE', response.data.data);
                })
                .catch((error) => {
                    this.notifyRequestErrors(error);
                });
        },
        addSendChips(event) {
            if (event.code == 'Space') {
                if (event.target.value.includes('@')) {
                    event.target.value.trim();
                    this.leadChips.push(event.target.value);
                    this.email = '';
                    this.inputWidth = 30;
                } else {
                    this.email = '';
                    this.inputWidth = 30;
                }
            }

            if (this.inputWidth == 0) {
                this.inputWidth = 30;
            } else if (event.target.value.length <= 1) {
                this.inputWidth = 30;
            } else {
                this.inputWidth = event.target.value.length * 15;
            }
        },
        checkNeedEmail() {
            let checkNeed = this.entityEmails.some(el => {
                return el.toLowerCase().includes(this.email.toLowerCase());
            });
            this.emailMenu = checkNeed;
        },
        checkEmailAndAdd() {
            if (this.email.includes('@')) {
                this.email.trim();
                this.leadChips.push(event.target.value);
                this.email = '';
                this.inputWidth = 30;
            }
        },
        deleteLastChips(event) {
            if (!this.email.length && event.code == 'Backspace') {
                let last = this.leadChips.length -1;
                this.leadChips.splice(last,1);
            }
        },
        deleteChips(index) {
            this.leadChips.splice(index,1);
        },
        addEmailToSendRecipients(email) {
            this.email = '';
            this.leadChips.some(el => el == email) ? '' : this.leadChips.push(email);
            this.inputFocus();
        },
        inputFocus() {
            let input = this.$refs[`${this.name}`];
            input.focus();
            this.emailMenu = true;
        },
        outside(event) {
            if (this.emailMenu && !event.target.classList.contains('lead-emails-item') 
            && event.target.name != this.name && !event.target.classList.contains(`${this.name}`)) {
                this.emailMenu = false;
            }
        },
    },
    computed: {
        emailWithLabel() {
            return this.activeEmails.map(item => {
                let getEmail;

                switch (this.entityType) {
                    case BASE_ENTITY_TYPES.RESIDENTIAL: {
                        getEmail = this.entityData.email_1;

                        if (getEmail === item) {
                            return {
                                email: getEmail,
                                label: 'Primary contact'
                            }
                        }

                        return {
                            email: item,
                            label: 'Additional email'
                        }
                        break;
                    }
                    case BASE_ENTITY_TYPES.COMMERCIAL_JOB: {
                        getEmail = this.representatives.find(el => el.emails.find(email => email.email === item));

                        if (getEmail) {
                            return {
                                email: item,
                                label: getEmail.title
                            }
                        }

                        return {
                            email: item
                        }
                        break;
                    }
                    default : {
                        return {
                            email: item
                        }
                    }
                }
            })
        },
        emailsPeople() {
            return this.$store.getters['user/getEmailsPeople'];
        },
        activeEmails() {
            return this.entityEmails.filter(el => {
                return el.toLowerCase().includes(this.email.toLowerCase());
            });
        },
        entityData() {
            switch (this.entityType) {
                case BASE_ENTITY_TYPES.RESIDENTIAL:
                case BASE_ENTITY_TYPES.RESIDENTIAL_JOB:
                    return this.$store.getters['lead/getLeadPreview'];
                case BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                    return this.$store.getters['commercial/getCommercialJobPreview'];
                case BASE_ENTITY_TYPES.COMMERCIAL_LEAD:
                    return this.$store.getters['commercial/getCommercialLeadPreview'];
            }
        },
        entityEmails() {
            let values = [];
            switch (this.entityType) {
                case BASE_ENTITY_TYPES.RESIDENTIAL:
                case BASE_ENTITY_TYPES.RESIDENTIAL_JOB:
                    values = Object.values(this.entityData);
                    break;
                case BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                case BASE_ENTITY_TYPES.COMMERCIAL_LEAD: {
                    values = [];
                    this.entityData.representatives && this.entityData.representatives.forEach(rep => {
                        rep.emails.length && rep.emails.forEach(email => {
                            values.push(email.email)
                        });
                    })
                } 
            }

            let emails = values.filter(Boolean).filter(el => el.toString().includes('@'));

            if (this.fromEmailAddress.length) {
                this.fromEmailAddress.forEach(el => {
                    !emails.includes(el) ? emails.push(el) : null;
                });
            }

            if (this.recipients.length) {
                this.recipients.forEach(el => {
                    !emails.includes(el) ? emails.push(el) : null;
                });
            }

            if (this.emailsPeople.length) {
                this.emailsPeople.forEach(el => {
                    !emails.includes(el) ? emails.push(el) : null;
                });
            }

            return emails;
        }
    },
    created() {
        if (this.isGetEmailsOutlook) {
            this.getEmailsOutlook();
        }
    },
    mounted() {
        document.body.addEventListener('click', this.outside, false);
    },
    destroyed() {
        document.body.removeEventListener('click', this.outside, false);
    }
}
</script>