<template>
    <vue-editor 
        :editor-toolbar="toolbar"
        :editorOptions="editorSettings"
        :placeholder="placeholder"
        :value="value"
        @input="$emit('input', $event)"
        useCustomImageHandler
        @image-added="handleImageAdded"
        @text-change="onTextchange"
        ref="editor"
        v-editor-max-length="maxLength"
        class="relative"
    />
</template>

<script>
    import { VueEditor, Quill } from 'vue2-editor/dist/vue2-editor.core.js';
    import ImageResize from 'quill-image-resize';

    // add custom fonts
    const Font = Quill.import('formats/font');
    Font.whitelist = ['times', 'arial', 'helvetica', 'trebuchet', 'georgia'];
    // add custom font size
    const Size = Quill.import('attributors/style/size');
    Size.whitelist = ['8px', '9px', '10px', '11px', '12px', '14px', '16px', '18px', '24px'];
    // register modules
    Quill.register(Size, true);
    Quill.register(Font, true);
    Quill.register('modules/imageResize', ImageResize);

    // fix extra spacing in emails
    const Block = Quill.import('blots/block');
    Block.tagName = 'DIV';
    Quill.register(Block, true);

    // remove text style on paste
    const Clipboard = Quill.import('modules/clipboard')
    const Delta = Quill.import('delta')
    class PlainClipboard extends Clipboard {
        onPaste (e) {
            e.preventDefault();
            const range = this.quill.getSelection();
            const text = e.clipboardData.getData('text/plain');
            const delta = new Delta()
                .retain(range.index)
                .delete(range.length)
                .insert(text);
            const index = text.length + range.index;
            const length = 0;
            this.quill.updateContents(delta, 'silent');
            this.quill.setSelection(index, length, 'silent');
            this.quill.scrollIntoView();
        }
    }
    Quill.register('modules/clipboard', PlainClipboard, true);

    const DEFAULT_TOOLBAR = 'defaultToolbar';
    const NOTES_TOOLBAR = 'notesToolbar';

    const ENTER_KEYCODE = 93;
    
    export default {
        name: 'TextEditor',
        components: {
            VueEditor,
            Quill
        },
        props: {
            value: String,
            placeholder: {
                type: String,
                default: ''
            },
            theme: {
                type: String,
                default: 'snow'
            },
            // available toolbars: defaultToolbar, notesToolbar
            toolbarName: {
                type: String,
                default: DEFAULT_TOOLBAR
            },
            maxLength: {
                type: [Number,String],
                required: false
            }
        },
        data() {
            return {
                defaultToolbar: [
                    [{ 'font': Font.whitelist }],
                    [{ 'size': Size.whitelist }],
                    ['bold', 'italic', 'underline', 
                        { color: [
                            '#101f32', // $el-dark
                            '#006bfd', // $el-darkblue
                            '#00a0ff', // $el-blue
                            '#2ecc71', // $el-green
                            '#ffc928', // $el-yellow
                            '#ffa128', // $el-orange
                            '#f53847', // $el-red
                            '#8876e2'  // $el-violet
                        ] }
                    ],
                    ['link', 'image']
                ],
                notesToolbar: [
                    ['bold', 'italic', 'underline'], 
                    [
                        { 'list': 'ordered' }, 
                        { 'list': 'bullet' }
                    ],
                ],
                editorSettings: {
                    theme: this.theme,
                    modules: {
                        imageResize: {}
                    }
                },
                lastInteractionEvent: null
            }
        },
        watch: {
            value: function() {
                if ((!this.value || this.value && this.value.includes('<p><br></p>')) && this.toolbarName === DEFAULT_TOOLBAR) {
                    // hack to prevent resetting config when line deleted
                    const el = this.$refs.editor.$el;
                    // labels with actual config
                    const labels = el.querySelectorAll('.ql-picker-label');
                    const font = labels[0].dataset.value;
                    const fontSize = labels[1].dataset.value;
                    const color = labels[2].dataset.value;
                    
                    // set font
                    el.querySelector(`span.ql-picker-item[data-value="${font}"]`).click();
                    // set font-size
                    el.querySelector(`span.ql-picker-item[data-value="${fontSize}"]`).click();
                    // set color
                    el.querySelector(`span.ql-picker-item[data-value="${color}"]`).click();
                }
            }
        },
        computed: {
            toolbar: function() {
                return this[this.toolbarName];
            }
        },
        directives: {
            editorMaxLength: {
                inserted(el, binding, vNode) {
                    if (!binding.value && !vNode.componentInstance.value) {
                        return;
                    }
                    let span = document.createElement('span');
                    let editor = vNode.elm;
                    let value = vNode.componentInstance.value.replace(/<[^>]+>/g, '');
                    span.className = 'text-editor-count';
                    span.innerHTML = `${value.length}/${binding.value}`;
                    editor.append(span);

                    value.length > (binding.value - (binding.value / 10)) 
                        ? span.classList.add('text-editor-count-danger')
                        : span.classList.remove('text-editor-count-danger')

                    editor.addEventListener('keypress', (event) => {
                        if (event.target.textContent.length >= binding.value) { 
                            event.preventDefault();
                        }
                    })
                    editor.addEventListener('keyup', (event) => {
                        if (event.target.textContent.length > binding.value) { 
                            event.target.textContent = event.target.textContent.substring(0,binding.value);
                        }
                    })
                    
                },
                update(el, binding, vNode) {
                    if (!binding.value && !vNode.componentInstance.value) {
                        return;
                    }
                    let span = el.querySelector('.text-editor-count');
                    let value = vNode.componentInstance.value.replace(/<[^>]+>/g, '');
                    if (span) {
                        span.classList.add('text-editor-count-danger');
                        span.innerHTML = `${value.length}/${binding.value}`;
                        value.length > (binding.value - (binding.value / 10))
                            ? span.classList.add('text-editor-count-danger')
                            : span.classList.remove('text-editor-count-danger')
                    }
                }
            }
        },
        methods: {
            handleImageAdded(file, Editor, cursorLocation, resetUploader) {
                const maxFileSize = 9961472; //9961472 - 9.5 MB in bytes
                if (file.size > maxFileSize) {
                    this.notifyError(`File '${file.name}' have size more then 10MB! Need less.`);
                    
                    return;
                }

                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => { 
                    Editor.insertEmbed(cursorLocation, "image", reader.result);
                    resetUploader();
                }
            },
            // needed for correct work image alignment tools
            registerClickListener() {
                const editor = this.$refs.editor.$el.querySelector(".ql-container");
                let content;

                this.eventListener = editor.addEventListener('click', () => {
                    content = editor.querySelector(".ql-editor").innerHTML;
                    this.$emit('input', content === '<p><br></p>' ? '' : content);
                });
            },
            initDefaultToolbar() {
                const el = this.$refs.editor.$el;
                // set default font
                el.querySelector('span.ql-picker-item[data-value="arial"]').click();
                // set default font-size
                el.querySelector('span.ql-picker-item[data-value="16px"]').click();
                // set default color
                el.querySelector('span.ql-picker-item[data-value="#101f32"]').click();
                // change plugin placeholder
                el.querySelector("input[data-link]").dataset.link = 'Enter link here';

                // scroll back to top
                // safari
                document.body.scrollTop = 0;
                // chrome, firefox, ie
                document.documentElement.scrollTop = 0;
            },
            onTextchange(delta, oldDelta, source) {
                let isBold;
                let isItalic;
                let isUnderline;
                let opt = oldDelta.ops.length > 1 
                    ? oldDelta.ops[oldDelta.ops.length - 2].attributes 
                    : null;

                if (opt) {
                    isBold = opt.hasOwnProperty('bold') ? opt.bold : false;
                    isItalic = opt.hasOwnProperty('italic') ? opt.italic : false;
                    isUnderline = opt.hasOwnProperty('underline') ? opt.underline : false;
                }

                this.$nextTick(function() {
                    if ((this.value && this.value.includes('<p><br></p>') || this.value === '')
                        && this.lastInteractionEvent === ENTER_KEYCODE
                        && opt) {
                        const el = this.$refs.editor.$el;
                        const boldElement = el.querySelector('.ql-bold');
                        const italicElement = el.querySelector('.ql-italic');
                        const underlineElement = el.querySelector('.ql-underline');

                        if (isBold && !boldElement.classList.contains('ql-active')) {
                            boldElement.click();
                        }
                        if (isItalic && !italicElement.classList.contains('ql-active')) {
                            italicElement.click();
                        }
                        if (isUnderline && !underlineElement.classList.contains('ql-active')) {
                            underlineElement.click();
                        }
                    }
                });
            },
            initEventListener() {
                let editor = this.$refs.editor.$el.querySelector('.ql-editor');
        
                editor.addEventListener('paste', function() {
                    this.$emit('input', editor.innerHTML)
                }.bind(this));

                window.addEventListener('keydown', (e) => {
                    this.lastInteractionEvent = e.keyCode;
                });

                window.addEventListener('click', (e) => {
                    this.lastInteractionEvent = 'mouseClick';
                });
            }
        },
        mounted() {
            if (this.toolbarName === DEFAULT_TOOLBAR) {
                this.initDefaultToolbar();
                this.registerClickListener();
            }

            this.initEventListener();
        }
    }
</script>