var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal"},[_c('div',{directives:[{name:"body-scroll-lock",rawName:"v-body-scroll-lock:reserveScrollBarGap",value:(!_vm.collapsedState),expression:"!collapsedState",arg:"reserveScrollBarGap"}],class:[
            'h-100',
            _vm.collapsedState || _vm.resizeMode ? 's-align-3' : 's-align-5'
        ]},[_c('div',{staticClass:"modal-content-box column-start relative",class:{'warranty-modal' : _vm.isWarranty},style:(_vm.heightContent)},[_c('div',{staticClass:"modal-header row-between bg-white z-index-2"},[_c('h1',[_vm._t("modal-header")],2),(_vm.showCollapseButton)?_c('div',{class:[
                        'row-end',
                        _vm.collapsedState ? 'w-30' : 'w-50'
                    ]},[_c('button',{staticClass:"transparent-button p-1 mr-2",on:{"click":_vm.collapse}},[_c('arrow-icon',{staticClass:"i-grey",attrs:{"size":"16","transform":_vm.collapsedState ? '270' : '90'}})],1),_c('button',{staticClass:"transparent-button p-1 mr-2",on:{"click":_vm.resize}},[(!_vm.resizeMode)?_c('decrease-icon',{staticClass:"i-grey",attrs:{"size":"16"}}):_c('resize-icon',{staticClass:"i-grey",attrs:{"size":"16"}})],1),_c('button',{staticClass:"transparent-button p-1",on:{"click":_vm.close}},[_c('close-icon',{staticClass:"i-grey"})],1)]):(_vm.isCloseBtn)?_c('button',{staticClass:"transparent-button p-1",on:{"click":_vm.close}},[_c('close-icon',{staticClass:"i-grey"})],1):_vm._e()]),_c('div',{class:[
                        'modal-body column-start',
                         !_vm.isFooter && 'mb-20 w-100 ps'
                     ]},[_vm._t("modal-body")],2),(_vm.isFooter)?_c('div',{staticClass:"modal-footer"},[_vm._t("modal-footer")],2):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }