<template>
    <perfect-scrollbar class="ps-grey lead-details">
        <div
            class="w-100"
            v-if="loading"
        >
            <loader/>
        </div>
        <div
            class="w-100"
            v-else
        >
            <div class="s-align-4 w-100 pb-4">
                <clock-icon
                    class="i-grey mr-2"
                    size="12"
                />
                <span class="f-11-grey">{{ warranty.created_at | timeAgo }}</span>
            </div>
            <div class="column-start w-100">
                <div class="row-between">
                    <h2
                        class="f-20-darkgrey f-sbold pb-4"
                        :class="{'f-italic': !fullName}"
                    >
                        <router-link
                            class="to-commercial-lead"
                            :to="redirectToPath"
                        >
                            {{ fullName || no_info }}
                        </router-link>
                    </h2>
                    <div class="s-align-6">
                        <priority
                            v-if="warranty.priority"
                            :priority="warranty.priority.name"
                            :priorityColor="warranty.priority.color"
                        />
                    </div>
                </div>
                <div class="column-start pv-25 w-100 bb-grey">
                    <h2 class="f-16-darkgrey f-sbold mb-8">Demographics</h2>
                    <div class="mb-1">
                        <content-item
                            title="Name"
                            :content="fullName"
                            :titleBold="false"
                            titleStyle="f-13-grey"
                        />
                    </div>
                    <div
                        class="mb-1"
                        v-if="warranty.source"
                    >
                        <content-item
                            title="Source"
                            :content="warranty.source.name"
                            :titleBold="false"
                            titleStyle="f-13-grey"
                        />
                    </div>
                    <div class="row-start pb-1">
                        <div :class="['md-12', address ? 'lg-8 nb-7' : 'lg-12 nb-12']">
                        <span class="f-12-grey">
                            Address:
                            <a
                                :href="getGoogleMapLink(address.lat, address.lng)"
                                :class="[
                                    fullAddress ? 'f-12-blue' : 'f-italic',
                                    {'none-events': !getGoogleMapLink(address.lat, address.lng)}
                                ]"
                                target="_blank"
                            >
                                {{ fullAddress || no_info }}
                                <link-icon
                                    v-if="getGoogleMapLink(address.lat, address.lng)"
                                    class="i-blue"
                                />
                            </a>
                        </span>
                        </div>
                        <div
                            class="s-align-3 lg-4 nb-5 md-12 mt-md-1"
                            v-if="address"
                        >
                            <button
                                class="show-on-map"
                                @click="$emit('showMap', { lat: +address.lat, lng: +address.lng })"
                            >
                                <map-icon/>
                                Show on Map
                            </button>
                        </div>
                    </div>
                </div>
                <div class="column-start pv-25 w-100 bb-grey">
                    <h2 class="f-16-darkgrey f-sbold mb-8">Work</h2>
                    <div
                        class="mb-1"
                        v-if="warranty.salesman"
                    >
                        <content-item
                            title="Salesman"
                            :content="warranty.salesman.fullname"
                            :titleBold="false"
                            titleStyle="f-13-grey"
                        />
                    </div>
                    <div
                        class="mb-1"
                        v-if="warranty.lead_type"
                    >
                        <content-item
                            title="Lead type"
                            :content="warranty.lead_type.name"
                            :titleBold="false"
                            titleStyle="f-13-grey"
                        />
                    </div>
                    <div
                        class="mb-1"
                        v-if="warranty.home_type"
                    >
                        <content-item
                            title="Home type"
                            :content="warranty.home_type.name"
                            :titleBold="false"
                            titleStyle="f-13-grey"
                        />
                    </div>
                    <content-item
                        title="Trade"
                        :content="trades"
                        :titleBold="false"
                        titleStyle="f-13-grey"
                    />
                </div>
            </div>
        </div>
    </perfect-scrollbar>
</template>

<script>
    import {Loader} from '@/ui';
    import api from '@/headers.js';
    import {ClockIcon, LinkIcon, MapIcon} from '@/icons';
    import {BASE_ENTITY_TYPES} from '@/constants';
    import {getGoogleMapLink, showFullAddress} from '@/helpers';
    import {ContentItem, Priority} from '@/components';
    
    export default {
        name: 'WarrantyJobListDetails',
        components: {
            Loader,
            ClockIcon,
            LinkIcon,
            MapIcon,
            ContentItem,
            Priority
        },
        props: {
            warrantyId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                no_info: 'No information',
                BASE_ENTITY_TYPES,
                loading: false,
                warranty: {}
            }
        },
        computed: {
            trades() {
                const trades = this.warranty.trades;
                return (trades && trades.length) ? trades.map(el => el.name).join(', ') : ''
            },
            fullAddress() {
                return showFullAddress(this.address);
            },
            address() {
                const address = this.warranty.addresses;
                return address.length ? address[0] : null;
            },
            redirectToPath() {
                return {
                    name: 'WarrantyJobDetails',
                    params: {
                        job_id: this.warranty.id
                    }
                }
            },
            entityType() {
                return this.warranty.entity ? this.warranty.entity.type : null;
            },
            fullName() {
                let fullName;
                switch (this.entityType) {
                    case BASE_ENTITY_TYPES.RESIDENTIAL_JOB:
                        const customer = this.warranty.entity.customer;
                        fullName = `${customer.first_name || ''} ${customer.last_name}`;
                        break;
                    case BASE_ENTITY_TYPES.COMMERCIAL_JOB:
                        fullName = this.warranty.entity.customer.name;
                        break;
                }
                return fullName;
            }
        },
        methods: {
            getGoogleMapLink,
            async getWarranty() {
                try {
                    this.loading = true;
                    const res = await api.get(`/warranty-jobs/${this.warrantyId}`);
                    this.warranty = res.data.data;
                } catch (err) {
                    this.notifyRequestErrors(err);
                } finally {
                    this.loading = false;
                }
            }
        },
        created() {
            this.getWarranty();
        }
    }
</script>
