<template>
    <div 
        class="modal s-align-5" 
        v-body-scroll-lock:reserveScrollBarGap="true"
        @dragenter.prevent
        @dragover.prevent
    >
        <perfect-scrollbar class="ps-grey signatories-estimate-modal">
            <div class="column-center w-100 t-center">
                <h2 class="f-24-black f-sbold t-center">Signatures Verification Needed</h2>
                <div class="signatories w-100 column-start">
                    <div 
                        :class="[
                            'relative row-start grabbable mt-4 signatory-section pick-list-property',
                            {
                                selected: (dragIndex === index),
                                over: (dragIndex && index !== dragIndex)
                            }
                        ]"
                        v-for="(sign, index) in signatories"
                        :key="index"
                        :draggable="true"
                        @dragstart="signDragStart(index)"
                        @dragenter="signDragEnter(index)"
                        @dragend="signDragEnd(index)"
                    >
                        <div class="lg-12 md-12 sm-12 column-start">
                            <div class="signatory-caption w-100">
                                <div class="column-start">
                                    <p class="f-14-darkgrey">{{ sign.signatureName }}</p>
                                </div>
                            </div>
                            <div class="signatory-fields row-start pr-2">
                                <div class="lg-4 md-4 sm-12 ls-sm-4 pr-4">
                                    <form-input 
                                        label="First Name"
                                        :class="[{ 'form-input-danger' : errors.has(`first_name_${index}`) }]"
                                    >
                                        <input
                                            type="text"
                                            :name="`first_name_${index}`"
                                            class="form-input-field"
                                            placeholder="First Name"
                                            v-input-style="sign.first_name"
                                            v-model="sign.first_name"
                                            v-validate="index === 0 && 'required|min:3|max:250'"
                                        />
                                    </form-input>
                                </div>
                                <div class="lg-4 md-4 sm-12 ls-sm-4 pr-4">
                                    <form-input 
                                        label="Last Name"
                                        :class="[{ 'form-input-danger' : errors.has(`last_name_${index}`) }]"
                                    >
                                        <input 
                                            type="text" 
                                            :name="`last_name_${index}`"                                    
                                            class="form-input-field"
                                            placeholder="Last Name"
                                            v-input-style="sign.last_name"
                                            v-model="sign.last_name"
                                            v-validate="index === 0 && 'required|min:3|max:250'"
                                        />
                                    </form-input>
                                </div>
                                <div class="lg-3 md-3 sm-12 ls-sm-4 pr-4">
                                    <form-input 
                                        label="Email"
                                        :class="[{ 'form-input-danger' : errors.has(`email_${index}`) }]"
                                    >
                                        <input 
                                            type="text" 
                                            :name="`email_${index}`"                                    
                                            class="form-input-field"
                                            placeholder="Email"
                                            v-input-style="sign.email"
                                            v-model="sign.email"
                                            v-validate="index === 0 && 'required|email'"
                                        />
                                    </form-input>
                                </div>
                                <div class="lg-1 md-1 sm-12 to-sign pointer s-align-5">
                                    <line-icon
                                        size="17"
                                        class="i-grey"
                                    />
                                </div>
                            </div>
                            <div class="signatory-message w-100">
                                <button 
                                    class="transparent-button"
                                    @click="addMessage = index"
                                    v-if="addMessage != index"
                                >
                                    <span 
                                        class="f-12-blue"
                                        v-if="!sign.message.length"
                                    >
                                        + Add Message
                                    </span>
                                    <span 
                                        class="f-12-blue"
                                        v-else
                                    >
                                        <edit-icon 
                                            size="10" 
                                            class="i-blue"
                                        />
                                        Edit Message
                                    </span>
                                </button>
                                <div 
                                    class="w-100"
                                    v-if="addMessage == index"
                                >
                                    <form-input-textarea 
                                        label="Message"
                                        :onSubmit="() => { addMessage = null }"
                                        v-input-counter="1000"
                                        transparentBg
                                    >
                                        <textarea 
                                            class="form-input-field transparent-bg"
                                            rows="2"
                                            placeholder="Message"
                                            maxlength="1000"
                                            v-input-style="sign.message"
                                            v-model="sign.message"
                                            v-resize-textarea
                                        />
                                    </form-input-textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        class="view-details-primary mt-4"
                        @click="toggleSignature"
                    >
                        {{ !showNewSignture ? '+ Add Signature #2' : '- Delete Signature #2' }}
                    </button>
                </div>
                <div class="row-between">
                    <button 
                        class="add-button"
                        @click="$emit('close')"
                    >
                        Cancel
                    </button>
                    <!-- <button class="view-details-primary">
                        + Add Document
                    </button> -->
                    <button 
                        class="primary-button"
                        @click="send"
                        :disabled="requestIsGoing"
                    >
                        Sign
                    </button>
                </div>
            </div>
        </perfect-scrollbar>
        <loader 
            size="full"
            v-if="requestIsGoing"
        />
    </div>
</template>

<script>
    import { LineIcon, ArrowIcon, EditIcon } from '@/icons';
    import { FormInput, FormInputTextarea, Loader } from '@/ui';
    import api from '@/headers.js';

    export default {
        name: 'SignModal',
        components: {
            LineIcon,
            FormInput,
            ArrowIcon,
            FormInputTextarea,
            EditIcon,
            Loader
        },
        props: {
            salesman: {
                type: Object,
                required: false
            },
            client: {
                type: Object,
                required: false
            },
            entityId: {
                type: [String, Number],
                required: false
            },
            entityType: {
                type: String,
                required: false
            },
            estimateId: {
                type: [String, Number],
                required: false
            },
            title: {
                type: String,
                required: false
            },
            pdf: {
                type: String,
                required: false
            },
            packetId: {
                type: [String, Number],
                required: false
            },
            packetSaved: {
                type: Boolean,
                required: false
            },
            type: {
                type: String,
                default: 'estimate'
            }
        },
        data() {
            return {
                addMessage: null,
                signatories: [
                    {
                        signatureName: 'Signature customer',
                        title: 'Primary Contact',
                        first_name: '',
                        last_name: '',
                        email: '',
                        message: '',
                        role: 'customer'
                    }
                ],
                signatoriesCompanyRep: {},
                requestIsGoing: false,
                showNewSignture: false,
                dragIndex: null,
                signatory: null
            }
        },
        computed: {
            userCompanyId() {
                return this.$store.getters['user/getUserCompanyId'];
            }
        },
        methods: {
            getAllSignatories() {
                this.requestIsGoing = true;

                const params = {
                    entityId: this.entityId,
                    entityType: this.entityType
                }

                api.get(`/envelopes/signatures`, {params})
                    .then(response => response.data)
                    .then(data => {
                        this.signatories[0] = Object.assign(this.signatories[0], data.customer);
                        this.signatoriesCompanyRep = data.company_rep;
                    })
                    .catch((error) => {
                        this.notifyRequestErrors(error);
                    })
                    .finally(() => this.requestIsGoing = false)
            },
            signDragStart(index) {
                this.dragIndex = index;
                this.signatory = this.signatories[index];
            },
            signDragEnter(index) {
                if (this.dragIndex == index) return false;

                this.signatories.splice(this.dragIndex, 1);
                this.signatories.splice(index, 0, this.signatory);

                this.dragIndex = index;
            },
            signDragEnd(index) {
                this.dragIndex = null;
                this.signatory = null;
            },
            toggleSignature() {
                if (!this.showNewSignture && this.signatoriesCompanyRep !== null) {
                    this.signatories.push({
                        signatureName: 'Signature company rep',
                        title: 'Company Rep',
                        message: '',
                        role: 'salesman',
                        ...this.signatoriesCompanyRep
                    })
                } else {
                    this.signatories.push({
                        signatureName: 'Signature company rep',
                        title: 'Company Rep',
                        first_name: '',
                        last_name: '',
                        email: '',
                        message: '',
                        role: 'salesman',
                    })
                    this.signatories.length > 2 && this.signatories.splice(1, 2);
                }
                this.showNewSignture = !this.showNewSignture;
            },
            setData(data, index) {
                for (let dataField in data) {
                    for (let signatoriesField in this.signatories[index]) {
                        if (dataField == signatoriesField) {
                            this.signatories[index][signatoriesField] = data[dataField];
                        };
                    };
                };
            },
            send() {
                this.$validator.validateAll().then(result => {
                    if (!result || this.pdf == '') {
                        this.notifyError('All fields are required.');
                        return;
                    }

                    let params;

                    if (this.type === 'estimate') {
                        params = {
                            envelope_title: this.title,
                            envelope_parties: [],
                            envelope_documents: [
                                {
                                    document_title: this.title,
                                    document_file_content: this.pdf,
                                    document_file_name: this.title
                                }
                            ],
                            envelope_meta: null
                        };

                        this.signatories.map((item, el) => {
                            if (el === 0) {
                                params.envelope_parties.push({
                                    party_name: `${item.first_name} ${item.last_name}`,
                                    party_email: item.email,
                                    party_message: item.message
                                })
                            }
                        });

                        params.envelope_meta = {
                            signature: 'email', 
                            customer: this.signatories.find(i => i.role == 'customer').email,
                            salesman: this.signatories.find(i => i.role == 'salesman').email, 
                            entity_id: parseInt(this.entityId),
                            entity_type: this.entityType,
                            estimates: [parseInt(this.estimateId)],
                            company_id: this.salesman ? this.salesman.company_id : this.userCompanyId,
                            title: this.title 
                        };

                        this.requestIsGoing = true;
                        api.post(`/estimates/${this.estimateId}/signatures/email`, params) 
                            .then(response => {
                                this.notifySuccess('Data is sent!');
                            })
                            .catch((error) => {
                                this.notifyRequestErrors(error);
                            })
                            .finally(() => {
                                this.requestIsGoing = false;
                                this.$emit('close');
                            });
                    } else if (this.type === 'packet') {
                        if (!this.packetSaved) {
                            this.requestIsGoing = true;
                            this.$emit('saveAsDraft');
                            return;
                        }

                        params = {
                            id: this.packetId,
                            envelope_parties: []
                        };

                        this.signatories.map((item) => {
                            params.envelope_parties.push({
                                party_name: `${item.first_name} ${item.last_name}`,
                                party_email: item.email,
                                party_message: item.message
                            })
                        });

                        this.requestIsGoing = true;
                        api.post('/envelopes/sent-on-sign', params)
                            .then(response => {
                                this.notifySuccess('Packet is sent!');
                            })
                            .catch((error) => {
                                this.notifyRequestErrors(error);
                            })
                            .finally(() => {
                                this.requestIsGoing = false;
                                this.$emit('redirect');
                            });
                    }
                });
            }
        },
        created() {
            this.setData(this.client, 0);
            this.setData(this.salesman, 1);

            this.getAllSignatories();
        }
    }
</script>