<template>
    <div
        class="pointer relative p-15 bg-white mb-2 opportunities-card"
        :class="isActive && !isContact ? 'active' : ''"
        @click="$emit('openItem')"
    >
        <div class="row-between opportunities-card__info">
            <div class="s-align-4">
                <status-marker
                    :marker="username"
                    size="35"
                />
            </div>
            <div class="flex-grow-1 w-100 ml-15">
                <div
                    class="column-start"
                    :class="isContact ? 'lg-5 sm-12' : ''"
                >
                    <div class="s-align-6 w-100">
                        <clock-icon
                            class="i-grey mr-2"
                            size="12"
                        />
                        <span class="f-11-grey">{{ item.created_at | timeAgo }}</span>
                    </div>
                    <p
                        class=" f-sbold mb-2"
                        :class="username === no_info ? 'f-14-grey f-italic' : 'f-14-black'"
                        v-if="!isContact"
                    >
                        {{ username }}
                    </p>
                    <p
                        class="f-12-grey"
                        v-else
                    >
                        Customer name:
                        <span :class="username !== no_info ? 'f-12-black' : 'f-12-grey f-italic'">
                            {{ username || no_info }}
                        </span>
                    </p>
                    <p class="f-12-grey ">
                        Email:
                        <span :class="email ? 'f-12-black' : 'f-12-grey f-italic'">{{ email || no_info }}</span>
                    </p>
                    <div class="row-baseline" v-if="!isContact">
                        <p class="f-12-grey mr-1">Phone:</p>
                        <span
                            :class="phone ? 'f-12-black' : 'f-normal f-12-grey f-italic'"
                        >
                            {{ phone || no_info }}
                        </span>
                    </div>
                    <p class="f-12-grey" @click.stop>
                        Address:
                        <template v-if="address">
                            <a
                                v-if="isContact"
                                :href="getGoogleMapLink(item.address.lat, item.address.lng)"
                                :class="[
                                    'f-12-blue',
                                    {'none-events': !getGoogleMapLink(item.address.lat, item.address.lng)}
                                ]"
                                target="_blank"
                            >
                                {{ address }}
                                <link-icon 
                                    v-if="getGoogleMapLink(item.address.lat, item.address.lng)"
                                    class="i-blue"
                                />
                            </a>
                            <a
                                v-else
                                :href="getGoogleMapLink(item.addresses[0].lat, item.addresses[0].lng)"
                                :class="[
                                    'f-12-blue',
                                    {'none-events': !getGoogleMapLink(item.addresses[0].lat, item.addresses[0].lng)}
                                ]"
                                target="_blank"
                            >
                                {{ address }}
                                <link-icon 
                                    v-if="getGoogleMapLink(item.addresses[0].lat, item.addresses[0].lng)"
                                    class="i-blue"
                                />
                            </a>
                        </template>
                        <span
                            v-else
                            class="f-12-grey f-italic"
                        >
                            {{ no_info }}
                        </span>
                    </p>
                </div>
                <template v-if="isContact">
                    <div class="column-start lg-4 sm-12">
                        <div class="row-baseline">
                            <p class="f-12-grey mr-1">Phone:</p>
                            <span :class="phone ? 'f-12-black' : 'f-normal f-12-grey f-italic'">
                                {{ phone || no_info }}
                            </span>
                        </div>
                        <p class="f-12-grey">
                            Created by:
                            <span :class="created_by ? 'f-12-black' : 'f-12-grey f-italic'">
                                {{ created_by || no_info }}
                            </span>
                        </p>
                    </div>
                    <div class="column-evenly-end lg-3 sm-12">
                        <p class="f-12-grey">{{ item.created_at | timeAgo }}</p>
                        <p class="f-12-grey">{{ item.created_at | createdAt }}</p>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import {LinkIcon, ClockIcon} from '@/icons';
    import {StatusMarker} from '@/components';
    import {showFullAddress, getGoogleMapLink} from '@/helpers';
    import ContactList from '@/components/CommercialJobs/ContactList.vue';
    import {LEAD_TEL_TYPES} from '@/constants.js';
    
    export default {
        name: 'OpportunitiesCard',
        components: {
            LinkIcon,
            StatusMarker,
            ContactList,
            ClockIcon
        },
        data() {
            return {
                no_info: 'No information',
                LEAD_TEL_TYPES
            }
        },
        props: {
            item: {
                type: Object,
                required: true
            },
            isActive: {
                type: Boolean,
                default: true
            },
            isContact: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            phone() {
                let phone = null;
                if (this.isContact) {
                    phone = this.item.phone;
                } else if (this.item.phones && this.item.phones.length) {
                    const phoneObj = this.item.phones
                        .find(el => el.type.toLowerCase() === this.LEAD_TEL_TYPES.MOBILE.toLowerCase())
                        || this.item.phones[0];
                    phone = phoneObj.number;
                    if (phoneObj.extension) {
                        phone += ` Ext-${phoneObj.extension}`
                    }
                }
                return phone;
            },
            username() {
                if (this.item.first_name || this.item.last_name) {
                    return `${this.item.first_name || ''} ${this.item.last_name || ''}`
                }
                return this.no_info;
            },
            created_by() {
                if (this.item.created_by){
                    return `${this.item.created_by.first_name || ''} ${this.item.created_by.last_name || ''}`
                }
                return null;
            },
            email() {
                if (this.isContact) {
                    return this.item.email;
                } else if (this.item.emails.length) {
                    return this.item.emails[0].email
                }
            },
            address() {
                if (this.isContact) {
                    return this.item.address ? showFullAddress(this.item.address) : null;
                } else if (this.item.addresses.length) {
                    return showFullAddress(this.item.addresses[0])
                }
            }
        },
        methods: {
            // Methods imported from helpers.js
            getGoogleMapLink
        }
    }
</script>
