<template>
    <div class="job-navigation w-100 relative">
        <div 
            class="additional-panel w-100"
            v-if="mScreen"
        >
            <span class="f-16-asphalt f-sbold">
                {{ mobileTitle }}
            </span>
        </div>
        <div class="navigation-wrapper w-100 row-between">
            <button 
                :class="[
                    'leads-menu-to', 
                    { 'leads-menu-to-tinted': !atTheBeginning && backlight }
                ]"
                @click="scrollLinks(SCROLL_TO_LEFT)"
                v-if="!atTheBeginning && buttonsVisible && dScreen"
            >
                <arrow-icon 
                    transform="180" 
                    size="13"
                />
            </button>
            <nav 
                :class="[
                    'navigation-panel hidden',
                    { 'pl-8' : !atTheBeginning }
                ]"
            >
                <ul 
                    class="navigation-links"
                    :id="identifier"
                    ref="navLinks"
                >
                    <slot name="links"></slot>
                </ul>
            </nav>
            <button 
                :class="[
                    'leads-menu-back', 
                    { 'leads-menu-back-tinted' : !atTheEnd && backlight }
                ]"
                @click="scrollLinks(SCROLL_TO_RIGHT)"
                v-if="!atTheEnd && buttonsVisible && dScreen"
            >
                <arrow-icon 
                    transform="0" 
                    size="13"
                />
            </button>
            <div class="navigation-submenu-button s-align-5">
                <button 
                    class="w-100 h-100 icon-dark"
                    @click="submenuIsOpen = !submenuIsOpen"
                >
                    <span 
                        class="mr-3"
                        v-if="!mScreen"
                    >
                        Menu settings
                    </span>
                    <arrow-icon 
                        :transform="submenuIsOpen ? 270 : 90"
                        :size="mScreen ? 13 : 8"
                    />
                </button>
            </div>
        </div>
        <div 
            class="navigation-submenu" 
            v-if="submenuIsOpen"
        >
            <div class="navigation-submenu-wrapper">
                <div class="navigation-submenu-title">
                    <div 
                        class="row-between" 
                        v-if="mScreen"
                    >
                        <span class="f-14-asphalt f-sbold">Menu settings</span>
                        <button 
                            class="icon-dark" 
                            @click="submenuIsOpen = false"
                        >
                            <arrow-icon 
                                transform="270" 
                                size="13"
                            />
                        </button>
                    </div>
                </div>
                <perfect-scrollbar class="navigation-submenu-scroll ps ps-grey">
                    <ul>
                        <slot name="submenu"></slot>
                    </ul>
                </perfect-scrollbar>
            </div>
        </div>
        <notes
            v-if="entityType !== BASE_ENTITY_TYPES.WARRANTY_JOB"
            v-can="[$permissions.EDIT_LEAD]"
            :entityId="entityId"
            :entityType="entityType"
        />
        <email-fullscreen-modal
            v-if="emailModal"
            :entityType="entityType"
            :entityId="entityId"
            @close="closeEmailModal"
            @onSend="updateMessages"
        />
        <confirm-actions-modal
            v-if="needConfirmAction"
            @close="needConfirmAction = !needConfirmAction"
            @confirmAction="confirmCloseEmailModal"
        >
            <p
                slot="body"
                class="f-16-black f-sbold t-center"
            >
                Are you sure you want to close? <br/>
                <span class="f-12-black">
                    Changes you made may not be saved.
                </span>
            </p>
        </confirm-actions-modal>
    </div>
</template>

<script>
    import { ArrowIcon } from '@/icons';
    import { getWidth } from '@/helpers';
    import cloneDeep from 'lodash/cloneDeep';
    import { Notes } from '@/components';
    import { BASE_ENTITY_TYPES } from '@/constants';
    import {emailMessage} from '@/views/LeadModules/messages-mixin/emailMessage';

    export default {
        name: 'JobNavigation',
        components: {
            ArrowIcon,
            Notes,
            EmailFullscreenModal: () => import('@/modals/EmailFullscreenModal.vue'),
            ConfirmActionsModal: () => import('@/modals/ConfirmActionsModal.vue')
        },
        props: {
            entityId: {
                type: [String, Number],
                default: ''
            },
            entityType: {
                type: String,
                required: true
            },
        },
        mixins: [emailMessage],
        data() {
            return {
                submenuIsOpen: false,
                wrapperWidth: 0,
                linksWidth: 0,
                step: 0,
                steps: [],
                current: 0,
                SCROLL_TO_RIGHT: 'to right',
                SCROLL_TO_LEFT: 'to left',
                scrollToStep: 0,
                backlight: true,
                identifier: 'LeadMenu',
                BASE_ENTITY_TYPES
            }
        },
        computed: {
            emailModal() {
                return this.$store.getters['emailMessage/getEmailModalState'];
            },
            mobileTitle() {
                return this.$route.meta.title ? this.$route.meta.title : this.$route.meta.related_to;
            },
            buttonsVisible() {
                return this.wrapperWidth < this.linksWidth;
            },
            limit() {
                return this.linksWidth - this.wrapperWidth;
            },
            atTheBeginning() {
                return this.current == 0;
            },
            atTheEnd() {
                return this.step >= this.limit;
            }
        },
        watch: {
            '$route' (to, from) {
                this.submenuIsOpen = false;
            }
        },
        methods: {
            updateMessages(data) {
                const update = !!data;
                this.$store.commit('emailMessage/updateMessages', update);
            },
            outside(event) {
                if (!this.$el.contains(event.target) && this.submenuIsOpen)
                    this.submenuIsOpen = false;
            },
            getLinksWidth() {
                if (this.dScreen) {
                    this.linksWidth = 0;
                    if (!this.buttonsVisible) {
                        this.step = 0;
                        this.steps = [];
                        this.current = 0;
                    }
                    this.wrapperWidth = getWidth(this.$refs.navLinks);
    
                    let menuItems = this.$el.querySelectorAll('.nav-menu-item');
                    // take index of active link
                    if (menuItems) {
                        menuItems.forEach((c, index) => {
                            if (c.classList.contains('navigation-link-active')) {
                                this.scrollToStep = index;
                            }
                        });
                    }
    
                    // links can be of different lengths, need to measure each of them
                    [].slice.call(menuItems).map((link) => {
                        this.steps.push(getWidth(link));
                        this.linksWidth = this.linksWidth + getWidth(link);
                    });
    
                    // scroll to the pre-selected item
                    if (this.scrollToStep != undefined) {
                        if (this.scrollToStep >= 2) {
                            this.current = this.scrollToStep - 2;
                        }
        
                        // counting steps before the pre-selected item
                        if (this.current > 0) {
                            const takenSteps = this.steps.slice(0, this.current);
            
                            this.step = takenSteps.reduce(function(prev, next) {
                                return prev + next;
                            });
    
                            this.$nextTick(function() {
                                this.scrollLinks(this.SCROLL_TO_RIGHT);
                                
                                // if the user has the ability to use the buttons 
                                // and leads-menu is set at the end, 
                                // it needs to reset the steps to the first hidden
                                if (this.atTheEnd) {
                                    let stepsBack = 0;
                                    let stepsBackCount = 0;
                                    const allSteps = cloneDeep(this.steps).reverse();
        
                                    for (let i = 0; i < allSteps.length; i++) {
                                        stepsBack = stepsBack + allSteps[i];
                                        stepsBackCount++;
        
                                        if (stepsBack >= this.wrapperWidth)
                                            break;
                                    }
        
                                    this.current = this.steps.length - stepsBackCount;
                                    this.step = this.linksWidth - stepsBack;
                                }
                            });
                        }
                    }
                }
            },
            scrollLinks(direction) {
                switch(direction) {
                    case this.SCROLL_TO_RIGHT :
                        this.step = this.step + this.steps[this.current];
                        this.current++;                       
                    break;
                    case this.SCROLL_TO_LEFT :
                        this.current--;
                        this.step = this.step - this.steps[this.current];
                    break;
                };

                this.$refs.navLinks.scrollTo({
                    left: this.step,
                    behavior: "smooth"
                });
            }
        },
        mounted() {
            document.body.addEventListener('click', this.outside, false);
            // the width of all children must be measured for scrolling. 
            // since they are not props, it is necessary to wait 
            // for the dom-elements to load and check them
            const intervalID = setInterval(() => {
                if (!document.getElementById(this.identifier)) {
                    clearInterval(intervalID);
                } else if (document.getElementById(this.identifier).children[0]) {
                    this.getLinksWidth();
                    clearInterval(intervalID);
                }
            }, 300);
        },
        destroyed() {
            document.body.removeEventListener('click', this.outside, false);
        }
    }
</script>